import StyledHeading from '../shared/StyledHeading'
import {REVIEWS_BLOCKS} from './config'
import SchemaAggregateRating from '../shared/SchemaAggregateRating'
import Section from '../shared/Section'
import {InlineTextWithVariables, RichtextWithVariables} from '../shared/SectionsWithVariables'
import LucidInlineBlocks from '../shared/LucidInlineBlocks'
import {useLucidContext} from '../../../state/ServerSideStore'
import SectionButton from '../shared/SectionButton'


const schemaValues = {
  image: 'https://placeimg.com/200/200/tech',
  telephone: '(866) 585-2157',
  streetAddress: '6825 Flanders Dr Ste 110',
  addressLocality: 'San Diego',
  addressRegion: 'CA',
  postalCode: '92121',
  addressCountry: 'USA',
  ratingValue: '5',
  reviewCount: '10',
}

export default function Reviews(props) {
  const [{contents}] = useLucidContext(props.cms)
  const practice_name = contents.practice?.name ?? ''

  const {
    title,
    subtitle,
    intro,
    // items,
    outro,
    button,
    color_scheme,
    className,
    css_overrides,
    color_scheme_id_override,
    lucid_page_section_id,
    cms,
    meta
  } = props
  return (
    <Section
      cms={cms}
      css_overrides={css_overrides} color_scheme_id_override={color_scheme_id_override} meta={meta} lucid_page_section_id={lucid_page_section_id}
      className={className}
      name="reviews"
      colorSchemeId={color_scheme}
      style={{
        maxWidth: '60vw',
        padding: '4rem',
        border: '1px solid #eee',
        margin: '0 auto',
      }}
    >
      <SchemaAggregateRating
        name={practice_name}
        image={schemaValues.image}
        telephone={schemaValues.telephone}
        streetAddress={schemaValues.streetAddress}
        addressLocality={schemaValues.addressLocality}
        addressRegion={schemaValues.addressRegion}
        postalCode={schemaValues.postalCode}
        addressCountry={schemaValues.addressCountry}
        ratingValue={schemaValues.ratingValue}
        reviewCount={schemaValues.reviewCount}
      />
      <StyledHeading>
        <h2>
          <InlineTextWithVariables cms={cms} name="title" value={title} />
        </h2>
        <InlineTextWithVariables cms={cms} name="subtitle" value={subtitle} />
      </StyledHeading>
      <RichtextWithVariables
        name='intro'
        richtext={intro}
        cms={cms}
      />
      <LucidInlineBlocks
        name="menu_items"
        blocks={REVIEWS_BLOCKS}
        itemProps={props}
      />
      <RichtextWithVariables
        richtext={outro}
        cms={cms}
        name={'outro'}
      />
      <SectionButton name='button' href={button?.href} label={button?.label} cms={cms} />
    </Section>
  )
}
