interface RSSItem {
    title: string
    link: string
    pubDate: Date
    description: string
}

export default async function RSSReader(feedUrl: string, numItems: number = 5): Promise<RSSItem[]> {
    const response = await fetch(feedUrl)
    
    if (!response.ok) {
        throw new Error(`Failed to fetch RSS feed from ${feedUrl}: HTTP ${response.status}`)
    }

    const text = await response.text()
    const parser = new DOMParser()
    
    let doc: Document
    try {
        doc = parser.parseFromString(text, 'text/xml')
    } catch (error) {
        throw new Error('Failed to parse XML from response text')
    }

    const items = Array.from(doc.getElementsByTagName('item')).slice(0, numItems)

    const rssItems: RSSItem[] = items.map((item) => {
        const titleTag = item.getElementsByTagName('title')[0]
        const linkTag = item.getElementsByTagName('link')[0]
        const pubDateTag = item.getElementsByTagName('pubDate')[0]
        const descriptionTag = item.getElementsByTagName('description')[0]

        const title = titleTag ? titleTag.textContent || '' : ''
        const link = linkTag ? linkTag.textContent || '' : ''
        const pubDateStr = pubDateTag ? pubDateTag.textContent || '' : ''
        const description = descriptionTag ? descriptionTag.textContent || '' : ''

        const pubDate = new Date(pubDateStr)
        if (isNaN(pubDate.getTime())) {
            throw new Error(`Invalid date: ${pubDateStr}`)
        }

        return {title, link, pubDate, description}
    })

    return rssItems
}
