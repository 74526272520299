import StyledHeading from './shared/StyledHeading'
import {InlineTextWithVariables, RichtextWithVariables} from './shared/SectionsWithVariables'
import Section from './shared/Section'
import SchemaVideo from './shared/SchemaVideo'
import SectionVideo from './shared/SectionVideo'
import styled from 'styled-components'
import SectionButton from './shared/SectionButton'

const StyledSection = styled(Section)`
  .section-content {
    max-width: 1440px;
  }
`

export default function VideoWide(props) {
  const {
    title,
    subtitle,
    intro,
    caption,
    video,
    outro,
    button,
    color_scheme,
    className,
    css_overrides,
    color_scheme_id_override, lucid_page_section_id, meta,
    cms,
  } = props

  return (
    <StyledSection
      cms={cms}
      css_overrides={css_overrides}
      color_scheme_id_override={color_scheme_id_override} meta={meta}
      lucid_page_section_id={lucid_page_section_id}
      className={className}
      name="videowide"
      colorSchemeId={color_scheme}
    >
      <SchemaVideo
        name={title}
        description={video?.caption}
        thumbnailUrl={video?.poster}
        // TO-DO: need to pull date from video
        uploadDate="2017-06-08T19:24:45+08:00"
        // TO-DO: need to pull duration from video
        duration="PT0M11S"
        contentUrl={video?.src}
        />
      <StyledHeading>
        <h2>
          <InlineTextWithVariables cms={cms} name="title" value={title}/>
        </h2>
        <InlineTextWithVariables cms={cms} name="subtitle" value={subtitle}/>
      </StyledHeading>
      <RichtextWithVariables
        name="intro"
        richtext={intro}
        cms={cms}
      />
      <SectionVideo
        title={title}
        src={video?.src}
        poster={video?.poster}
        size={video?.size}
        style={{margin: '0 auto', width: '100%'}}
      />
      <RichtextWithVariables
        name="caption"
        richtext={caption}
        cms={cms}
      />
      <RichtextWithVariables
        name="outro"
        richtext={outro}
        cms={cms}
      />
      <SectionButton
        name='button'
        href={button?.url}
        label={button?.label}
        cms={cms}
      />
    </StyledSection>
  )
}

// export const blockConfig = {
//   Component(props) {
//     const { index, data } = props

//     return (
//       <BlocksControls
//         index={index}
//         // focusRing={{ offset: -4, borderRadius: 4 }}
//         insetControls
//       >
//         {/* eslint-disable-next-line */}
//         <VideoWide {...data} />
//       </BlocksControls>
//     )
//   },
//   template: {
//     label: 'VideoWide',
//     defaultItem: {
//       title: 'VideoWide Section Title',
//       subtitle: 'Subtitle',
//       video_src: schemaValues.contentUrl,
//       video_poster: schemaValues.thumbnailUrl,
//       button: {
//         label: 'Button',
//         url: '/test',
//       },
//     },
//     fields: [
//       {
//         name: 'title',
//         label: 'Title',
//         component: 'text',
//       },
//       {
//         name: 'subtitle',
//         label: 'Subtitle',
//         component: 'text',
//       },
//       {
//         name: 'intro',
//         label: 'Intro',
//         component: 'html',
//       },
//       {
//         name: 'video_src',
//         label: 'Video',
//         component: 'group',
//         fields: [
//           {
//             name: 'src',
//             label: 'Video',
//             component: 'image',
//             clearable: true,
//             parse: (media) => {
//               if (!media) {
//                 return
//               }
//               return `https://einstein-clients.imgix.net${media.directory}/${media.filename}`
//             },
//             previewSrc: (src) => {
//               console.log('previewSrc', src)
//               return src
//             },
//           },
//         ],
//       },
//       {
//         name: 'video_poster',
//         label: 'Poster',
//         component: 'group',
//         fields: [
//           {
//             name: 'src',
//             label: 'Poster',
//             component: 'image',
//             // description: 'Image description',
//             clearable: true,
//             parse: (media) => {
//               if (!media) {
//                 return
//               }
//               // console.log('Video field config - parse:', media)
//               return `https://einstein-clients.imgix.net${media.directory}/${media.filename}`
//             },
//             previewSrc: (src) => {
//               console.log('previewSrc', src)
//               return src
//             },
//           },
//         ],
//       },
//       {
//         name: 'description',
//         label: 'Description',
//         component: 'html',
//       },
//       {
//         name: 'outro',
//         label: 'Outro',
//         component: 'html',
//       },
//       {
//         name: 'button',
//         label: 'Button',
//         component: 'group',
//         fields: [...buttonFields],
//       },
//     ],
//     itemProps: (item) => {
//       const tempDiv = document.createElement('div')
//       tempDiv.innerHTML = item.title
//       return {
//         label: tempDiv.textContent,
//       }
//     },
//   },
// }

// export const templateName = 'videowide'
