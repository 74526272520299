import Section from '../shared/Section'
import SectionText from '../shared/SectionText'
import {SOCIAL_STRIPE_ITEM_BLOCKS} from './config'
import styled from 'styled-components'
import LucidInlineBlocks from '../shared/LucidInlineBlocks'


const StyledSection = styled(Section)`
  .section-content,
  .section-item-wrapper {
    display: flex;
    align-items: center;
  }

  p {
    margin-right: 0.5rem;
    font-size: 1.5rem;
  }

  a {
    text-decoration: none;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  svg {
    width: 60px;
    height: 60px;
  }
`

export default function SocialStripe(props) {
  const {
    title,
    color_scheme,
    className,
    css_overrides,
    color_scheme_id_override,
    lucid_page_section_id,
    cms,
    meta
  } = props
  return (
    <StyledSection
      cms={cms}
      css_overrides={css_overrides}
      color_scheme_id_override={color_scheme_id_override} meta={meta}
      lucid_page_section_id={lucid_page_section_id}
      className={className}
      name="socialstripe"
      colorSchemeId={color_scheme}>
      <p>
        <SectionText name="title" text={title} cms={cms}/>
      </p>
      <LucidInlineBlocks
        itemProps={props}
        name="socialstripe_items"
        blocks={SOCIAL_STRIPE_ITEM_BLOCKS}
        className="section-item-wrapper"
      />
    </StyledSection>
  )
}
