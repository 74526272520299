import {
  Menu,
  MenuButton,
  MenuItems,
  MenuLink,
  MenuPopover,
} from '@reach/menu-button'
import '@reach/menu-button/styles.css'
import Link from 'next/link'
import styled from 'styled-components'

// props need to be string, boolean would give an error React/Dom
const StyledMenuButton = styled(MenuButton)<{ is_expanded: string }>`
  background-color: inherit;
  color: #716c7f;
  width: 150px;
  cursor: pointer;
  height: 50px;

  ${(props) =>
    props.is_expanded === 'true'
      ? `border: 1px lightgray solid;
      box-shadow: 2px 2px 8px lightgray;
      border-radius: 7px 7px 0 0;`
      : `border: none;
      box-shadow: none;
      border-radius: 0;`};
`

const StyledMenuPopover = styled(MenuPopover)`
  // Tina top bar z-index is 2500
  z-index: 2501;
  width: 150px;
  border-bottom: 1px lightgray solid;
  border-left: 1px lightgray solid;
  border-right: 1px lightgray solid;
  border-radius: 0 0 7px 7px;
  background-color: #f6f6f9;
  overflow: hidden;
  box-shadow: 2px 2px 8px lightgray;
`

const StyledMenuItems = styled(MenuItems)`
  width: 150px;
  background-color: inherit;
  border: none;
  padding: 0;
  text-align: center;
  > a {
      height: 50px;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    color: #716c7f;
    text-decoration: none;
  }

  &:hover {
    background-color: #0574e4;
    > a {
      text-decoration: underline;
      color: white;
    }
    > div {
      color: white;
      background-color: inherit !important;
    }
  }
`

const ManagersMenu = () => (
  <>
    <Menu>
      {({isExpanded}) => (
        <>
          <StyledMenuButton is_expanded={isExpanded.toString()}>
            Managers{' '}
            <span aria-hidden style={{color: '#0574e4', fontSize: '1rem'}}>
              ▾
            </span>
          </StyledMenuButton>
          <StyledMenuPopover>
            <StyledMenuItems>
              <MenuLink as={Link} href="/sites">
                Sites
              </MenuLink>
            </StyledMenuItems>
            <StyledMenuItems>
              <MenuLink as={Link} href="/users">
                Users
              </MenuLink>
            </StyledMenuItems>
            <StyledMenuItems>
              <MenuLink as={Link} href="/roles">
                Roles
              </MenuLink>
            </StyledMenuItems>
            <StyledMenuItems>
              <MenuLink as={Link} href="/sections">
                Sections
              </MenuLink>
            </StyledMenuItems>
          </StyledMenuPopover>
        </>
      )}
    </Menu>
  </>
)

export default ManagersMenu
