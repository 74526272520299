import Section from '../shared/Section'
import StyledHeading from '../shared/StyledHeading'
import {InlineTextWithVariables, RichtextWithVariables} from '../shared/SectionsWithVariables'
import {AFFILIATIONS_ITEM_BLOCKS} from './config'
import styled from 'styled-components'
import LucidInlineBlocks from '../shared/LucidInlineBlocks'
import SectionButton from '../shared/SectionButton'

const StyledInlineBlocks = styled(LucidInlineBlocks)`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
`

export default function Affiliations(props) {
  const {
    title,
    subtitle,
    intro,
    // items,
    outro,
    button,
    color_scheme,
    css_overrides,
    color_scheme_id_override, lucid_page_section_id, meta,
    className,
    cms,
  } = props
  return (
    <Section
      cms={cms}
      className={className}
      css_overrides={css_overrides}
      color_scheme_id_override={color_scheme_id_override} meta={meta}
      lucid_page_section_id={lucid_page_section_id}
      name="affiliations"
      colorSchemeId={color_scheme}>
      <StyledHeading>
        <h2>
          <InlineTextWithVariables cms={cms} name="title" value={title}/>
        </h2>
        <InlineTextWithVariables cms={cms} name="subtitle" value={subtitle}/>
      </StyledHeading>
      <RichtextWithVariables cms={cms} name="intro" richtext={intro}/>
      <StyledInlineBlocks
        name="affiliations_items"
        blocks={AFFILIATIONS_ITEM_BLOCKS}
        itemProps={props}
      />
      <RichtextWithVariables cms={cms} name="outro" richtext={outro}/>
      <SectionButton href={button?.href} label={button?.label} cms={cms} name='button'/>
    </Section>
  )
}
