import {createContext, PropsWithChildren, useReducer} from 'react'
import reducer, {ActionTypes} from './reducer'
import {BlockContents, Menu, Page, PageBuild} from '@/components/shared/types'
import {GraphQLColorSchemeResponse} from '@/components/managers'
import {StyleCoreSiteProvider} from '@/src/state/site/StyleCoreStore'
import {SiteThemeProvider} from '@/src/state/site/ThemeStore'

export type LucidState = {
  site_build_id: number
  menus: Menu[]
  pages: Page[]
  schemes: GraphQLColorSchemeResponse[]
  contents: BlockContents
  favicon: string
  pageBuild?: PageBuild
}

const initialState: LucidState = {
  site_build_id: 0,
  menus: [],
  pages: [],
  schemes: [],
  contents: {},
  favicon: '/favicon.ico'
}

export type Dispatcher = (arg: {type: typeof ActionTypes[number], payload: any}) => void

type LucidContextInterface = readonly [LucidState, Dispatcher]

export const LucidSiteContext = createContext<LucidContextInterface>([initialState, () => {}])

export const LucidSiteProvider = ({children}: PropsWithChildren<any>) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  return (
    <LucidSiteContext.Provider value={[state as LucidState, dispatch]}>
      <StyleCoreSiteProvider>
        <SiteThemeProvider>
          {children}
        </SiteThemeProvider>
      </StyleCoreSiteProvider>
    </LucidSiteContext.Provider>
  )
}
