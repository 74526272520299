import Section from '../shared/Section'
import StyledHeading from '../shared/StyledHeading'
import {InlineTextWithVariables, RichtextWithVariables} from '../shared/SectionsWithVariables'
import {FEATURES_LIST_ITEM_BLOCKS} from './config'
import styled from 'styled-components'
import LucidInlineBlocks from '../shared/LucidInlineBlocks'
import SectionButton from '../shared/SectionButton'


const StyledInlineBlocks = styled(LucidInlineBlocks)`
  .section-item-wrapper {
    display: grid;
    justify-items: center;
    align-items: flex-start;

    @media (min-width: 640px) {
      grid-template-columns: 120px auto repeat(2, 1fr);
    }

    h3 span {
      display: block;
    }

    .image-wrapper {
      padding-right: 1rem;
    }
  }
`

export default function FeaturesList(props) {
  const {
    title,
    subtitle,
    intro,
    outro,
    button,
    color_scheme,
    className,
    css_overrides,
    color_scheme_id_override, lucid_page_section_id, meta,
    cms,
  } = props
  return (
    <Section
      cms={cms}
      className={className}
      css_overrides={css_overrides} color_scheme_id_override={color_scheme_id_override} meta={meta} lucid_page_section_id={lucid_page_section_id}
      name="featureslist"
      colorSchemeId={color_scheme}
    >
      <StyledHeading>
        <h2>
          <InlineTextWithVariables cms={cms} name="title" value={title} />
        </h2>
        <InlineTextWithVariables cms={cms} name="subtitle" value={subtitle} />
      </StyledHeading>
      <RichtextWithVariables
        name="intro"
        richtext={intro}
        cms={cms}
      />
      <StyledInlineBlocks
        itemProps={props}
        name="features_list_items"
        blocks={FEATURES_LIST_ITEM_BLOCKS}
      />
      <RichtextWithVariables
        name="outro"
        richtext={outro}
        cms={cms}
      />
      <SectionButton name='button' href={button?.href} label={button?.label} cms={cms} />
    </Section>
  )
}
