import {BlocksControls} from '@einsteinindustries/react-tinacms-inline'
import SectionImage from '../shared/SectionImage'
import {RichtextWithVariables} from '../shared/SectionsWithVariables'
import SectionText from '../shared/SectionText'

// TODO: Use actual `cms`

// Base item for all the other item types to pull from
const baseItem = {
  label: 'Item',
  defaultItem: {
    title: 'Title',
    subtitle: 'Subtitle',
  },
  itemProps: (item) => {
    if (!item.title && !item.subtitle) {
      return
    }

    return {label: item.title || item.subtitle}
  },
  // TODO: Create a set of default fields
  fields: [
    {
      name: 'title',
      label: 'Title',
      component: 'text',
    },
    {
      name: 'subtitle',
      label: 'Subtitle',
      component: 'text',
    },
  ],
}

// Templates to use in the parent's block config and the InlineBlocks component
const itemTemplates = {
  pods: {
    ...baseItem,
    label: 'Pods Item',
    defaultItem: {
      ...baseItem.defaultItem,
      image: {
        src: 'https://tools--dev.cms.eiidev.com/placeholder/600/600',
        alt: '',
      },
      description:
        '<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci expedita doloremque temporibus ipsum illo vero aut voluptas laborum ex dignissimos numquam at odio, aspernatur voluptatum, provident sit nesciunt! Dicta, natus.</p>',
    },
    fields: [
      ...baseItem.fields,
      {
        name: 'image',
        label: 'Image',
        component: 'group',
        fields: [
          {
            name: 'src',
            label: 'Image',
            component: 'image',
            description: 'Image description',
            clearable: true,
            parse: (media) => {
              if (!media) {
                return
              }
              // console.log('image field config - parse:', media)
              return `${process.env.NEXT_PUBLIC_IMGIX_READ_URL}${media.directory}/${media.filename}`
            },
            previewSrc: (src) => {
              console.log('previewSrc', src)
              return src
            },
          },
          {
            name: 'alt',
            label: 'Image Alt',
            component: 'text',
          },
        ],
      },
      {
        name: 'description',
        label: 'Description',
        component: 'html',
      },
    ],
  },
}

// Tina form config for MenuDemo
// export const blockConfig = {
//   Component(props) {
//     const { index, data } = props

//     return (
//       <BlocksControls
//         index={index}
//         focusRing={{ offset: -4, borderRadius: 4 }}
//         insetControls
//       >
//         <Pods {...data} />
//       </BlocksControls>
//     )
//   },
//   template: {
//     ...baseItem,
//     label: 'Pods',
//     defaultItem: {
//       title: 'Pods',
//       subtitle: '',
//       intro: '',
//       pods_items: [
//         {
//           // id: 1,
//           _template: 'pods',
//           ...baseItem.defaultItem,
//           image: {
//             src: 'https://tools--dev.cms.eiidev.com/placeholder/600/600',
//             alt: '',
//           },
//           description:
//             '<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci expedita doloremque temporibus ipsum illo vero aut voluptas laborum ex dignissimos numquam at odio, aspernatur voluptatum, provident sit nesciunt! Dicta, natus.</p>',
//         },
//       ],
//       outro: '',
//       button: {
//         href: '',
//         label: '',
//       },
//     },
//     fields: [
//       ...baseItem.fields,
//       {
//         name: 'intro',
//         label: 'Intro',
//         component: 'html',
//       },
//       {
//         name: 'pods_items',
//         label: 'Items',
//         component: 'blocks',
//         templates: itemTemplates,
//       },
//       {
//         name: 'outro',
//         label: 'Outro',
//         component: 'html',
//       },
//       {
//         name: 'button',
//         label: 'Button',
//         component: 'group',
//         fields: [...buttonFields],
//       },
//     ],
//   },
// }

// InlineBlocks `blocks`
export const PODS_ITEM_BLOCKS = {
  pods: {
    Component({_name, index, data}) {
      return (
        <ItemWrapper index={index}>
          <div className="section-item-wrapper">
            <div className="image-wrapper">
              <SectionImage
                name="image.src"
                src={data.image.src}
                alt={data.image.alt}
                // width={image.width}
                // height={image.height}
                cms={data.cms}
              />
            </div>
            <div className="content-wrapper">
              <ItemHeader title={data.title} subtitle={data.subtitle} />
              <RichtextWithVariables
                name="description"
                richtext={data.description}
                cms={data.cms}
              />
            </div>
          </div>
        </ItemWrapper>
      )
    },
    template: itemTemplates.pods,
  },
}

function ItemWrapper(props) {
  return (
    <BlocksControls
      index={props.index}
      focusRing={{offset: 16, borderRadius: 4}}
      insetControls={false}
    >
      {props.children}
    </BlocksControls>
  )
}

function ItemHeader(props) {
  if (!props.title && !props.subtitle) {
    return null
  }
  return (
    <h3>
      {props.title ? (
        <span className="title">
          <SectionText name="title" text={props.title} cms={props.cms} />
        </span>
      ) : null}
      {props.subtitle ? (
        <span className="subtitle">
          <SectionText name="subtitle" text={props.subtitle} cms={props.cms} />
        </span>
      ) : null}
    </h3>
  )
}
