// @ts-nocheck
import {DialogContent, DialogOverlay} from '@reach/dialog'
import {useState} from 'react'
import {UserComboBox} from '../PageManager/components/PageDialog'
import {Form, useCMS} from '@einsteinindustries/tinacms'
import {Page, Status, User} from '../../shared/types'
import styled, {createGlobalStyle, css} from 'styled-components'
import {enumStagesOrder} from '@/components/managers/PageManager/constants'
import LucidAlert from '../../../components/shared/LucidAlert'
import {updatePageStage} from '../../../components/managers/PageManager/components/AssignUserGroup'
import RejectDialog from '../../../components/managers/PageManager/components/RejectDialog'
import ButtonBar from '@/components/managers/PageManager/components/utils/ButtonBar'
import {Menu, MenuButton, MenuItem, MenuItems, MenuPopover,} from '@reach/menu-button'
import '@reach/menu-button/styles.css'
import useUserSearch from '@/src/utils/useUserSearch'
import {roundClickable} from '@/src/styles/home'

const tinaButtonStyleDisable = css`
  filter: grayscale(25%);
  opacity: 0.6;
  cursor: default;
`

export const CTA = styled.button<{ is_expanded?: string }>`
  ${roundClickable};
  ${(props) => props.disabled && tinaButtonStyleDisable}

  ${(props) => props.is_expanded === 'true' && 'border-bottom-left-radius: 0px'}
`

const StyledMenuButton = styled(MenuButton)<{ is_expanded: string }>`
  ${roundClickable};
  ${(props) => props.disabled && tinaButtonStyleDisable}
  width: 33px;
  background-color: var(--tina-color-primary);
  color: var(--tina-color-grey-0);
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  padding: 0 var(--tina-padding-small);

  ${(props) =>
    props.is_expanded === 'true' && 'border-bottom-right-radius: 0px'}
`

const StyledMenuPopover = styled(MenuPopover)`
  border-top: 1px solid #ccc;
  z-index: 2501;
  color: var(--tina-color-grey-0);
`

const Container = styled.div`
  z-index: 1;
  position: absolute;
  right: 60px;
`

export const buttonAccept = {
  PROPOSED: 'Submit',
  IN_PROGRESS: 'Submit',
  READY_FOR_REVIEW: 'Approve',
  APPROVED: 'Publish', // TODO: Is this correct?
}
const arrayStages: Status[] = Object.keys(enumStagesOrder) as Status[]
// Special case: published page can't go higher -> un_publish will bring back to in progress
export const getNextStage = (status: Status) =>
  status === 'APPROVED'
    ? 'IN_PROGRESS'
    : arrayStages[arrayStages.indexOf(status) + 1]

interface AssignModalProps {
  pages: Page[]
  showDialog: boolean
  closeDialog: () => void
  isAction?: boolean
}

export const AssignModal = ({
  pages,
  showDialog,
  closeDialog,
  isAction,
}: AssignModalProps) => {
  const firstPage = pages[0]
  const [assigneeInput, setAssigneeInput] = useState('')
  const [showRejectDialog, setShowRejectDialog] = useState(false)
  const [showPublishWizard, setShowPublishWizard] = useState(false)
  const users = useUserSearch(assigneeInput)
  const cms = useCMS()
  const lucidAlert = new LucidAlert(cms)

  const isReject =
    firstPage.status === 'IN_REVIEW' || firstPage.status === 'APPROVED'

  const isAssigned = () =>
    (users.length === 1 && firstPage.user?.id !== users[0].id) ||
    (!users.length && firstPage.user)

  const handleSave = async (approve: boolean) => {
    let newStage
    if (!approve) {
      setShowRejectDialog(true)
      return
    }
    // publish page wizard
    if (firstPage.status === 'APPROVED' && isAction) {
      setShowPublishWizard(true)
      return
    }
    if (isAction) {
      newStage = getNextStage(firstPage.status)
    } else {
      newStage =
        firstPage.status === 'PROPOSED' ? 'IN_PROGRESS' : firstPage.status
    }
    const msg = isAction
      ? `${firstPage.name} will change from ${firstPage.status} to ${newStage}`
      : `${firstPage.user?.name || 'unassigned'} will be assigned to ${
          users[0].name
        }`
    const confirmDialog = confirm(msg)
    if (!confirmDialog) return

    await handleUpdatePage(users[0] || {id: 0}, newStage)
  }

  const handleUpdatePage = async (user: User, status: Status) => {
    if (isAction || isAssigned()) {
      console.log(status)
      for await (const page of pages) {
        const variables = {
          newPage: {
            params: {
              userId: Number(user.id),
              status,
            },
            id: page.id,
          },
        }
        await updatePageStage(variables, lucidAlert, page, user, status)
      }
    }
    closeDialog()
  }

  return (
    <>
      <DialogOverlay
        isOpen={showDialog}
        onDismiss={closeDialog}
        style={{zIndex: 9000}}
      >
        {' '}
        <DialogContent
          style={{
            borderRadius: '3px',
            boxShadow:
              'rgba(9, 30, 66, 0.08) 0px 0px 0px 1px, rgba(9, 30, 66, 0.08) 0px 2px 1px, rgba(9, 30, 66, 0.31) 0px 0px 20px -6px',
            overflow: 'scroll',
            height: '50vh',
            width: '50vw',
          }}
          aria-labelledby={`label--${firstPage.id}`}
        >
          <div
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <div style={{marginTop: '15px'}}>
              <span>Assign a user to this page</span>
              <UserComboBox
                users={users}
                assigneeInput={assigneeInput}
                setAssigneeInput={setAssigneeInput}
              />
            </div>
            <ButtonBar
              closeDialog={closeDialog}
              isValid={!(!isAction && !isAssigned())}
              nameAction={isAction ? buttonAccept[firstPage.status] : 'Submit'}
              isReject={!!isAction && isReject}
              handleSave={handleSave}
            />
          </div>
        </DialogContent>{' '}
      </DialogOverlay>
      {showRejectDialog && (
        <RejectDialog
          showDialog={showRejectDialog}
          closeDialog={() => setShowRejectDialog(false)}
          pages={pages}
          onSave={closeDialog}
        />
      )}
      {/*{showPublishWizard && (
        <PublishPagesWizard
          showDialog={showPublishWizard}
          pages={pages}
          onSave={closeDialog}
          closeDialog={() => setShowPublishWizard(false)}
          allPages={allPages}
          allRoutes={routes}
        />
      )}*/}
    </>
  )
}

interface AssignToUserProps {
  page: Page
  isAction?: boolean
}

type ButtonTitle = {
  in_progress: string
  in_review: string
  approved: string
  published: string
}

const buttonTitle: ButtonTitle = {
  in_progress: 'Submit for Approval',
  in_review: 'Approve/Reject',
  approved: 'Publish/Reject',
  published: 'Unpublish',
}

type ButtonTitleKeys = keyof ButtonTitle

const AssignToUser = ({page}: AssignToUserProps) => {
  const [dialog, setDialog] = useState({show: false, isAction: false})
  const [expended, setExpended] = useState(false)
  // need to be sure that the page is up-to-date
  // const { data } = useSWR(ADMIN_PAGE_QUERY, lucidDataFetcher({ id: page.id }))
  const cms = useCMS()
  // TODO: Dispatch event in tina
  const forms = cms.plugins.getType<Form>('form')
  const form = forms.all().length ? forms.all()[0] : null
  const currentState = form?.finalForm.getState()

  const dispatchClick = () => {
    // emulate click on Tina's save button
    const saveButton =
      document.querySelectorAll<HTMLElement>('[class*="save"i]')[0]
    saveButton.click()
  }

  const currentPage = page

  const excludedStages = ['proposed', 'published', 'archived']

  return (
    <Container id="lucid-save-button">
      <CTA
        style={{
          backgroundColor: 'var(--tina-color-primary)',
          color: 'var(--tina-color-grey-0)',
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
          padding: 'var(--tina-padding-small)',
          width: '100px',
        }}
        onClick={dispatchClick}
        disabled={currentState?.pristine}
        is_expanded={expended.toString()}
      >
        Save
      </CTA>
      <Menu>
        {({isExpanded}) => (
          <>
            {isExpanded ? setExpended(true) : setExpended(false)}
            <StyledMenuButton
              is_expanded={isExpanded.toString()}
              disabled={currentState?.pristine}
            >
              <span aria-hidden>▾</span>
            </StyledMenuButton>
            <StyledMenuPopover>
              <div className="arbitrary-element">
                <MenuItems
                  style={{
                    backgroundColor: 'var(--tina-color-primary)',
                    border: 'none',
                    width: '133px',
                  }}
                >
                  <MenuItem
                    style={{
                      backgroundColor: 'var(--tina-color-primary)',
                    }}
                    onSelect={() => setDialog({show: true, isAction: false})}
                  >
                    Assign User
                  </MenuItem>
                  {!excludedStages.includes(currentPage.status) && (
                    <MenuItem
                      style={{backgroundColor: 'var(--tina-color-primary)'}}
                      onSelect={() => setDialog({show: true, isAction: true})}
                    >
                      {buttonTitle[currentPage.status as ButtonTitleKeys]}
                    </MenuItem>
                  )}
                </MenuItems>{' '}
              </div>{' '}
            </StyledMenuPopover>
          </>
        )}
      </Menu>

      {dialog.show && (
        <AssignModal
          pages={[currentPage]}
          showDialog={dialog.show}
          closeDialog={() => setDialog({show: false, isAction: false})}
          isAction={dialog.isAction}
        />
      )}
      <GlobalStyle />
    </Container>
  )
}

// Tina save button styling
const GlobalStyle = createGlobalStyle`
  [class*="SaveButton"][class*="ToolbarButton"] {
    background-color: var(--tina-color-grey-1) !important;
    width: 130px;
  }
`

export default AssignToUser
