import {
  Page,
  PageBuild,
  Status,
  User,
} from '../../../../components/shared/types'
import {
  FilterHeader,
  PagesArray,
} from '../../../../components/managers/PageManager/components/newWizards/NewProjectWizard'
import React, {Dispatch, useEffect, useState} from 'react'
import {
  buttonAccept,
  getNextStage,
} from '../../../../components/managers/Toolbar/AssignToUserButton'
import {
  applyFiltering,
  genericSearch,
  handleMutation,
} from '../../../../components/managers/PageManager/utils'
import {
  ASSIGN_PAGE_BUILD_TO_USER,
  SET_PAGE_BUILD_STATUS,
} from '../../../../graphql/mutations'
import {mutate} from 'swr'
import {ALL_PAGES} from '../../../../graphql/queries'
import {DialogReducerAction} from '../dialogReducer'
import {
  closeAssignUserGroupDialog,
  openPublishWizard,
  openRejectDialog,
} from '../dialogUtilityFunctions'
import useUserSearch from '../../../../utils/useUserSearch'
import DialogLayout from '@/components/shared/DialogLayout'
import UserComboBox from '@/components/shared/UserComboBox'

interface AssignUserGroupProps {
  showDialog: boolean
  selectedPagesProps: string[]
  pages: PageBuild[]
  action: string
  dispatchAction: Dispatch<DialogReducerAction>
}

const MISSING_USER_MESSAGE = 'Select user to continue'
const MISSING_PAGE_MESSAGE = 'Select pages to continue'

export async function updatePages(
  selectedPages: PageBuild[],
  stage: Status | undefined,
  user: User | undefined
) {
  // TODO: Unassigned user mutation
  if (!user) return

  let variables: object = selectedPages.map((page) => ({
    page_build_id: page.id,
    user_id: user?.id,
  }))

  let message = {
    error: `Something went wrong while assigning ${user.name} to the pages`,
    success: `The pages were assigned successfully to ${user.name}`,
  }
  await handleMutation(ASSIGN_PAGE_BUILD_TO_USER, variables, message, () =>
    mutate(ALL_PAGES)
  )

  variables = selectedPages.map((page) => ({
    page_build_id: page.id,
    status: stage || (page.status === 'PROPOSED' ? 'IN_PROGRESS' : page.status),
  }))
  // TODO: In the case that a page is only being assigned, we should not be touching its status
  message = {
    error: 'Something went wrong while updating the status of the pages.',
    success: 'Pages status was updated successfully.',
  }
  await handleMutation(SET_PAGE_BUILD_STATUS, variables, message, () =>
    mutate(ALL_PAGES)
  )
}

const stagesWithoutReject = ['all', 'IN_PROGRESS', 'PUBLISHED']

const AssignUserGroup = ({
  showDialog,
  selectedPagesProps,
  pages,
  action,
  dispatchAction,
}: AssignUserGroupProps) => {
  const [selectedPagesId, setSelectedPagesId] =
    useState<string[]>(selectedPagesProps)
  const [user, setUser] = useState<User | undefined>(undefined)
  // action === approved => pages to be published
  const isValid = !!(selectedPagesId.length && (user || action === 'APPROVED'))

  const errorMessage = !selectedPagesId.length
    ? MISSING_PAGE_MESSAGE
    : !user && action !== 'APPROVED'
    ? MISSING_USER_MESSAGE
    : ''

  const handleChange = (pageId: string) => {
    if (selectedPagesId.includes(pageId)) {
      setSelectedPagesId([...selectedPagesId.filter((id) => id !== pageId)])
    } else {
      setSelectedPagesId([...selectedPagesId, pageId])
    }
  }

  const selectedAllPages = () => {
    if (!selectedPagesId.length) {
      setSelectedPagesId(
        pages.reduce<string[]>((acc, curr) => [...acc, curr.id], [])
      )
    } else {
      setSelectedPagesId([])
    }
  }

  const handleUpdatePage = async (approved: boolean) => {
    if (isValid) {
      // get pages from ids
      const selectedPages = pages.filter((page) =>
        selectedPagesId.includes(page.id)
      )

      // show reject dialog
      if (!approved) {
        openRejectDialog([selectedPages, user!], dispatchAction)
        return
      }

      // we have a wizard for published pages
      if (action === 'APPROVED') {
        openPublishWizard(selectedPages, dispatchAction)
        return
      }
      const newStage =
        action !== 'all'
          ? approved
            ? getNextStage(action as Status)
            : 'IN_PROGRESS'
          : undefined
      await updatePages(selectedPages, newStage, user)

      closeAssignUserGroupDialog(dispatchAction)
    }
  }

  return (
    <DialogLayout
      title={`Group Action - Assign User ${
        !!errorMessage ? ' - ' + errorMessage : ''
      }`}
      showDialog={showDialog}
      closeDialog={() => closeAssignUserGroupDialog(dispatchAction)}
      onSave={{
        name: buttonAccept[
          action === 'all' ? 'IN_PROGRESS' : (action as Status)
        ],
        action: () => handleUpdatePage(true),
      }}
      isSaveAllowed={isValid}
      optionalAction={
        !stagesWithoutReject.includes(action) && isValid
          ? {name: 'reject', action: () => handleUpdatePage(false)}
          : undefined
      }
    >
      <PagesArrayWithUser
        pages={pages}
        selectedPages={selectedPagesId}
        handleChange={handleChange}
        selectAllPages={selectedAllPages}
        setUser={setUser}
        isDisplayUser={action !== 'approved'}
        filterAction={{filterStatus: action, filterAssignee: 'all'}}
      />
    </DialogLayout>
  )
}

interface PagesArrayWithUserProps {
  pages: PageBuild[]
  handleChange: (pageId: string) => void
  selectedPages: string[]
  selectAllPages: () => void
  setUser: (user: User) => void
  isDisplayUser: boolean
  filterAction?: { filterStatus: string; filterAssignee: string }
}

const PagesArrayWithUser = ({
  pages,
  handleChange,
  selectedPages,
  selectAllPages,
  filterAction,
  setUser,
  isDisplayUser,
}: PagesArrayWithUserProps) => {
  const [searchInput, setSearchInput] = useState('')
  const [filterState, setFilterState] = useState(filterAction || null)
  const [assigneeInput, setAssigneeInput] = useState('')
  const users = useUserSearch(assigneeInput)
  useEffect(() => {
    setUser(users[0])
  }, [setUser, users])

  const filteredPages = pages.filter((page) =>
    applyFiltering(page, filterState)
  )

  const searchedPages = filteredPages.filter((page) =>
    genericSearch(page, searchInput)
  )

  // selected pages first
  searchedPages.sort((a) => {
    const isASelected = selectedPages.includes(a.id)
    if (isASelected) {
      return -1
    } else {
      return 1
    }
  })

  return (
    <div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          position: 'sticky',
          top: 0,
          backgroundColor: 'white',
          zIndex: 10,
        }}
      >
        <FilterHeader
          pages={pages}
          setFilterState={setFilterState}
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          style={{paddingTop: '15px'}}
          initialFilterState={filterAction}
        />
        {isDisplayUser && (
          <div style={{display: 'flex', alignItems: 'center'}}>
            <span style={{marginRight: '12px', color: '#757575'}}>
              Assign to User:
            </span>
            <UserComboBox
              users={users}
              assigneeInput={assigneeInput}
              setAssigneeInput={setAssigneeInput}
            />
          </div>
        )}
      </div>
      <br />
      <span>
        Select the pages to be moved:{' '}
        <button onClick={selectAllPages}>
          {selectedPages.length ? 'Unselect All' : 'Select All'}
        </button>
      </span>

      <PagesArray
        selectedPages={selectedPages}
        handleChange={handleChange}
        searchedPages={searchedPages}
        style={{marginBottom: '80px'}}
      />
    </div>
  )
}

export default AssignUserGroup
