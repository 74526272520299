import StyledHeading from './shared/StyledHeading'
import {InlineTextWithVariables, RichtextWithVariables} from './shared/SectionsWithVariables'
import SectionImage from './shared/SectionImage'
import Section from './shared/Section'
import styled from 'styled-components'
import SectionButton from './shared/SectionButton'

const Columns = styled.div`
  max-width: 1440px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-flow: row wrap;
`

const Column = styled.div`
  display: flex;
  flex-flow: column wrap;
  flex: 1 1 0px;
  padding: 0 1rem;
`

export default function TextblockColumns3(props) {
  const {
    title,
    subtitle,
    intro,
    column_1_title,
    column_1_image,
    column_1_richtext,
    column_2_title,
    column_2_image,
    column_2_richtext,
    column_3_title,
    column_3_image,
    column_3_richtext,
    outro,
    button,
    color_scheme,
    className,
    css_overrides,
    color_scheme_id_override,
    lucid_page_section_id, meta,
    cms,
  } = props
  return (
    <Section
      cms={cms}
      css_overrides={css_overrides}
      color_scheme_id_override={color_scheme_id_override} meta={meta}
      lucid_page_section_id={lucid_page_section_id}
      className={className}
      name="textblockcolumns3"
      colorSchemeId={color_scheme}>
      <StyledHeading>
        <h2>
          <InlineTextWithVariables cms={cms} name="title" value={title}/>
        </h2>
        <InlineTextWithVariables cms={cms} name="subtitle" value={subtitle}/>
      </StyledHeading>
      <RichtextWithVariables cms={cms} name="intro" richtext={intro}/>
      <Columns>
        <Column>
          <RichtextWithVariables
            name="column_1_title"
            richtext={column_1_title}
            cms={cms}
          />
          <SectionImage
            name='column_1_image'
            src={column_1_image?.src}
            alt={column_1_image?.alt}
            width={column_1_image?.width}
            height={column_1_image?.height}
            cms={cms}
          />
          <RichtextWithVariables
            name="column_1_richtext"
            richtext={column_1_richtext}
            cms={cms}
          />
        </Column>
        <Column>
          <RichtextWithVariables
            name="column_2_title"
            richtext={column_2_title}
            cms={cms}
          />
          <SectionImage
            name='column_2_image'
            src={column_2_image?.src}
            alt={column_2_image?.alt}
            width={column_2_image?.width}
            height={column_2_image?.height}
            cms={cms}
          />
          <RichtextWithVariables
            name="column_2_richtext"
            richtext={column_2_richtext}
            cms={cms}
          />
        </Column>
        <Column>
          <RichtextWithVariables
            name="column_3_title"
            richtext={column_3_title}
            cms={cms}
          />
          <SectionImage
            name='column_3_image'
            src={column_3_image?.src}
            alt={column_3_image?.alt}
            width={column_3_image?.width}
            height={column_3_image?.height}
            cms={cms}
          />
          <RichtextWithVariables
            name="column_3_richtext"
            richtext={column_3_richtext}
            cms={cms}
          />
        </Column>
      </Columns>
      <RichtextWithVariables cms={cms} name="outro" richtext={outro}/>
      <SectionButton
        name={'button'}
        href={button?.url}
        label={button?.label}
        cms={cms}
      />
    </Section>
  )
}

// export const blockConfig = {
//   Component(props) {
//     const { index, data } = props

//     return (
//       <BlocksControls
//         index={index}
//         // focusRing={{ offset: -4, borderRadius: 4 }}
//         insetControls
//       >
//         {/* eslint-disable-next-line */}
//         <TextblockColumns3 {...data} />
//       </BlocksControls>
//     )
//   },
//   template: {
//     label: 'Textblock Columns 3',
//     defaultItem: {
//       title: 'Textblock Columns 3 Section Title',
//       column_1_richtext:
//         '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam ac iaculis elit. Fusce nec risus sed mi efficitur sagittis. Nulla at est libero. Suspendisse ornare justo vel velit fermentum feugiat vitae vitae augue.</p>',
//       column_2_richtext:
//         '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam ac iaculis elit. Fusce nec risus sed mi efficitur sagittis. Nulla at est libero. Suspendisse ornare justo vel velit fermentum feugiat vitae vitae augue.</p>',
//       column_3_richtext:
//         '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam ac iaculis elit. Fusce nec risus sed mi efficitur sagittis. Nulla at est libero. Suspendisse ornare justo vel velit fermentum feugiat vitae vitae augue.</p>',
//     },
//     fields: [
//       {
//         name: 'title',
//         label: 'Title',
//         component: 'text',
//       },
//       {
//         name: 'column_1_title',
//         label: 'Column 1 title',
//         component: 'text',
//       },
//       {
//         name: 'column_1_richtext',
//         label: 'Column 1 Richtext',
//         component: 'html',
//       },
//       {
//         name: 'column_1_image',
//         label: 'Column 1 Image',
//         component: 'group',
//         fields: [
//           {
//             name: 'src',
//             label: 'Column 1 Image',
//             component: 'image',
//             description: 'Column 1 Image description',
//             clearable: true,
//             parse: (media) => {
//               if (!media) {
//                 return
//               }
//               // console.log('image field config - parse:', media)
//               return `https://einstein-clients.imgix.net${media.directory}/${media.filename}`
//             },
//             previewSrc: (src) => {
//               console.log('previewSrc', src)
//               return src
//             },
//           },
//           {
//             name: 'alt',
//             label: 'Column 1 Image Alt',
//             component: 'text',
//           },
//         ],
//       },
//       {
//         name: 'column_2_title',
//         label: 'Column 2 title',
//         component: 'text',
//       },
//       {
//         name: 'column_2_richtext',
//         label: 'Column 2 Richtext',
//         component: 'html',
//       },
//       {
//         name: 'column_2_image',
//         label: 'Column 2 Image',
//         component: 'group',
//         fields: [
//           {
//             name: 'src',
//             label: 'Column 2 Image',
//             component: 'image',
//             description: 'Column 2 Image description',
//             clearable: true,
//             parse: (media) => {
//               if (!media) {
//                 return
//               }
//               // console.log('image field config - parse:', media)
//               return `https://einstein-clients.imgix.net${media.directory}/${media.filename}`
//             },
//             previewSrc: (src) => {
//               console.log('previewSrc', src)
//               return src
//             },
//           },
//           {
//             name: 'alt',
//             label: 'Column 2 Image Alt',
//             component: 'text',
//           },
//         ],
//       },
//       {
//         name: 'column_3_title',
//         label: 'Column 3 title',
//         component: 'text',
//       },
//       {
//         name: 'column_3_richtext',
//         label: 'Column 3 Richtext',
//         component: 'html',
//       },
//       {
//         name: 'column_3_image',
//         label: 'Column 3 Image',
//         component: 'group',
//         fields: [
//           {
//             name: 'src',
//             label: 'Column 3 Image',
//             component: 'image',
//             description: 'Column 3 Image description',
//             clearable: true,
//             parse: (media) => {
//               if (!media) {
//                 return
//               }
//               // console.log('image field config - parse:', media)
//               return `https://einstein-clients.imgix.net${media.directory}/${media.filename}`
//             },
//             previewSrc: (src) => {
//               console.log('previewSrc', src)
//               return src
//             },
//           },
//           {
//             name: 'alt',
//             label: 'Column 3 Image Alt',
//             component: 'text',
//           },
//         ],
//       },
//       {
//         name: 'outro',
//         label: 'Outro',
//         component: 'html',
//       },
//     ],
//     itemProps: (item) => {
//       const tempDiv = document.createElement('div')
//       tempDiv.innerHTML = item.title
//       return {
//         label: tempDiv.textContent,
//       }
//     },
//   },
// }

// export const templateName = 'TextblockColumns3'
