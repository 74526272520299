import useSWR from 'swr'
import useDebounce from '@/src/utils/useDebounce'
import {SEARCH_USERS} from '@/graphql/queries'
import {lucidDataFetcherV2} from '@/graphql/fetchers'
import {User} from '@/components/shared/types'

export default function useUserSearch(userNameInput: string): User[] {
  const name = userNameInput.toLowerCase()
  const debouncedSearch = useDebounce(name, 1000)

  const {data: users} = useSWR(
      () => (debouncedSearch ? [SEARCH_USERS, {searchUserInput: {query: name}}] : null),
      lucidDataFetcherV2
  )

  return users?.data?.searchUsers || []
}
