import StyledHeading from '../shared/StyledHeading'
import {InlineTextWithVariables, RichtextWithVariables} from '../shared/SectionsWithVariables'
import SectionText from '../shared/SectionText'
import SectionImage from '../shared/SectionImage'
import Section from '../shared/Section'
import {TEXT_QUOTE_ITEM_BLOCKS} from './config'
import styled from 'styled-components'
import LucidInlineBlocks from '../shared/LucidInlineBlocks'
import SectionButton from '../shared/SectionButton'


const Columns = styled.div`
  max-width: 1440px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-flow: row wrap;
`

const Column = styled.div`
  display: flex;
  flex-flow: column wrap;
  flex: 1 1 0px;
  padding: 0 1rem;
`
const StyledInlineBlocks = styled(LucidInlineBlocks)`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
`

export default function TextblockQuoteCarousel(props) {
  const {
    title,
    subtitle,
    intro,
    column_1_title,
    column_1_image,
    column_1_richtext,
    column_2_title,
    column_2_image,
    column_2_richtext,
    outro,
    button,
    color_scheme,
    className,
    css_overrides,
    color_scheme_id_override, lucid_page_section_id, meta,
    cms
  } = props
  return (
    <Section
      cms={cms}
      css_overrides={css_overrides}
      color_scheme_id_override={color_scheme_id_override} meta={meta}
      lucid_page_section_id={lucid_page_section_id}
      className={className}
      name="textblockquotecarousel"
      colorSchemeId={color_scheme}>
      <StyledHeading>
        <h2>
          <InlineTextWithVariables cms={cms} name="title" value={title}/>
        </h2>
        <InlineTextWithVariables cms={cms} name="subtitle" value={subtitle}/>
      </StyledHeading>
      <RichtextWithVariables cms={cms} name="intro" richtext={intro}/>
      <Columns>
        <Column>
          {column_1_title ? (
            <h3>
              <SectionText
                name="column_1_title"
                text={column_1_title}
                cms={cms}
              />
            </h3>
          ) : null}
          <SectionImage
            name='column_1_image'
            src={column_1_image?.src}
            alt={column_1_image?.alt}
            width={column_1_image?.width}
            height={column_1_image?.height}
            cms={cms}
          />
          <RichtextWithVariables
            name="column_1_richtext"
            richtext={column_1_richtext}
            cms={cms}
          />
        </Column>
        <Column>
          {column_2_title ? (
            <h3>
              <SectionText
                name="column_2_title"
                text={column_2_title}
                cms={cms}
              />
            </h3>
          ) : null}
          <SectionImage
            name='column_2_image'
            src={column_2_image?.src}
            alt={column_2_image?.alt}
            width={column_2_image?.width}
            height={column_2_image?.height}
            cms={cms}
          />
          <RichtextWithVariables
            name="column_2_richtext"
            richtext={column_2_richtext}
            cms={cms}
          />
        </Column>
      </Columns>
      <StyledInlineBlocks name="items" blocks={TEXT_QUOTE_ITEM_BLOCKS} itemProps={props}/>
      <RichtextWithVariables cms={cms} name="outro" richtext={outro}/>
      <SectionButton
        name='button'
        href={button?.url}
        label={button?.label}
        cms={cms}
      />
    </Section>
  )
}
