import {FieldConfigAndOptionalsMerge} from '@/components/shared/externalTypes'
import {v4 as uuidv4} from 'uuid'
import {TypographyElement} from '@/components/editor/typography/types'

/**
 * The fields that can be configured for a typography element
 */
export const configFields: FieldConfigAndOptionalsMerge = {
  fontVariant: {
    name: 'Font Variant',
    component: 'select',
    options: [
      {label: 'Normal', value: 'normal'},
      {label: 'Small Caps', value: 'small-caps'}
    ],
    nameOverride: 'Font Variant',
    description: 'The font variant to use'
  },
  fontSize: {
    name: 'fontSize',
    component: 'text',
    description: 'The font size to use',
    nameOverride: 'Font Size',
    min: 0
  },
  fontWeight: {
    name: 'fontWeight',
    component: 'text',
    description: 'The font weight to use',
    nameOverride: 'Font Weight',
    min: 0
  },
  lineHeight: {
    name: 'lineHeight',
    component: 'text',
    nameOverride: 'Line Height',
    description: 'The line height to use'
  },
  letterSpacing: {
    name: 'letterSpacing',
    component: 'text',
    nameOverride: 'Letter Spacing',
    description: 'The letter spacing to use'
  },
  textIndent: {
    name: 'textIndent',
    component: 'text',
    nameOverride: 'Text Indent',
    description: 'The text indent to use'
  },
  textOverflow: {
    name: 'textOverflow',
    component: 'select',
    options: [
      {label: 'Clip', value: 'clip'},
      {label: 'Ellipsis', value: 'ellipsis'},
      {label: 'None', value: 'none'}
    ],
    nameOverride: 'Text Overflow',
    description: 'The text overflow to use'
  },
  textShadow: {
    name: 'textShadow',
    component: 'text',
    nameOverride: 'Text Shadow',
    description: 'The text shadow to use'
  },
  textTransform: {
    name: 'textTransform',
    component: 'select',
    options: [
      {label: 'None', value: 'none'},
      {label: 'Capitalize', value: 'capitalize'},
      {label: 'Uppercase', value: 'uppercase'},
      {label: 'Lowercase', value: 'lowercase'}
    ],
    nameOverride: 'Text Transform',
    description: 'The text transform to use'
  },
  whiteSpace: {
    name: 'whiteSpace',
    component: 'select',
    options: [
      {label: 'Normal', value: 'normal'},
      {label: 'No Wrap', value: 'nowrap'},
      {label: 'Pre', value: 'pre'},
      {label: 'Pre Wrap', value: 'pre-wrap'},
      {label: 'Pre Line', value: 'pre-line'}
    ],
    nameOverride: 'White Space',
    description: 'The white space to use'
  },
  wordBreak: {
    name: 'wordBreak',
    component: 'select',
    options: [
      {label: 'Normal', value: 'normal'},
      {label: 'Break All', value: 'break-all'},
      {label: 'Keep All', value: 'keep-all'},
      {label: 'Break Word', value: 'break-word'}
    ],
    nameOverride: 'Word Break',
    description: 'The word break to use'
  },
  wordSpacing: {
    name: 'wordSpacing',
    component: 'text',
    nameOverride: 'Word Spacing',
    description: 'The word spacing to use'
  }
}

/**
 * The fields that can be configured for a typography element grouped by name
 * This is used to display the fields in the editor
 */
export const configFieldsGrouping = [
  {
    name: 'Basics',
    description: 'Adjust Font Variant, Size, Weight and Shadow',
    fields: ['fontVariant', 'fontSize', 'fontWeight', 'textShadow']
  },
  {
    name: 'Kerning and Spacing',
    description: 'Adjust Kerning and Spacing',
    fields: ['textIndent', 'letterSpacing', 'wordSpacing', 'lineHeight']
  },
  {
    name: 'Transformation and Overflow',
    description: 'Adjust Text Transformation and Overflow',
    fields: ['textTransform', 'textOverflow', 'whiteSpace', 'wordBreak']
  }
]

/**
 * The default typography elements for a new group
 */
export const defaultTypographyGroupElements: TypographyElement[] = [
  {
    name: 'Heading 1',
    id: uuidv4(),
    configuration: {
      fonts: {
        primary: {
          name: 'IBM Plex Serif'
        },
        fallback: {
          name: 'Inter'
        }
      },
      fontSize: '2.5rem',
      fontWeight: '700',
      lineHeight: '1.2',
      letterSpacing: '-0.05em',
      textIndent: '0',
      textTransform: 'none',
      textOverflow: 'clip',
      whiteSpace: 'normal',
      wordBreak: 'normal',
      wordSpacing: 'normal',
      fontVariant: 'normal',
      textShadow: 'none'
    }
  },
  {
    name: 'Heading 2',
    id: uuidv4(),
    configuration: {
      fonts: {
        primary: {
          name: 'IBM Plex Serif'
        },
        fallback: {
          name: 'Inter'
        }
      },
      fontSize: '2rem',
      fontWeight: '700',
      lineHeight: '1.2',
      letterSpacing: '-0.05em',
      textIndent: '0',
      textTransform: 'none',
      textOverflow: 'clip',
      whiteSpace: 'normal',
      wordBreak: 'normal',
      wordSpacing: 'normal',
      fontVariant: 'normal',
      textShadow: 'none'
    }
  },
  {
    name: 'Heading 3',
    id: uuidv4(),
    configuration: {
      fonts: {
        primary: {
          name: 'IBM Plex Serif'
        },
        fallback: {
          name: 'Inter'
        }
      },
      fontSize: '1.75rem',
      fontWeight: '700',
      lineHeight: '1.2',
      letterSpacing: '-0.05em',
      textIndent: '0',
      textTransform: 'none',
      textOverflow: 'clip',
      whiteSpace: 'normal',
      wordBreak: 'normal',
      wordSpacing: 'normal',
      fontVariant: 'normal',
      textShadow: 'none'
    }
  },
  {
    name: 'Heading 4',
    id: uuidv4(),
    configuration: {
      fonts: {
        primary: {
          name: 'IBM Plex Serif'
        },
        fallback: {
          name: 'Inter'
        }
      },
      fontSize: '1.5rem',
      fontWeight: '700',
      lineHeight: '1.2',
      letterSpacing: '-0.05em',
      textIndent: '0',
      textTransform: 'none',
      textOverflow: 'clip',
      whiteSpace: 'normal',
      wordBreak: 'normal',
      wordSpacing: 'normal',
      fontVariant: 'normal',
      textShadow: 'none'
    }
  },
  {
    name: 'Heading 5',
    id: uuidv4(),
    configuration: {
      fonts: {
        primary: {
          name: 'IBM Plex Serif'
        },
        fallback: {
          name: 'Inter'
        }
      },
      fontSize: '1.25rem',
      fontWeight: '700',
      lineHeight: '1.2',
      letterSpacing: '-0.05em',
      textIndent: '0',
      textTransform: 'none',
      textOverflow: 'clip',
      whiteSpace: 'normal',
      wordBreak: 'normal',
      wordSpacing: 'normal',
      fontVariant: 'normal',
      textShadow: 'none'
    }
  },
  {
    name: 'Heading 6',
    id: uuidv4(),
    configuration: {
      fonts: {
        primary: {
          name: 'IBM Plex Serif'
        },
        fallback: {
          name: 'Inter'
        }
      },
      fontSize: '1rem',
      fontWeight: '700',
      lineHeight: '1.2',
      letterSpacing: '-0.05em',
      textIndent: '0',
      textTransform: 'none',
      textOverflow: 'clip',
      whiteSpace: 'normal',
      wordBreak: 'normal',
      wordSpacing: 'normal',
      fontVariant: 'normal',
      textShadow: 'none'
    }
  },
  {
    name: 'Paragraph',
    id: uuidv4(),
    configuration: {
      fonts: {
        primary: {
          name: 'IBM Plex Sans'
        },
        fallback: {
          name: 'Inter'
        }
      },
      fontSize: '1rem',
      fontWeight: '400',
      lineHeight: '1.5',
      letterSpacing: '0',
      textIndent: '0',
      textTransform: 'none',
      textOverflow: 'clip',
      whiteSpace: 'normal',
      wordBreak: 'normal',
      wordSpacing: 'normal',
      fontVariant: 'normal',
      textShadow: 'none'
    }
  },
  {
    name: 'Small',
    id: uuidv4(),
    configuration: {
      fonts: {
        primary: {
          name: 'IBM Plex Sans'
        },
        fallback: {
          name: 'Inter'
        }
      },
      fontSize: '0.875rem',
      fontWeight: '400',
      lineHeight: '1.5',
      letterSpacing: '0',
      textIndent: '0',
      textTransform: 'none',
      textOverflow: 'clip',
      whiteSpace: 'normal',
      wordBreak: 'normal',
      wordSpacing: 'normal',
      fontVariant: 'normal',
      textShadow: 'none'
    }
  },
  {
    name: 'Label',
    id: uuidv4(),
    configuration: {
      fonts: {
        primary: {
          name: 'IBM Plex Sans'
        },
        fallback: {
          name: 'Inter'
        }
      },
      fontSize: '0.875rem',
      fontWeight: '700',
      lineHeight: '1.5',
      letterSpacing: '0',
      textIndent: '0',
      textTransform: 'none',
      textOverflow: 'clip',
      whiteSpace: 'normal',
      wordBreak: 'normal',
      wordSpacing: 'normal',
      fontVariant: 'normal',
      textShadow: 'none'
    }
  },
  {
    name: 'Link',
    id: uuidv4(),
    configuration: {
      fonts: {
        primary: {
          name: 'IBM Plex Sans'
        },
        fallback: {
          name: 'Inter'
        }
      },
      fontSize: '1rem',
      fontWeight: '400',
      lineHeight: '1.5',
      letterSpacing: '0',
      textIndent: '0',
      textTransform: 'none',
      textOverflow: 'clip',
      whiteSpace: 'normal',
      wordBreak: 'normal',
      wordSpacing: 'normal',
      fontVariant: 'normal',
      textShadow: 'none'
    }
  },
  {
    name: 'Button',
    id: uuidv4(),
    configuration: {
      fonts: {
        primary: {
          name: 'IBM Plex Serif'
        },
        fallback: {
          name: 'Inter'
        }
      },
      fontSize: '1rem',
      fontWeight: '500',
      lineHeight: '1.5',
      letterSpacing: '0',
      textIndent: '0',
      textTransform: 'none',
      textOverflow: 'clip',
      whiteSpace: 'normal',
      wordBreak: 'normal',
      wordSpacing: 'normal',
      fontVariant: 'normal',
      textShadow: 'none'
    }
  },
  {
    name: 'Quote',
    id: uuidv4(),
    configuration: {
      fonts: {
        primary: {
          name: 'IBM Plex Serif'
        },
        fallback: {
          name: 'Inter'
        }
      },
      fontSize: '1.25rem',
      fontWeight: '400',
      lineHeight: '1.5',
      letterSpacing: '0',
      textIndent: '0',
      textTransform: 'none',
      textOverflow: 'clip',
      whiteSpace: 'normal',
      wordBreak: 'normal',
      wordSpacing: 'normal',
      fontVariant: 'normal',
      textShadow: 'none'
    }
  },
  {
    name: 'Code',
    id: uuidv4(),
    configuration: {
      fonts: {
        primary: {
          name: 'IBM Plex Mono'
        },
        fallback: {
          name: 'IBM Plex Mono'
        }
      },
      fontSize: '0.875rem',
      fontWeight: '400',
      lineHeight: '1.5',
      letterSpacing: '0',
      textIndent: '0',
      textTransform: 'none',
      textOverflow: 'clip',
      whiteSpace: 'normal',
      wordBreak: 'normal',
      wordSpacing: 'normal',
      fontVariant: 'normal',
      textShadow: 'none'
    }
  }
]

/**
 * A static configuration object that is used to define the default preview text
 */
export const previewText = {
  text: 'The quick brown fox jumps over the lazy dog',
  paragraph: `No one shall be subjected to arbitrary arrest, detention or exile.
Everyone is entitled in full equality to a fair and public hearing by an independent and impartial tribunal, in the determination of his rights and obligations and of any criminal charge against him.
No one shall be subjected to arbitrary interference with his privacy, family, home or correspondence, nor to attacks upon his honour and reputation. Everyone has the right to the protection of the law against such interference or attacks.`,
  custom: 'Custom text'
}

/**
 * A static configuration object that is used to define the default configuration
 */
export const defaultConfiguration = {
  configuration: {
    fonts: {
      primary: {
        name: 'Roboto'
      },
      fallback: {
        name: 'Roboto'
      }
    },
    fontVariant: 'normal',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '40px',
    letterSpacing: '0px',
    textIndent: '0px',
    textOverflow: 'clip',
    textShadow: '',
    textTransform: 'none',
    whiteSpace: 'normal',
    wordBreak: 'normal',
    wordSpacing: '2px'
  }
}
