import StyledHeading from './shared/StyledHeading'
import {InlineTextWithVariables, RichtextWithVariables} from './shared/SectionsWithVariables'
import Section from './shared/Section'
import SchemaVideo from './shared/SchemaVideo'
import SectionVideo from './shared/SectionVideo'
import SectionButton from './shared/SectionButton'
import {BUTTON_FIELDS} from './shared/InputTypesFields'

export default function Video(props) {
  const {
    title,
    subtitle,
    intro,
    video,
    outro,
    button,
    color_scheme,
    className,
    css_overrides,
    color_scheme_id_override,
    lucid_page_section_id, meta,
    cms,
  } = props
  return (
    <Section
      cms={cms}
      css_overrides={css_overrides} color_scheme_id_override={color_scheme_id_override} meta={meta} lucid_page_section_id={lucid_page_section_id}
      className={className}
      colorSchemeId={color_scheme}
      name="video"
    >
      <SchemaVideo
        name={title}
        description={video?.caption}
        thumbnailUrl={video?.poster}
        // TO-DO: need to pull date from video
        uploadDate="2017-06-08T19:24:45+08:00"
        // TO-DO: need to pull duration from video
        duration="PT0M11S"
        contentUrl={video?.src}
        embedUrl={video?.src}
      />
      <StyledHeading>
        <h2>
          <InlineTextWithVariables cms={cms} name="title" value={title} />
        </h2>
        <InlineTextWithVariables cms={cms} name="subtitle" value={subtitle} />
      </StyledHeading>
      <RichtextWithVariables
        name="intro"
        richtext={intro}
        cms={cms}
      />
      <SectionVideo
        title={title}
        src={video?.src}
        poster={video?.poster}
        size={video?.size}
      />
      <RichtextWithVariables
        name="caption"
        richtext={video?.caption}
        cms={cms}
      />
      <RichtextWithVariables
        name="outro"
        richtext={outro}
        cms={cms}
      />
      <SectionButton
        href={button?.url}
        label={button?.label}
        fields={BUTTON_FIELDS}
        cms={cms}
        name='button'/>
    </Section>
  )
}

// export const blockConfig = {
//   Component(props) {
//     const { index, data } = props

//     return (
//       <BlocksControls
//         index={index}
//         // focusRing={{ offset: -4, borderRadius: 4 }}
//         insetControls
//       >
//         {/* eslint-disable-next-line */}
//         <Video {...data} />
//       </BlocksControls>
//     )
//   },
//   template: {
//     label: 'Video',
//     defaultItem: {
//       title: 'Video Section Title',
//       subtitle: 'Subtitle',
//       video_src: schemaValues.contentUrl,
//       video_poster: schemaValues.thumbnailUrl,
//       button: {
//         label: 'Button',
//         url: '/test',
//       },
//     },
//     fields: [
//       {
//         name: 'title',
//         label: 'Title',
//         component: 'text',
//       },
//       {
//         name: 'subtitle',
//         label: 'Subtitle',
//         component: 'text',
//       },
//       {
//         name: 'intro',
//         label: 'Intro',
//         component: 'html',
//       },
//       {
//         name: 'video_src',
//         label: 'Video',
//         component: 'group',
//         fields: [
//           {
//             name: 'src',
//             label: 'Video',
//             component: 'image',
//             clearable: true,
//             parse: (media) => {
//               if (!media) {
//                 return
//               }
//               return `https://einstein-clients.imgix.net${media.directory}/${media.filename}`
//             },
//             previewSrc: (src) => {
//               console.log('previewSrc', src)
//               return src
//             },
//           },
//         ],
//       },
//       {
//         name: 'video_poster',
//         label: 'Poster',
//         component: 'group',
//         fields: [
//           {
//             name: 'src',
//             label: 'Poster',
//             component: 'image',
//             // caption: 'Image caption',
//             clearable: true,
//             parse: (media) => {
//               if (!media) {
//                 return
//               }
//               // console.log('Video field config - parse:', media)
//               return `https://einstein-clients.imgix.net${media.directory}/${media.filename}`
//             },
//             previewSrc: (src) => {
//               console.log('previewSrc', src)
//               return src
//             },
//           },
//         ],
//       },
//       {
//         name: 'caption',
//         label: 'caption',
//         component: 'html',
//       },
//       {
//         name: 'outro',
//         label: 'Outro',
//         component: 'html',
//       },
//       {
//         name: 'button',
//         label: 'Button',
//         component: 'group',
//         fields: [...buttonFields],
//       },
//     ],
//     itemProps: (item) => {
//       const tempDiv = document.createElement('div')
//       tempDiv.innerHTML = item.title
//       return {
//         label: tempDiv.textContent,
//       }
//     },
//   },
// }

// export const templateName = 'video'
