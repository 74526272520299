import {BlocksControls} from '@einsteinindustries/react-tinacms-inline'
import SectionImage from '../shared/SectionImage'
import SectionText from '../shared/SectionText'
import {RichtextWithVariables} from '../shared/SectionsWithVariables'

// export const templateName = 'menu_demo'

// TODO: Use actual `cms`

// Base item for all the other item types to pull from
const baseItem = {
  label: 'Item',
  defaultItem: {
    title: 'Title',
    subtitle: '',
  },
  itemProps: (item) => {
    if (!item.title && !item.subtitle) {
      return
    }

    return {label: item.title || item.subtitle}
  },
  // TODO: Create a set of default fields
  fields: [
    {
      name: 'title',
      label: 'Title',
      component: 'text',
    },
    {
      name: 'subtitle',
      label: 'Subtitle',
      component: 'text',
    },
  ],
}

// Templates to use in the parent's block config and the InlineBlocks component
const itemTemplates = {
  // This is in reference to the old `Menu Block` section. Not a TinaCMS `block`.
  // block: {
  //   ...baseItem,
  //   defaultItem: {
  //     ...baseItem.defaultItem,
  //     title: 'Block Item Title',
  //   },
  //   label: 'Block Item',
  // },
  icon: {
    ...baseItem,
    label: 'Icon Item',
    defaultItem: {
      ...baseItem.defaultItem,
      title: 'Icon Item Title',
      icon: '🚀',
    },
    fields: [
      ...baseItem.fields,
      {
        name: 'icon',
        label: 'Icon',
        component: 'select',
        options: ['🚀', '🚫', '✅', '⭐️'],
      },
    ],
  },
  photo: {
    ...baseItem,
    label: 'Photo Item',
    defaultItem: {
      ...baseItem.defaultItem,
      title: 'Photo Item Title',
      image: {
        src: '',
        alt: '',
      },
    },
    fields: [
      ...baseItem.fields,
      {
        name: 'image',
        label: 'Image',
        component: 'group',
        fields: [
          {
            name: 'src',
            label: 'Image',
            component: 'image',
            description: 'Image description',
            clearable: true,
            parse: (media) => {
              if (!media) {
                return
              }
              // console.log('image field config - parse:', media)
              return `${process.env.NEXT_PUBLIC_IMGIX_READ_URL}${media.directory}/${media.filename}`
            },
            previewSrc: (src) => {
              console.log('previewSrc', src)
              return src
            },
          },
          {
            name: 'alt',
            label: 'Image Alt',
            component: 'text',
          },
          {
            name: 'caption',
            label: 'Caption',
            component: 'html',
          },
        ],
      },
    ],
  },
  text: {
    ...baseItem,
    label: 'Text Item',
    defaultItem: {
      ...baseItem.defaultItem,
      title: 'Text Item Title',
      description:
        '<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci expedita doloremque temporibus ipsum illo vero aut voluptas laborum ex dignissimos numquam at odio, aspernatur voluptatum, provident sit nesciunt! Dicta, natus.</p>',
    },
    fields: [
      ...baseItem.fields,
      {
        name: 'description',
        label: 'Description',
        component: 'html',
      },
    ],
  },
}

// Tina form config for MenuDemo
// export const blockConfig = {
//   Component(props) {
//     const { index, data } = props

//     return (
//       <BlocksControls
//         index={index}
//         focusRing={{ offset: -4, borderRadius: 4 }}
//         insetControls
//       >
//         <MenuDemo {...data} />
//       </BlocksControls>
//     )
//   },
//   template: {
//     label: 'Menu (Demo)',
//     defaultItem: {
//       title: 'Menu Section',
//       subtitle: '',
//       intro: '',
//       menu_items: [
//         {
//           // id: 1,
//           _template: 'text',
//           title: 'Lorem Ipsum',
//           subtitle: '',
//           description:
//             '<p><strong>Lorem ipsum dolor sit amet consectetur adipisicing elit.</strong> Adipisci expedita doloremque temporibus ipsum illo vero aut voluptas laborum ex dignissimos numquam at odio, aspernatur voluptatum, provident sit nesciunt! Dicta, natus.</p>',
//         },
//       ],
//       outro: '',
//       button: {
//         href: '',
//         label: '',
//       },
//     },
//     fields: [
//       {
//         name: 'title',
//         label: 'Title',
//         component: 'text',
//       },
//       {
//         name: 'subtitle',
//         label: 'Subtitle',
//         component: 'text',
//       },
//       {
//         name: 'menu_items',
//         label: 'Menu Items',
//         component: 'blocks',
//         templates: itemTemplates,
//       },
//     ],
//   },
// }

// InlineBlocks `blocks`
export const MENU_ITEM_BLOCKS = {
  text: {
    Component({_name, index, data}) {
      return (
        <ItemWrapper index={index}>
          <ItemHeader title={data.title} subtitle={data.subtitle} />
          <RichtextWithVariables
            name="description"
            richtext={data.description}
            cms={data.cms}
          />
          {/* <pre style={{ whiteSpace: 'pre-wrap' }}>
            {JSON.stringify(data, null, 2)}
          </pre> */}
        </ItemWrapper>
      )
    },
    template: itemTemplates.text,
  },
  icon: {
    Component({_name, index, data}) {
      return (
        <ItemWrapper index={index}>
          <ItemHeader title={data.title} subtitle={data.subtitle} />
          <div style={{fontSize: '8rem', textAlign: 'center'}}>
            {data.icon}
          </div>
          {/* <pre style={{ whiteSpace: 'pre-wrap' }}>
            {JSON.stringify(data, null, 2)}
          </pre> */}
        </ItemWrapper>
      )
    },
    template: itemTemplates.icon,
  },
  // block: {
  //   Component({ _name, index, data }) {
  //     return (
  //       <ItemWrapper index={index}>
  //         <ItemHeader title={data.title} subtitle={data.subtitle} />
  //         <pre style={{ whiteSpace: 'pre-wrap' }}>
  //           {JSON.stringify(data, null, 2)}
  //         </pre>
  //       </ItemWrapper>
  //     )
  //   },
  //   template: itemTemplates.block,
  // },
  photo: {
    Component({_name, index, data}) {
      return (
        <ItemWrapper index={index}>
          <ItemHeader title={data.title} subtitle={data.subtitle} />
          <SectionImage
            name="image.src"
            src={data.image.src}
            alt={data.image.alt}
            // width={image.width}
            // height={image.height}
            cms={data.cms}
          />
          {/* <pre style={{ whiteSpace: 'pre-wrap' }}>
            {JSON.stringify(data, null, 2)}
          </pre> */}
        </ItemWrapper>
      )
    },
    template: itemTemplates.photo,
  },
}

function ItemWrapper(props) {
  return (
    <div
      className="section-item-wrapper"
      style={{
        flex: '1 0 calc(33% - 1rem)',
        // border: '1px dashed #000',
        padding: '1rem',
      }}
    >
      <BlocksControls
        index={props.index}
        focusRing={{offset: 16, borderRadius: 4}}
        insetControls={false}
      >
        {props.children}
      </BlocksControls>
    </div>
  )
}

function ItemHeader(props) {
  if (!props.title && !props.subtitle) {
    return null
  }
  return (
    <h4>
      {props.title ? (
        <span className="title">
          <SectionText name="title" text={props.title} cms={props.cms} />
        </span>
      ) : null}
      {props.subtitle ? (
        <span className="subtitle">
          <SectionText name="subtitle" text={props.subtitle} cms={props.cms} />
        </span>
      ) : null}
    </h4>
  )
}
