import Link from 'next/link'
import {InlineGroup} from '@einsteinindustries/react-tinacms-inline'
import styled from 'styled-components'
import {BUTTON_FIELDS} from '@/components/sections/shared/InputTypesFields'
import {InlineTextWithVariables} from '@/components/sections/shared/SectionsWithVariables'

// const Button = styled.a`
//   color: ${(props) => (props.color ? props.color : '#ffffff')};
//   font-size: ${(props) => (props.fontSize ? props.fontSize : '1.25rem')};
//   background-color: ${(props) =>
//     props.backgroundColor ? props.backgroundColor : '#555555'};
//   border-radius: ${(props) =>
//     props.borderRadius ? props.borderRadius : '.25rem'};
//   margin: ${(props) => (props.margin ? props.margin : '1rem auto')};
//   padding: ${(props) => (props.padding ? props.padding : '1rem 1.5rem')};
//   display: inline-block;
// `

// function SectionButton(props) {
//   const { href, label, style } = props
//   return href.length ? (
//     <Link href={href} passHref>
//       <Button
//         color={style?.color}
//         fontSize={style?.fontSize}
//         backgroundColor={style?.backgroundColor}
//         borderRadius={style?.borderRadius}
//         margin={style?.margin}
//         padding={style?.padding}
//       >
//         {label}
//       </Button>
//     </Link>
//   ) : null
// }

export interface SectionButtonProps {
  label: string
  href: string
  cms: boolean
  name: string
}

const StyledDiv = styled.div`
  display: grid;
`

const StyledSpan = styled.span`
  display: inline-block;
  min-width: 320px;
  margin-top: 2rem;
  padding: 1rem;
  text-align: center;
  justify-self: center;

  @media (max-width: 1024px) {
    min-width: 30vw;
  }

  @media (max-width: 640px) {
    min-width: unset;
    width: 100%;
  }
`

function SectionButton({
  label,
  href,
  cms,
  name
}: SectionButtonProps) {
  return cms ? (
    <InlineGroup
      name={name}
      insetControls={true}
      fields={BUTTON_FIELDS}
      // focusRing={{borderRadius: 4}}
    >
      <Button cms={cms} label={label} href={href}/>
    </InlineGroup>
  ) : (
    <Button cms={cms} label={label} href={href}/>
  )
}

function Button({href, label, cms}: {href: string, label: string, cms:boolean}) {
  const hasData = href && label
  const UseLink = !cms ? Link : ({children} : {children: any}) => {return children}
  if (hasData) {
    return (
      <a href={href} className="section-button button">
        <UseLink href={href} passHref>
              <span className="button-text button">
                <InlineTextWithVariables value={label} cms={cms} name={'label'}/>
              </span>
        </UseLink>
      </a>
    )
  }
  return null
}

export default SectionButton
