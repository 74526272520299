import type {Site} from '@/components/shared/types'
import {ProxyConfigStatus} from '@/components/shared/types'
import {buttonModifier, DefaultRoundButton} from '@/src/styles/home'
import styled from 'styled-components'
import {lucidDataFetcherV2} from '@/graphql/fetchers'
import {SITE_BUILD_NEEDS_SYNC, UPSERT_PROXY_CONFIG} from '@/graphql/queries'
import useSWR, {SWRResponse} from 'swr'
import {useState} from 'react'
import {useRouter} from 'next/router'


interface ProxyConfigurationButtonProps {
  site: Site
}

const ProxySync = styled.div`
  ${buttonModifier},
  .proxy-sync {
    width: 200px;
    height: 34px;
    margin: 0 0 4px;
  }
`

const ProxyConfigButton = ({
  site
}: ProxyConfigurationButtonProps) => {
  const router = useRouter()
  const {asPath} = router
  const [, , siteBuildVersion, siteId, pageBuildId, pageBuildVersion] = asPath.split('/')
  const proxyConfig = site.proxy_configs.find(pc =>
      pc.site_build_version === siteBuildVersion && pc.page_build_version === pageBuildVersion
  )
  let fetchKey: [string, Record<string, any>] | null = null
  if (proxyConfig) {
    fetchKey = [SITE_BUILD_NEEDS_SYNC, {
      site_build_version: siteBuildVersion,
      page_build_version: pageBuildVersion,
      site_id: site.id
    }]
  }
  const {data: {data: {siteBuildNeedsProxySync}}, error, mutate, isValidating}: SWRResponse = useSWR(
      fetchKey,
      lucidDataFetcherV2,
      {
        fallbackData: {
          data: {
            siteBuildNeedsProxySync: false
          }
        },
        revalidateOnFocus: true
      }
  )

  const [proxyState, setProxyState] = useState({isMutating: false, configCreated: false})

  function serverSideRefresh() {
    router.replace(router.asPath)
  }

  async function syncWithProxy() {
    if (proxyState.isMutating || isValidating || (proxyConfig && !siteBuildNeedsProxySync)) return

    setProxyState((prev) => {
      return {...prev, isMutating: true}
    })
    await lucidDataFetcherV2(UPSERT_PROXY_CONFIG, {
      site_id: site.id,
      site_build_version: siteBuildVersion,
      page_build_version: pageBuildVersion
    })
    setProxyState({configCreated: true, isMutating: false})
    mutate()
    // We need to fetch all serverside props to get updated pageBuild info
    serverSideRefresh()
  }

  let className = 'proxy-sync'
  let label = 'Create Proxy Config'
  if (proxyConfig || proxyState.configCreated) {
    label = 'Sync Proxy Config'
  }
  if (isValidating || proxyState.isMutating || (proxyConfig && !siteBuildNeedsProxySync)) {
    className += ' button-disabled'
  }

  if (proxyConfig?.config_status === ProxyConfigStatus[ProxyConfigStatus.DEPLOYING]) {
    className += ' button-loading'
  }

  return (
    <ProxySync>
      <DefaultRoundButton onClick={async () => await syncWithProxy()} className={className}>{label}</DefaultRoundButton>
    </ProxySync>
  )
}

export default ProxyConfigButton
