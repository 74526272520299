import StyledHeading from './shared/StyledHeading'
import {InlineTextWithVariables, RichtextWithVariables} from './shared/SectionsWithVariables'
import SectionText from './shared/SectionText'
import SectionImage from './shared/SectionImage'
import Section from './shared/Section'
import styled from 'styled-components'
import SectionButton from './shared/SectionButton'

const Row = styled.div`
  max-width: 1440px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-flow: row wrap;

  .tagline {
    width: calc(100% - 49vw);
    min-width: 50%;
    color: #fff;
    display: flex;
    text-align: center;
    justify-content: center;
    height: auto;
    padding: 3rem;
    font-size: 3rem;
    position: absolute;
    bottom: 2.5rem;
    margin: 0 auto;
    left: 25%;
    z-index: 4;

    ::before {
      background-color: #555;
      opacity: 0.8;
      top: 0;
      position: absolute;
      content: '';
      display: block;
      width: 100%;
      height: 100%;
    }
  }
  .image {
    z-index: 2;
  }

  .imageLeft {
    width: 33%;
  }

  .imageRight {
    width: 42%;
  }
`

const Column = styled.div`
  max-width: 1440px;
  width: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  ::before {
    background-color: #000;
    content: '';
    display: block;
    width: 65%;
    height: 80%;
    position: absolute;
    z-index: 1;
    top: 2.5rem;
  }

  ::after {
    background-color: #000;
    content: '';
    display: block;
    width: 10rem;
    height: 10rem;
    position: absolute;
    z-index: 3;
    right: 0;
    bottom: 0;
  }
`

export default function TitleAndImageCollage(props) {
  const {
    title,
    subtitle,
    image_1,
    image_2,
    intro,
    tagline,
    outro,
    button,
    color_scheme,
    className,
    css_overrides,
    color_scheme_id_override, lucid_page_section_id, meta,
    cms,
  } = props
  return (
    <Section
      cms={cms}
      css_overrides={css_overrides}
      color_scheme_id_override={color_scheme_id_override} meta={meta}
      lucid_page_section_id={lucid_page_section_id}
      className={className}
      name="titleandimagecollage"
      colorSchemeId={color_scheme}>
      <StyledHeading>
        <h2>
          <InlineTextWithVariables cms={cms} name="title" value={title}/>
        </h2>
        <InlineTextWithVariables cms={cms} name="subtitle" value={subtitle}/>
      </StyledHeading>
      <RichtextWithVariables cms={cms} name="intro" richtext={intro}/>
      <Row>
        <Column>
          <div className="tagline">
            <SectionText name="tagline" text={tagline} cms={cms}/>
          </div>
          <div className="image imageLeft">
            <SectionImage
              name='image_1'
              src={image_1?.src}
              alt={image_1?.alt}
              width={image_1?.width}
              height={image_1?.height}
              cms={cms}
            />
          </div>
          <div className="image imageRight">
            <SectionImage
              name='image_2'
              src={image_2?.src}
              alt={image_2?.alt}
              width={image_2?.width}
              height={image_2?.height}
              cms={cms}
            />
          </div>
        </Column>
      </Row>
      <RichtextWithVariables cms={cms} name="outro" richtext={outro}/>
      <SectionButton
        name='button'
        href={button?.url}
        label={button?.label}
        cms={cms}
      />
    </Section>
  )
}

// export const blockConfig = {
//   Component(props) {
//     const { index, data } = props

//     return (
//       <BlocksControls
//         index={index}
//         // focusRing={{ offset: -4, borderRadius: 4 }}
//         insetControls
//       >
//         {/* eslint-disable-next-line */}
//         <TitleAndImageCollage {...data} />
//       </BlocksControls>
//     )
//   },
//   template: {
//     label: 'Title And Image Collage',
//     defaultItem: {
//       title: 'Title And Image Collage Section',
//       subtitle: '',
//       left_image: {
//         src: 'https://tools--dev.cms.eiidev.com/placeholder/480/480',
//         alt: 'placeholder',
//       },
//       tagline: 'Tagline',
//       right_image: {
//         src: 'https://tools--dev.cms.eiidev.com/placeholder/600/400',
//         alt: 'placeholder',
//       },
//       button: {
//         label: '',
//         url: '',
//       },
//       colorSchemeId: '1',
//     },
//     fields: [
//       {
//         name: 'title',
//         label: 'Title',
//         component: 'text',
//       },
//       {
//         name: 'subtitle',
//         label: 'Subtitle',
//         component: 'text',
//       },
//       {
//         name: 'intro',
//         label: 'Intro',
//         component: 'html',
//       },
//       {
//         name: 'left_image',
//         label: 'Image Left',
//         component: 'group',
//         fields: [
//           {
//             name: 'src',
//             label: 'Image Left',
//             component: 'image',
//             description: 'Image Left description',
//             clearable: true,
//             parse: (media) => {
//               if (!media) {
//                 return
//               }
//               // console.log('image field config - parse:', media)
//               return `https://einstein-clients.imgix.net${media.directory}/${media.filename}`
//             },
//             previewSrc: (src) => {
//               console.log('previewSrc', src)
//               return src
//             },
//           },
//           {
//             name: 'alt',
//             label: 'Image Left Alt',
//             component: 'text',
//           },
//         ],
//       },
//       {
//         name: 'tagline',
//         label: 'Tagline',
//         component: 'text',
//       },
//       {
//         name: 'right_image',
//         label: 'Image Right',
//         component: 'group',
//         fields: [
//           {
//             name: 'src',
//             label: 'Image Right',
//             component: 'image',
//             description: 'Image Right description',
//             clearable: true,
//             parse: (media) => {
//               if (!media) {
//                 return
//               }
//               // console.log('image field config - parse:', media)
//               return `https://einstein-clients.imgix.net${media.directory}/${media.filename}`
//             },
//             previewSrc: (src) => {
//               console.log('previewSrc', src)
//               return src
//             },
//           },
//           {
//             name: 'alt',
//             label: 'Image Right Alt',
//             component: 'text',
//           },
//         ],
//       },
//       {
//         name: 'outro',
//         label: 'Outro',
//         component: 'html',
//       },
//       {
//         name: 'button',
//         label: 'Button',
//         component: 'group',
//         fields: buttonFields,
//       },
//       {
//         name: 'colorSchemeId',
//         label: 'Color Scheme',
//         component: 'select',
//         options: [
//           {
//             value: '1',
//             label: 'Default',
//           },
//         ],
//       },
//     ],
//     itemProps: (item) => {
//       const tempDiv = document.createElement('div')
//       tempDiv.innerHTML = item.title
//       return {
//         label: tempDiv.textContent,
//       }
//     },
//   },
// }

// export const templateName = 'titleandimagecollage'
