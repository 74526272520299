// import dynamic from 'next/dynamic'
import StyledHeading from './shared/StyledHeading'
import {InlineTextWithVariables, RichtextWithVariables} from './shared/SectionsWithVariables'
import SectionImage from './shared/SectionImage'
import Section from './shared/Section'
import SectionButton from './shared/SectionButton'

export default function Image(props) {
  const {
    title,
    subtitle,
    intro,
    image,
    caption,
    outro,
    button,
    color_scheme,
    className,
    css_overrides,
    color_scheme_id_override,
    lucid_page_section_id, meta,
    cms,
  } = props
  return (
    <Section
      name="image"
      cms={cms}
      css_overrides={css_overrides} color_scheme_id_override={color_scheme_id_override} meta={meta} lucid_page_section_id={lucid_page_section_id}
      className={className}
      colorSchemeId={color_scheme}
    >
      <StyledHeading>
        <h2>
          <InlineTextWithVariables cms={cms} name="title" value={title} />
        </h2>
        <InlineTextWithVariables cms={cms} name="subtitle" value={subtitle} />
      </StyledHeading>
      <RichtextWithVariables cms={cms} name="intro" richtext={intro} />
      <SectionImage
        style={{
          display: 'block',
          marginInline: 'auto',
          marginBottom: '4rem',
          maxWidth: 'clamp(80%, 50vw, 100%)',
        }}
        name='image'
        src={image?.src}
        alt={image?.alt}
        width={image?.width}
        height={image?.height}
        cms={cms}
      />
      <RichtextWithVariables cms={cms} name="caption" richtext={caption} />
      <RichtextWithVariables cms={cms} name="outro" richtext={outro} />
      <SectionButton
        name='button'
        href={button?.url}
        label={button?.label}
        cms={cms}
      />
    </Section>
  )
}

// export const blockConfig = {
//   Component(props) {
//     const { index, data } = props

//     return (
//       <BlocksControls
//         index={index}
//         // focusRing={{ offset: -4, borderRadius: 4 }}
//         insetControls
//       >
//         {/* eslint-disable-next-line */}
//         <Image {...data} />
//       </BlocksControls>
//     )
//   },
//   template: {
//     label: 'Image',
//     defaultItem: {
//       title: 'Image Section',
//       subtitle: '',
//       image: {
//         src: '',
//         alt: '',
//       },
//       button: {
//         label: '',
//         url: '',
//       },
//       colorSchemeId: '1',
//     },
//     fields: [
//       {
//         name: 'title',
//         label: 'Title',
//         component: 'text',
//       },
//       {
//         name: 'subtitle',
//         label: 'Subtitle',
//         component: 'text',
//       },
//       {
//         name: 'intro',
//         label: 'Intro',
//         component: 'html',
//       },
//       {
//         name: 'image',
//         label: 'Image',
//         component: 'group',
//         fields: [
//           {
//             name: 'src',
//             label: 'Image',
//             component: 'image',
//             description: 'Image description',
//             clearable: true,
//             parse: (media) => {
//               if (!media) {
//                 return
//               }
//               // console.log('image field config - parse:', media)
//               return `https://einstein-clients.imgix.net${media.directory}/${media.filename}`
//             },
//             previewSrc: (src) => {
//               console.log('previewSrc', src)
//               return src
//             },
//           },
//           {
//             name: 'alt',
//             label: 'Image Alt',
//             component: 'text',
//           },
//           {
//             name: 'caption',
//             label: 'Caption',
//             component: 'html',
//           },
//         ],
//       },
//       {
//         name: 'outro',
//         label: 'Outro',
//         component: 'html',
//       },
//       {
//         name: 'button',
//         label: 'Button',
//         component: 'group',
//         fields: buttonFields,
//       },
//       {
//         name: 'colorSchemeId',
//         label: 'Color Scheme',
//         component: 'select',
//         options: [
//           {
//             value: '1',
//             label: 'Default',
//           },
//         ],
//       },
//     ],
//     itemProps: (item) => {
//       const tempDiv = document.createElement('div')
//       tempDiv.innerHTML = item.title
//       return {
//         label: tempDiv.textContent,
//       }
//     },
//   },
// }

// export const templateName = 'image'
