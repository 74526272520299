import {Media} from '@einsteinindustries/tinacms'

export const BUTTON_FIELDS = [
  {
    label: 'Label',
    name: 'label',
    component: 'text',
  },
  {
    label: 'Link URL',
    name: 'url',
    component: 'text',
  },
]

export const IMAGE_FIELDS = [
  {
    name: 'src',
    label: 'Image',
    component: 'image',
    description: 'Pick Image from the Assets Manager',
    clearable: true,
    parse: (media: Media) => {
      if (!media) {
        return
      }
      return `${process.env.NEXT_PUBLIC_IMGIX_READ_URL}${media.directory}/${media.filename}`
    },
    previewSrc: (src: string) => {
      return src
    },
  },
  {
    name: 'src',
    label: 'External Image',
    component: 'text',
    description: 'Or enter an external Image URL',
  },
  {
    label: 'Alt Text',
    name: 'alt',
    component: 'text',
  },
  {
    label: 'Width',
    name: 'width',
    component: 'text',
  },
  {
    label: 'Height',
    name: 'height',
    component: 'text',
  },
  {
    label: 'Link',
    name: 'link',
    component: 'text',
    description: 'Optional Link'
  },
]

export const IMAGE_GROUP = [{
  name: 'items',
  label: 'Add Images',
  component: 'blocks',
  templates: {
    image_item: {
      label: 'Image Item',
      fields: IMAGE_FIELDS
    }
  },
}]

export const VIDEO_FIELDS = [
  {
    label: 'Video Source',
    name: 'src',
    component: 'text',
  },
  {
    label: 'Poster Image Source',
    name: 'poster',
    component: 'text',
  },
  {
    label: 'Caption',
    name: 'caption',
    component: 'text',
  },
  {
    label: 'Size (1080, 720, 480, etc)',
    name: 'size',
    component: 'number',
  },
]


export const multiInput = (label: string, type: 'video' | 'image') => {
  return {
    label,
    component: 'group',
    fields: type === 'video' ? VIDEO_FIELDS : IMAGE_FIELDS
  }
}
