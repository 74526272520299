export const enumStagesOrder = {
  PROPOSED: 1,
  IN_PROGRESS: 2,
  READY_FOR_REVIEW: 3,
  APPROVED: 4,
}

export const stagesColor = {
  PROPOSED: '#bdc3c7',
  IN_PROGRESS: '#f1c40f',
  READY_FOR_REVIEW: '#3498db',
  APPROVED: '#2ecc71',
  PUBLISHED: '#16a085',
  ARCHIVED: '#2c3e50',
}

export const sortFields = ['description', 'status', 'updated_at']

// Defines the tabs on top of Page manager
export const PROJECTS = ['Live', 'Dev', 'All']
