import type {MediaList} from '@einsteinindustries/tinacms-core'
import type {ParsedUrlQuery} from 'querystring'

export type PickByType<T, Value> = {
  [P in keyof T as T[P] extends Value | undefined ? P : never]: T[P]
}

/**
 * Types
 */
export type Status =
  | 'PROPOSED'
  | 'IN_PROGRESS'
  | 'READY_FOR_REVIEW'
  | 'APPROVED'

export enum SectionStatusType {
  ACTIVE,
  HIDDEN,
  DISABLED
}
interface Base {
  id: string
  created_at: string
  updated_at: string
}

export interface PageBuild extends Base {
  locked: boolean
  body_sections: any[]
  description: string
  page: Page
  page_id: string
  status: Status
  assigned_to_user?: User
  assigned_to_user_id?: string
  source_page_build?: PageBuild
  source_page_build_id?: string
}

export interface Page extends Base {
  canonical_url: any
  contact_formname: any
  ppc_phone_number: any
  consultation_phone_number: any
  bar_color_scheme: any
  twitter_username: any
  image: any
  description: any
  archived: boolean
  name: string
  site: {
    name: string
    id: string
  }
  site_id: string
  page_builds: PageBuild[]
  menu_items: any[]
  route: Route
  attributes?: {
    page_tags: string[]
    exclude_from_sitemap: boolean
  }
  live_page_build_id?: string
  live_page_build?: PageBuild
  dev_page_build_id?: string
  dev_page_build?: PageBuild
}

export interface Role {
  id: string
  description: string
  name: string
}

interface SiteUser {
  site: Site
  site_id: string
  user: User
  user_id: string
}

export interface User extends Base {
  assigned_page_builds: PageBuild[]
  attributes: { preferred_name: string }
  site_users: SiteUser[]
  email: string
  name: string
  role?: Role
  role_id?: string
}

export enum RouteType {
  PAGE,
  ROUTE,
  EXTERNAL
}

export interface Route {
  id: string
  route_type: keyof typeof RouteType
  url: string
  created_at: string
  updated_at: string
  site_build_id: number
  page_id: number
  target_route_id: number
  page: Page
  site_build: SiteBuild
  target_route: Route
  route_aliases: Route[]
}

export enum ProxyConfigVersion {
  dev,
  live
}

export type BuildVersion = keyof typeof ProxyConfigVersion

export enum ProxyConfigStatus {
  PENDING,
  DEPLOYING,
  DEPLOYED
}

export interface ProxyConfig {
  id: string
  proxy_config_id: string
  last_pipeline_execution_id?: string
  site_id: string
  created_at: string
  updated_at: string
  config_status: typeof ProxyConfigStatus[number]
  site_build_version: typeof ProxyConfigVersion[number]
  page_build_version: typeof ProxyConfigVersion[number]
}

export interface SiteBuild {
  id: string
  name: string
  description?: string
  stage: string
  created_at: string
  updated_at: string
  published_date: Date
  site_id: string
  site?: Site
  menus?: Menu[]
  page_sections?: PageSection[]
  header_sections?: PageSection[]
  footer_sections?: PageSection[]
}

export interface Site {
  pages: Page[]
  active: boolean
  client_account: number
  client_site: boolean
  created_at: string
  updated_at: string
  live_site_build_id: string
  dev_site_build_id: string
  live_site_build?: SiteBuild
  dev_site_build?: SiteBuild
  domain: string
  proxy_configs: ProxyConfig[]
  id: string
  launch_date: Date
  name: string
  contents: Contents[]
}

export const SectionFieldInput = [
  'TEXT',
  'RANGE',
  'IMAGE',
  'RICHTEXT',
  'DATE',
  'TEXTAREA',
  'NUMBER',
  'COLOR',
  'TOGGLE',
  'SELECT',
] as const

type SectionFieldInputType = typeof SectionFieldInput[number]

export interface Contents {
  created_at: string
  id: string
  name: string
  page_section: PageSection
  page_section_id: string
  scope: 'PAGE' | 'SITE'
  site_id: string
  updated_at: string
  value: string
  value_history: { value: string }[]
}

export interface SectionField {
  id: string
  created_at: string
  input_type: SectionFieldInputType
  name: string
  section: Section
  section_id: string
  sort_order: number
  updated_at: string
  default_value?: string
}

export type Section = {
  id: string
  archived: boolean
  attributes: { render_on_page: boolean }
  created_at: string
  name: keyof typeof import('@/components/sections').default | '' | 'New Section'
  status: 'ACTIVE' | 'HIDDEN' | 'DISABLED'
  page_sections: any[]
  preview_photo?: string | null
  section_fields: SectionField[]
  updated_at: string
  parent_id?: string,
  items?: SectionItem[],
  description?: string,
}

export type SectionItemField = SectionField;

export type SectionItem = {
  id?: string,
  attributes: { render_on_page: boolean }
  created_at: string
  name?: string,
  description?: string,
  section_fields: SectionItemField[],
  updated_at?: Date | string,
  parent_id?: string,
}

export type FilterState = { filterStatus: string; filterAssignee: string }

export interface MenuItem {
  id: string
  label: string
  menu_id: string | null
  site_build_id?: string
  parent_menu_item_id?: string | null
  route?: Route
  route_id?: string
  sort_order: number
  updated_at: string
  created_at?: string
  group_menu_items: MenuItem[]
}

export interface Menu {
  id: string
  archived: boolean
  name: string
  site_build_id: string
  source_menu_id: string
  menu_items: MenuItem[]
  created_at: string
  updated_at: string
}

export enum PageSectionType {
  HEADER,
  BODY,
  FOOTER
}

export interface PageSection {
  id: string | number
  page_section_type: PageSectionType
  sort_order: number
  color_scheme_id: string | null
  section: Section
  contents: Contents[]
  key?: string
}

export interface PageDefinition {
  domain: string
  site_id: string
  page_id: string
  slug: string
  proxy_config_id: string
  site_build_version: BuildVersion
  page_build_version: BuildVersion
}

export interface PublishedPagePath extends ParsedUrlQuery {
  proxy_config_id: string
  slug: string
}

export type MediaListResponse = MediaList & {
  limit: number
  offset: number
  totalCount: number
}

export type ActiveSiteBuild = {
  site_id: string
  version: 'live' | 'dev'
  build_id?: number 
}

export type BlockContents = {
  [label: string]: string | BlockContents | number | BlockContents[] | null | boolean
}

export type PageTemplate = {
  name: string
  sections: {
    name: string
    sort_order: number
  }[]
}

export type SiteMeta = {
  styles?: {
    fonts: string,
    css: string,
  }
}
