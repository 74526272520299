import React, {useEffect, useMemo, useState} from 'react'
import styled from 'styled-components'
import {InlineSettings} from '@einsteinindustries/react-tinacms-inline'
import {ColorSwatch} from 'iconsax-react'
import {Badge} from '@nextui-org/react'
import {computeColorSchemeValues} from '@/components/managers'
import {
  Helpers as StyleCoreHelpers,
  StyleCoreOverload,
  StyleCoreTarget,
  useStyleCoreDispatcher,
  useStyleCoreMap
} from '@/components/shared/StyleCore'
import Helpers from '@/src/utils/shared/helpers'
import {FieldConfig} from '@/components/shared/externalTypes'
import {useLucidContext} from '@/src/state/ServerSideStore'
import {
  CMSHiding,
  CssOverridesContainer,
  StyleCoreCSSComponent,
  StyleCoreFormInnerSettings,
  StylesModalContent,
  SwitchColorScheme
} from '@/components/sections/shared/CMSSection'

export type SectionMeta = {
  style?: {
    css: string,
    selectors: string,
  }
}

interface  SectionProps {
  colorSchemeId?: string // DELETE
  color_scheme_id_override: string | null
  sectionStyle?: SectionStyle
  children: React.ReactNode
  className?: string
  name: string
  style?: React.CSSProperties
  cms: boolean
  css_overrides?: string
  // TODO: all sections must eventually pass both of these params. For now, we're keeping them optional (section_id, lucid_page_section_id)
  section_id?: string
  // TODO: We might want to consider converting this to page_section_id,
  //  once we have a better idea of how we're going to handle the page_section_id vs section_id
  lucid_page_section_id?: string,
  meta: SectionMeta
}

// Section color scheme object
export interface SectionColorScheme {
  bgColor?: string
  bgImage?: string
  bgOpacity?: number
  bgPositionX: number
  bgPositionY: number
  bgRepeat: boolean
  title: string
  subtitle: string
}

// Section style object
export interface SectionStyle {
  bgColor?: string
  bgImage?: string
  bgOpacity?: number
  bgPositionX?: number
  bgPositionY?: number
  bgRepeat?: boolean
  stackOrder?: string
  border?: number
}

interface StyledSectionProps {
  bgColor?: string
  bgImage?: string
  bgOpacity?: number
  bgPositionX?: number
  bgPositionY?: number
  bgRepeat?: boolean
  children: React.ReactNode
  className?: string
  stackOrder?: string
  border?: number
  title: string
  subtitle: string
  buttonBackground: string
  buttonHover: string
  buttonText: string
  text: string
}

const StyledSection = styled.div<StyledSectionProps>`
  /* "underlay" layer */
  /* display: flex; */
  /* justify-content: center; */
  /* background color layer */
  /* padding-bottom: 4rem; */
  // TODO: demo only
  color: ${(p) => p.text};
  background-color: ${(p) => p.bgColor};

  .title {
    color: ${(p) => p.title};
  }

  h2 .subtitle {
    color: ${(p) => p.subtitle};
  }

  .section-content {
    position: relative;
      /* max-width: ${(p) => (p.stackOrder === 'front' ? '67%' : null)}; */
    width: calc(100% - 2rem);
    max-width: 1000px;
    margin: 0 auto;
    padding: 2rem;
      /* background-color: ${(p) => p.bgColor}; */
    z-index: 1;
    border: ${(p) => {
      switch (Number(p.border)) {
        case 1:
          return '2px solid red'
        case 2:
          return '2px dashed green'
        case 0:
        default:
          return null
      }
    }};
    /* background image layer */

    &:before {
      display: block;
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      background-image: ${(p) => (p.bgImage ? `url(${p.bgImage})` : null)};
      background-position: ${(p) => p.bgPositionX}% ${(p) => p.bgPositionY}%;
      background-repeat: ${(p) => (p.bgRepeat ? 'repeat' : 'no-repeat')};
      background-size: cover;
      opacity: ${(p) => Number(p.bgOpacity) / 100};
    }
  }

  .section-button {
    color: ${(p) => p.buttonText};
    background-color: ${(p) => p.buttonBackground};
    text-decoration: none;

    &:hover {
      background-color: ${(p) => p.buttonHover};
    }
  }
`
/**
 *
 * TODO: We have to update this to allow for full rendering without useState
 *  For SSG, we need to be able to render pages without any front end loading
 *  We need to come back here and clean every async call/load after page render
 *
 */
export default function Section({...props}: SectionProps) {
  const {
    colorSchemeId,
    children,
    className = '',
    name,
    lucid_page_section_id,
    css_overrides,
    color_scheme_id_override,
    cms,
    meta
  } = props


  const [lucidSiteStore] = useLucidContext(cms)
  const styleCoreTarget: StyleCoreTarget = {
    componentName: name,
    identifier: lucid_page_section_id
  }

  const sectionClassName = [`${name}-section`, className, StyleCoreHelpers.selectors.getSelectorID(styleCoreTarget)].join(' ').trim()

  const styleCoreMap = useStyleCoreMap(cms)
  const styleCoreDispatcher = useStyleCoreDispatcher(cms)

  const [currentScheme, setCurrentScheme] = useState<{[key: string]: any}>()

  useEffect(() => {
    changeColorScheme(color_scheme_id_override)
  }, [lucidSiteStore.schemes])

  useEffect(() => {
    if (typeof lucid_page_section_id !== 'undefined' && cms && !styleCoreMap.get(styleCoreTarget) && styleCoreMap.getRoot(styleCoreTarget)) {
      styleCoreDispatcher({
        target: styleCoreTarget,
        overloads: [{}]
      })
    }
  }, [])

  useMemo(() => {
    async function setInitialValues() {
      if (styleCoreMap.get(styleCoreTarget)) {
        const initialValues = await Helpers.tina.forms.only.getInitialValues({
          content: (styleCoreMap.get(styleCoreTarget)?.config ?? {}) as FieldConfig,
          title: 'Style Core',
          id: StyleCoreHelpers.formManager.convertTargetToFormID(styleCoreTarget),
          listeners: {
            target: {
              page_section_id: Number(lucid_page_section_id)
            },
            preventContentTableUpsert: false
          }
        })
        styleCoreDispatcher({
          target: styleCoreTarget,
          overloads: [initialValues as StyleCoreOverload]
        })
      }
    }
    if (typeof lucid_page_section_id !== 'undefined' && styleCoreMap.get(styleCoreTarget) && cms) {
      setInitialValues()
    }
  }, [styleCoreTarget.identifier, styleCoreTarget.componentName])

  // TODO: Create a function that determines which values (sectionColorScheme or sectionStyle) to use

  const cn = `${name}-${Math.random().toString(36).slice(2)}`
  const [display, setDisplay] = useState<'visible' | 'hidden' | 'locked'>('hidden')

  // Simple preprocessing to prefix all rules with our randomly generated class
  const prefixCss = css_overrides?.split('}').filter(rule => rule !== '').map(rule => `.${cn} ${rule}}`).join('')

  function onActive() {
    if (display === 'hidden' && cms) {
      setDisplay(typeof css_overrides !== 'undefined' ? 'visible' : 'locked')
    }
  }

  function onBlur() {
    if (display) {
      setDisplay('hidden')
    }
  }

  const changeColorScheme = (id: string | null) => {
    let scheme = null
    /**
     * We will sort schemes so that we always get the same default/first scheme
     */
    const schemes = lucidSiteStore.schemes.sort((a, b) => Number(b.id) - Number(a.id))
    if (!id) {
      scheme = schemes.find((scheme) => Boolean(scheme.id)) ?? schemes[0]
    } else {
      scheme = schemes.find((scheme) => scheme.id === id) ?? schemes[0]
    }
    if (scheme) {
      setCurrentScheme(computeColorSchemeValues([scheme]).pop())
    }
  }

  const StyleManager = ({cms} : {cms: boolean}) => {
    if (cms) {
      return <>
        <StyleCoreCSSComponent target={styleCoreTarget} cms={cms}/>
        <style jsx global>{`${prefixCss ?? ''}`}</style>
      </>
    } else {
      return <>
        <style jsx global>{`${prefixCss ?? ''} ${meta?.style?.css ?? ''}`}</style>
      </>
    }
  }

  /**
   * TODO: next line can't be always front end. We have to update it to be conditionally loaded
   */
  // usePlugin(siteForm)

  return (
    <>
      <StyledSection
        onMouseEnter={onActive}
        onMouseLeave={onBlur}
        bgColor={currentScheme?.background_color}
        bgImage={currentScheme?.background_image}
        bgOpacity={currentScheme?.background_image_opacity}
        bgPositionX={currentScheme?.bgPositionX}
        bgPositionY={currentScheme?.bgPositionY}
        bgRepeat={currentScheme?.background_image_repeat}
        // stackOrder={currentScheme?.stackOrder}
        title={currentScheme?.title}
        subtitle={currentScheme?.subtitle}
        text={currentScheme?.text}
        stackOrder="front"
        border={currentScheme?.border}
        buttonBackground={currentScheme?.button_background}
        buttonHover={currentScheme?.button_hover}
        buttonText={currentScheme?.button_text}
        className={sectionClassName +  ` ${cn}`}
      >
        <CMSHiding cms={cms} >
          <CssOverridesContainer  display={display} >
            <InlineSettings fields={[
              {
                label: 'Color Scheme Override',
                name: 'color_scheme_id_override',
                component: (props) => <SwitchColorScheme {...props} onChange={changeColorScheme} cms={cms} color_scheme_id_override={color_scheme_id_override} activeColorScheme={currentScheme}/>
              },
              {
                label: 'Styles',
                name: 'styles',
                component: (props) => <StyleCoreFormInnerSettings {...props} cms={cms} styleCoreElement={styleCoreMap.get(styleCoreTarget)} formManagerTarget={{
                  page_section_id: Number(lucid_page_section_id)
                }} />
              },
              {
                label: 'CSS Overrides',
                name: 'css_overrides',
                component: (props) => <StylesModalContent {...props} colorSchemeOverride={color_scheme_id_override} activeColorScheme={currentScheme} />,
              }
          ]}/>
          <span>
            <ColorSwatch size={32} color={'transparent'}/>
            <Badge style={{
              position: 'absolute',
              zIndex: 99,
              top: 0,
              right: 0,
              transform: 'translate(25%, -70%)',
              pointerEvents: 'none'
            }} size="md" enableShadow disableOutline color="primary">
              <ColorSwatch />
            </Badge>
          </span>
          </CssOverridesContainer>
        </CMSHiding>
        {children}
        <StyleManager cms={cms}/>
      </StyledSection>
    </>
  )
}

