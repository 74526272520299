import Section from '../shared/Section'
import StyledHeading from '../shared/StyledHeading'
import {InlineTextWithVariables, RichtextWithVariables} from '../shared/SectionsWithVariables'
// export { blockConfig, templateName } from './config'

export default function Textblock(props) {
  const {
    title,
    subtitle,
    richtext,
    // button,
    color_scheme,
    className,
    css_overrides,
    color_scheme_id_override, lucid_page_section_id, meta,
    cms,
  } = props
  return (
    <Section
      cms={cms}
      css_overrides={css_overrides} color_scheme_id_override={color_scheme_id_override} meta={meta} lucid_page_section_id={lucid_page_section_id}
      className={className}
      name="textblock"
      colorSchemeId={color_scheme}
    >
      <StyledHeading>
        <h2>
          <InlineTextWithVariables cms={cms} name="title" value={title} />
        </h2>
        <InlineTextWithVariables cms={cms} name="subtitle" value={subtitle} />
      </StyledHeading>
      <RichtextWithVariables
        name="richtext"
        richtext={richtext}
        cms={cms}
      />
      {/* <SectionButton
        href={button?.url}
        label={button?.label}
        fields={buttonFields}
        cms={cms}
      /> */}
    </Section>
  )
}
