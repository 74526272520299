import Head from 'next/head'
import React from 'react'
import {GetStaticProps, InferGetStaticPropsType} from 'next'
import {
  getAllLucidSections,
  getAllSiteContent,
  getLucidColorSchemes,
  getMenus,
  getPage,
  getPageDefinition,
  getPages,
  getPublishedPaths,
  getSite,
  selectActiveSiteBuild
} from '@/graphql/data-managers'
import formatInitialBlocks from '@/tina/blocks/formatInitialBlocks'
import {BlockContents, PageBuild, PublishedPagePath, Site, SiteMeta} from '@/components/shared/types'
import Sections from '@/components/sections'
import {ServerSideStore} from '@/src/state/ServerSideStore'
import extractFormManagerContents from '@/src/utils/extractFormManagerContents'

export interface SplitPublishedPaths {
  proxy_config_id: string
  slug: string[]
}

export async function getStaticPaths() {
  const publishedPages = (await getPublishedPaths()) as PublishedPagePath[]
  return {
    paths: publishedPages.map((page) => ({
      params: {
        ...page,
        slug: page.slug
          .split('/')
          .filter((e) => e)
          .concat('index'),
      },
    })),
    fallback: false,
  }
}

export const getStaticProps: GetStaticProps = async (context) => {
  const {proxy_config_id, slug} = context.params as unknown as SplitPublishedPaths
  const patchedSlug = '/' + slug.slice(0, slug.length - 1).join('/')
  const pageDefinition = await getPageDefinition(patchedSlug, proxy_config_id)

  const siteId = pageDefinition.site_id
  const pageId = pageDefinition.page_id
  const siteBuildVersion = pageDefinition.site_build_version
  const pageBuildVersion = pageDefinition.page_build_version

  const site = await getSite(siteId) as Site
  const page = await getPage(pageId)
  const pageBuild = page[`${pageBuildVersion}_page_build`] as PageBuild
  const sections = await getAllLucidSections()

  const siteBuildId = site[`${siteBuildVersion}_site_build_id`]
  const {colorSchemes} = await getLucidColorSchemes(siteBuildId)
  const contents = extractFormManagerContents(await getAllSiteContent({site_id: Number(siteId)}))
  const {menus} = await getMenus(siteBuildId)
  const pages = await getPages(siteId)
  const activeSiteBuild = selectActiveSiteBuild(site, siteBuildVersion)

  const [headerSections, bodySections, footerSections] = formatInitialBlocks(
    pageBuild,
    activeSiteBuild,
    site
  )

  return {
    props: {
      pageBuild,
      page,
      sections,
      colorSchemes,
      headerSections,
      bodySections,
      footerSections,
      site,
      siteBuild: activeSiteBuild,
      siteBuildId,
      contents,
      menus,
      pages,
    },
  }
}

type PageProps = InferGetStaticPropsType<typeof getStaticProps>
export default function StaticPage({
  pageBuild,
  page,
  sections,
  headerSections,
  bodySections,
  footerSections,
  siteBuild,
  site,
  colorSchemes,
  menus,
  siteBuildId,
  contents,
  pages
 }: PageProps) {

  ServerSideStore.menus = menus
  ServerSideStore.contents = contents
  ServerSideStore.schemes = colorSchemes

  const meta = ServerSideStore.contents?.meta as SiteMeta
  const fonts = JSON.parse(`${meta.styles?.fonts}` ?? '[]')
  const fontURL = `https://fonts.googleapis.com/css?family=${fonts.map((font: string) => font[0].replace(/ /g, '+')).join('|')}&display=swap`
  const globalStyles = meta.styles?.css

  let globalStyleComponent = null
  if (globalStyles) {
    globalStyleComponent = <style>{globalStyles}</style>
  }

  return (
    <div>
      <Head>
        <title>{page.name}</title>
        <link
          rel="shortcut icon"
          type="image/png"
          href={contents?.practice?.logos?.favicon}
        />
        <link rel="preload" href={fontURL} as="style"/>
        <link rel="stylesheet" href={fontURL}/>
        {globalStyleComponent}
      </Head>
      <div className={'header-sections'}>
        {unwrapSections(headerSections)}
      </div>
      <div className={'body-sections'}>
        {unwrapSections(bodySections)}
      </div>
      <div className={'footer-sections'}>
        {unwrapSections(footerSections)}
      </div>
    </div>
  )
}

function isKeyOfSection(key: any): key is keyof typeof Sections {
  return typeof key === 'string' && key in Sections
}

function unwrapSections(sectionData: BlockContents[]) {
  return sectionData.map(({_template, ...sectionContent}, index) => {
    if (isKeyOfSection(_template)) {
      const SectionComponent = Sections[_template]
      return <SectionComponent key={index} {...sectionContent}/>
    }
    return null
  })
}
