import Section from './shared/Section'
import StyledHeading from './shared/StyledHeading'
import {InlineTextWithVariables, RichtextWithVariables} from './shared/SectionsWithVariables'
import SectionImage from './shared/SectionImage'
import SectionButton from './shared/SectionButton'
import styled from 'styled-components'

const Row = styled.div`
  max-width: 1440px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-flow: row wrap;
`

const Column = styled.div`
  display: flex;
  flex-flow: column wrap;
  flex: 1 1 0px;
  padding: 0 1rem;
`

export default function TextblockImage(props) {
  const {
    title,
    subtitle,
    intro,
    richtext,
    button_main,
    image,
    icon,
    outro,
    button,
    color_scheme,
    className,
    css_overrides,
    color_scheme_id_override,
    lucid_page_section_id, meta,
    cms,
  } = props
  return (
    <Section
      cms={cms}
      css_overrides={css_overrides}
      color_scheme_id_override={color_scheme_id_override} meta={meta}
      lucid_page_section_id={lucid_page_section_id}
      className={className}
      name="textblockimage"
      colorSchemeId={color_scheme}>
      <StyledHeading>
        <h2>
          <InlineTextWithVariables cms={cms} name="title" value={title}/>
        </h2>
        <InlineTextWithVariables cms={cms} name="subtitle" value={subtitle}/>
      </StyledHeading>
      <RichtextWithVariables cms={cms} name="intro" richtext={intro}/>
      <Row>
        <Column>
          <div>
            <RichtextWithVariables cms={cms} name="richtext" richtext={richtext}/>
            <SectionButton
              name='button_main'
              href={button_main?.url}
              label={button_main?.label}
              cms={cms}
            />
          </div>
        </Column>
        <Column>
          <SectionImage
            name='image'
            src={image?.src}
            alt={image?.alt}
            width={image?.width}
            height={image?.height}
            cms={cms}
          />
          <div className="icon">{icon}</div>
        </Column>
      </Row>
      <RichtextWithVariables cms={cms} name="outro" richtext={outro}/>
      <SectionButton
        name={'button'}
        href={button?.url}
        label={button?.label}
        cms={cms}
      />
    </Section>
  )
}

// export const blockConfig = {
//   Component(props) {
//     const { index, data } = props

//     useEffect(() => {
//       console.log('TextblockImage data', data)
//     }, [data])

//     return (
//       <BlocksControls
//         index={index}
//         focusRing={{ offset: -4, borderRadius: 4 }}
//         insetControls
//       >
//         <TextblockImage {...data} />
//       </BlocksControls>
//     )
//   },
//   template: {
//     label: 'Textblock Image',
//     defaultItem: {
//       title: 'Textblock Image Title',
//       subtitle: 'Subtitle',
//       richtext:
//         '<p>Take root and flourish quis nostrum exercitationem ullam corporis suscipit laboriosam culture Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur descended from astronomers encyclopaedia galactica? Nisi ut aliquid ex ea commodi consequatur something incredible is waiting to be known sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem</p>',
//       image: {
//         src: '',
//         alt: '',
//       },
//       icon: '',
//       button: {
//         url: '/',
//         label: 'Button',
//       },
//       colorSchemeId: '1',
//     },
//     fields: [
//       {
//         name: 'title',
//         label: 'Title',
//         component: 'text',
//       },
//       {
//         name: 'subtitle',
//         label: 'Subtitle',
//         component: 'text',
//       },
//       {
//         name: 'intro',
//         label: 'Intro',
//         component: 'html',
//       },
//       {
//         name: 'icon',
//         label: 'Icon',
//         component: 'select',
//         options: ['🚀', '🚫', '✅', '⭐️'],
//       },
//       {
//         name: 'image',
//         label: 'Image',
//         component: 'group',
//         fields: [
//           {
//             name: 'src',
//             label: 'Image',
//             component: 'image',
//             description: 'Image description',
//             clearable: true,
//             parse: (media) => {
//               if (!media) {
//                 return
//               }
//               // console.log('image field config - parse:', media)
//               return `https://einstein-clients.imgix.net${media.directory}/${media.filename}`
//             },
//             previewSrc: (src) => {
//               console.log('previewSrc', src)
//               return src
//             },
//           },
//           {
//             name: 'alt',
//             label: 'Image Alt',
//             component: 'text',
//           },
//           {
//             name: 'caption',
//             label: 'Caption',
//             component: 'html',
//           },
//         ],
//       },
//       {
//         name: 'richtext',
//         label: 'Main Content',
//         component: 'html',
//       },
//       {
//         name: 'button_main',
//         label: 'Main Button',
//         component: 'group',
//         fields: buttonFields,
//       },
//       {
//         name: 'outro',
//         label: 'Outro',
//         component: 'html',
//       },
//       {
//         name: 'button',
//         label: 'Button',
//         component: 'group',
//         fields: buttonFields,
//       },
//       {
//         name: 'colorSchemeId',
//         label: 'Color Scheme',
//         component: 'select',
//         options: [
//           {
//             value: '1',
//             label: 'Default',
//           },
//         ],
//       },
//     ],
//     itemProps: (item) => {
//       const tempDiv = document.createElement('div')
//       tempDiv.innerHTML = item.title
//       return {
//         label: tempDiv.textContent,
//       }
//     },
//   },
// }

// export const templateName = 'textblockimage'
