import {useState} from 'react'
import Section from '../shared/Section'
import StyledHeading from '../shared/StyledHeading'
import {InlineTextWithVariables, RichtextWithVariables} from '../shared/SectionsWithVariables'
import SectionImage from '../shared/SectionImage'
import SectionButton from '../shared/SectionButton'


export default function OfficeTour(props) {
  const {
    title,
    subtitle,
    intro,
    poster,
    iframe,
    caption,
    outro,
    button,
    color_scheme,
    className,
    css_overrides,
    color_scheme_id_override,
    lucid_page_section_id, meta,
    cms,
  } = props

  const [tourIsActive, setTourIsActive] = useState(false)

  return (
    <Section
      cms={cms}
      css_overrides={css_overrides} color_scheme_id_override={color_scheme_id_override} meta={meta} lucid_page_section_id={lucid_page_section_id}
      className={className}
      name="officetour"
      colorSchemeId={color_scheme}
    >
      <StyledHeading>
        <h2>
          <InlineTextWithVariables cms={cms} name="title" value={title} />
        </h2>
        <InlineTextWithVariables cms={cms} name="subtitle" value={subtitle} />
      </StyledHeading>
      <RichtextWithVariables
        name="intro"
        richtext={intro}
        cms={cms}
      />
      {tourIsActive ? (
        // TODO: Lazyload iframe
        // TODO: Create iframe as HTML element?
        <div>
          <div dangerouslySetInnerHTML={{__html: iframe}} />
          <RichtextWithVariables
            name="caption"
            richtext={caption}
            cms={cms}
          />
        </div>
      ) : (
        // TODO: Use a default poster if none is set
        // TODO: Use next/image
        <div onClick={() => setTourIsActive(true)}>
          Click to activate virtual tour
          {poster ? (
            <SectionImage
              name='poster'
              src={poster?.src}
              alt={poster?.alt}
              height={poster?.height}
              width={poster?.width}
              layout='fill'
              cms={cms}/>
          ) : null}
        </div>
      )}
      <RichtextWithVariables
        name="outro"
        richtext={outro}
        cms={cms}
      />
      <SectionButton href={button?.href} label={button?.label} cms={cms}  name='button'/>
    </Section>
  )
}
