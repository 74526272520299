import Section from '../shared/Section'
import StyledHeading from '../shared/StyledHeading'
import {InlineTextWithVariables, RichtextWithVariables} from '../shared/SectionsWithVariables'
import SectionText from '../shared/SectionText'
import {TIMELINE_ITEM_BLOCKS} from './config'
import styled from 'styled-components'
import LucidInlineBlocks from '../shared/LucidInlineBlocks'

const StyledInlineBlocks = styled(LucidInlineBlocks)`
  display: grid;
  /* grid-template-columns: repeat(3, 1fr);
  grid-template-areas: 'a b c'; */

  @media (min-width: 640px) {
    .section-item-wrapper {
      display: grid;
      grid-template-columns: 130px repeat(2, 1fr);
    }

    > div {
      display: grid;
    }

    > div:nth-child(even) {
      justify-self: end;
    }
  }

  h3 span {
    display: block;
  }

  /* > div {
    display: flex;
    flex-wrap: wrap;
  }
  > div:nth-child(even) {
    justify-content: flex-end;
  } */
`

export default function Timeline(props) {
  const {
    title,
    subtitle,
    intro,
    quote,
    cite,
    outro,
    // button,
    color_scheme,
    className,
    css_overrides,
    color_scheme_id_override, lucid_page_section_id, meta,
    cms,
  } = props
  return (
    <Section
      cms={cms}
      css_overrides={css_overrides}
      color_scheme_id_override={color_scheme_id_override} meta={meta}
      lucid_page_section_id={lucid_page_section_id}
      className={className}
      name="timeline"
      colorSchemeId={color_scheme}>
      <StyledHeading>
        <h2>
          <InlineTextWithVariables cms={cms} name="title" value={title}/>
        </h2>
        <InlineTextWithVariables cms={cms} name="subtitle" value={subtitle}/>
      </StyledHeading>
      <RichtextWithVariables cms={cms} name="intro" richtext={intro}/>
      <blockquote>
        <q>
          <SectionText name="quote" text={quote} cms={cms}/>
        </q>
        <cite>
          <SectionText name="cite" text={cite} cms={cms}/>
        </cite>
      </blockquote>
      <StyledInlineBlocks itemProps={{...props}} name="timeline_items" blocks={TIMELINE_ITEM_BLOCKS}/>
      {/* <Items>
        {items.map((item) => (
          <TimelineItem
            key={item.id}
            title={item.title}
            subtitle={item.subtitle}
            description={item.description}
          />
        ))}
      </Items> */}
      <RichtextWithVariables cms={cms} name="outro" richtext={outro}/>
      {/* <SectionButton
        href={button?.url}
        label={button?.label}
        fields={buttonFields}
        cms={cms}
      /> */}
    </Section>
  )
}
