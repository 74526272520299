import Section from './shared/Section'
import StyledHeading from './shared/StyledHeading'
import {InlineTextWithVariables, RichtextWithVariables} from './shared/SectionsWithVariables'
import SectionText from './shared/SectionText'
import SectionImage from './shared/SectionImage'
import styled from 'styled-components'
import SectionButton from './shared/SectionButton'

const StyledDiv = styled.div`
  display: grid;
  justify-items: center;
  align-items: center;

  @media (min-width: 640px) {
    grid-template-columns: 50% auto repeat(2, 1fr);
  }
`

export default function QuoteImage(props) {
  const {
    title,
    subtitle,
    intro,
    quote,
    cite,
    image,
    outro,
    button,
    color_scheme,
    className,
    css_overrides,
    color_scheme_id_override, lucid_page_section_id, meta,
    cms,
  } = props
  return (
    <Section
      cms={cms}
      css_overrides={css_overrides}
      color_scheme_id_override={color_scheme_id_override} meta={meta}
      lucid_page_section_id={lucid_page_section_id}
      className={className}
      name="quoteimage"
      colorSchemeId={color_scheme}>
      <StyledHeading>
        <h2>
          <InlineTextWithVariables cms={cms} name="title" value={title}/>
        </h2>
        <InlineTextWithVariables cms={cms} name="subtitle" value={subtitle}/>
      </StyledHeading>
      <RichtextWithVariables cms={cms} name="intro" richtext={intro}/>
      <StyledDiv>
        <blockquote>
          <q>
            <SectionText name="quote" text={quote} cms={cms}/>
          </q>
          <cite>
            <SectionText name="cite" text={cite} cms={cms}/>
          </cite>
        </blockquote>
        <SectionImage
          name='image'
          src={image?.src}
          alt={image?.alt}
          width={image?.width}
          height={image?.height}
          cms={cms}
        />
      </StyledDiv>
      <RichtextWithVariables cms={cms} name="outro" richtext={outro}/>
      <SectionButton
        name='button'
        href={button?.url}
        label={button?.label}
        cms={cms}
      />
    </Section>
  )
}

// export const blockConfig = {
//   Component(props) {
//     const { index, data } = props

//     useEffect(() => {
//       console.log('QuoteImage data', data)
//     }, [data])

//     return (
//       <BlocksControls
//         index={index}
//         focusRing={{ offset: -4, borderRadius: 4 }}
//         insetControls
//       >
//         <QuoteImage {...data} />
//       </BlocksControls>
//     )
//   },
//   template: {
//     label: 'Quote Image',
//     defaultItem: {
//       title: 'Quote Image',
//       subtitle: '',
//       quote:
//         'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum convallis quam eu congue suscipit. Sed ac vestibulum ex. Donec vitae purus dictum, posuere dolor.',
//       cite: '- Author',
//       image: {
//         src: 'https://tools--dev.cms.eiidev.com/placeholder/425/560',
//         alt: '',
//       },
//       colorSchemeId: '1',
//     },
//     fields: [
//       {
//         name: 'title',
//         label: 'Title',
//         component: 'text',
//       },
//       {
//         name: 'subtitle',
//         label: 'Subtitle',
//         component: 'text',
//       },
//       {
//         name: 'intro',
//         label: 'Intro',
//         component: 'html',
//       },
//       {
//         name: 'quote',
//         label: 'Quote',
//         component: 'text',
//       },
//       {
//         name: 'cite',
//         label: 'Cite',
//         component: 'text',
//       },
//       {
//         name: 'image',
//         label: 'Image',
//         component: 'group',
//         fields: [
//           {
//             name: 'src',
//             label: 'Image',
//             component: 'image',
//             description: 'Image description',
//             clearable: true,
//             parse: (media) => {
//               if (!media) {
//                 return
//               }
//               // console.log('image field config - parse:', media)
//               return `https://einstein-clients.imgix.net${media.directory}/${media.filename}`
//             },
//             previewSrc: (src) => {
//               console.log('previewSrc', src)
//               return src
//             },
//           },
//           {
//             name: 'alt',
//             label: 'Image Alt',
//             component: 'text',
//           },
//           {
//             name: 'caption',
//             label: 'Caption',
//             component: 'html',
//           },
//         ],
//       },
//       {
//         name: 'outro',
//         label: 'Outro',
//         component: 'html',
//       },
//       {
//         name: 'button',
//         label: 'Button',
//         component: 'group',
//         fields: buttonFields,
//       },
//       {
//         name: 'colorSchemeId',
//         label: 'Color Scheme',
//         component: 'select',
//         options: [
//           {
//             value: '1',
//             label: 'Default',
//           },
//         ],
//       },
//     ],
//     itemProps: (item) => {
//       const tempDiv = document.createElement('div')
//       tempDiv.innerHTML = item.title
//       return {
//         label: tempDiv.textContent,
//       }
//     },
//   },
// }

// export const templateName = 'quoteimage'
