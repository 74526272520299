import StyledHeading from './shared/StyledHeading'
import {InlineTextWithVariables, RichtextWithVariables} from './shared/SectionsWithVariables'
import SectionImage from './shared/SectionImage'
import Section from './shared/Section'
import styled from 'styled-components'
import SectionButton from './shared/SectionButton'
import {IMAGE_FIELDS} from './shared/InputTypesFields'
import {InlineGroup} from '@einsteinindustries/react-tinacms-inline'
import SectionText from './shared/SectionText'
import {LucidInlineGroups} from './shared/LucidInlineGroups'

const GROUP_FIELDS = [{
  name: 'items',
  label: 'Add Images',
  component: 'blocks',
  templates: {
    image_item: {
      label: 'Image Item',
      fields: [
        ...IMAGE_FIELDS,
        {
          label: 'Label',
          name: 'label',
          component: 'text',
        },
        {
          label: 'Caption',
          name: 'caption',
          component: 'text',
        },
      ]
    }
  },
}]

const StyledDiv = styled.div`
  display: grid;
  justify-items: center;
  align-items: center;

  @media (min-width: 640px) {
    grid-template-columns: repeat(2, 1fr);
  }
`

export default function PhotoSetCollage(props) {
  const {
    title,
    subtitle,
    intro,
    images,
    outro,
    button,
    color_scheme,
    className,
    css_overrides,
    color_scheme_id_override, lucid_page_section_id, meta,
    cms,
  } = props
  return (
    <Section
      cms={cms}
      css_overrides={css_overrides}
      color_scheme_id_override={color_scheme_id_override} meta={meta}
      lucid_page_section_id={lucid_page_section_id}
      className={className}
      name="photosetcollage"
      colorSchemeId={color_scheme}>
      <StyledHeading>
        <h2>
          <InlineTextWithVariables cms={cms} name="title" value={title}/>
        </h2>
        <InlineTextWithVariables cms={cms} name="subtitle" value={subtitle}/>
      </StyledHeading>
      <RichtextWithVariables cms={cms} name="intro" richtext={intro}/>
      <StyledDiv>
        <LucidInlineGroups
          name='images'
          fields={GROUP_FIELDS}
          insetControls={true}
          focusRing={true}
          cms={cms}
        >
          <>
            {images?.items?.map((item, i) => (
              <div className='image-wrapper' key={i}>
                <SectionImage
                  src={item.src}
                  alt={item.alt}
                  width={item.width}
                  height={item.height}
                  cms={cms}
                  name={`items[${i}]`}
                />
                <SectionText name={`items[${i}].label`} text={item.label} cms={cms}/>
                <RichtextWithVariables cms={cms} name={`items[${i}].caption`} richtext={item.caption}/>
              </div>
            ))}
          </>
        </LucidInlineGroups>
      </StyledDiv>
      <RichtextWithVariables cms={cms} name="outro" richtext={outro}/>
      <SectionButton
        name='button'
        href={button?.url}
        label={button?.label}
        cms={cms}
      />
    </Section>
  )
}

// export const blockConfig = {
//   Component(props) {
//     const { index, data } = props

//     useEffect(() => {
//       console.log('PhotoSetCollage data', data)
//     }, [data])

//     return (
//       <BlocksControls
//         index={index}
//         focusRing={{ offset: -4, borderRadius: 4 }}
//         insetControls
//       >
//         <PhotoSetCollage {...data} />
//       </BlocksControls>
//     )
//   },
//   template: {
//     label: 'Photo Set Collage',
//     defaultItem: {
//       title: 'Photo Set Collage',
//       subtitle: '',
//       image_1: {
//         src: 'https://tools--dev.cms.eiidev.com/placeholder/600/400',
//         alt: '',
//       },
//       label_1: '<p>Label 1</p>',
//       image_2: {
//         src: 'https://tools--dev.cms.eiidev.com/placeholder/600/400',
//         alt: '',
//       },
//       label_2: '<p>Label 2</p>',
//       button: {
//         url: '/',
//         label: 'Button',
//       },
//       colorSchemeId: '1',
//     },
//     fields: [
//       {
//         name: 'title',
//         label: 'Title',
//         component: 'text',
//       },
//       {
//         name: 'subtitle',
//         label: 'Subtitle',
//         component: 'text',
//       },
//       {
//         name: 'intro',
//         label: 'Intro',
//         component: 'html',
//       },
//       {
//         name: 'image_1',
//         label: 'Image 1',
//         component: 'group',
//         fields: [
//           {
//             name: 'src',
//             label: 'Image 1',
//             component: 'image',
//             description: 'Image description',
//             clearable: true,
//             parse: (media) => {
//               if (!media) {
//                 return
//               }
//               // console.log('image field config - parse:', media)
//               return `https://einstein-clients.imgix.net${media.directory}/${media.filename}`
//             },
//             previewSrc: (src) => {
//               console.log('previewSrc', src)
//               return src
//             },
//           },
//           {
//             name: 'alt',
//             label: 'Image 1 Alt',
//             component: 'text',
//           },
//         ],
//       },
//       {
//         name: 'label_1',
//         label: 'Label 1',
//         component: 'html',
//       },
//       {
//         name: 'image_2',
//         label: 'Image 2',
//         component: 'group',
//         fields: [
//           {
//             name: 'src',
//             label: 'Image 2',
//             component: 'image',
//             description: 'Image description',
//             clearable: true,
//             parse: (media) => {
//               if (!media) {
//                 return
//               }
//               // console.log('image field config - parse:', media)
//               return `https://einstein-clients.imgix.net${media.directory}/${media.filename}`
//             },
//             previewSrc: (src) => {
//               console.log('previewSrc', src)
//               return src
//             },
//           },
//           {
//             name: 'alt',
//             label: 'Image 2 Alt',
//             component: 'text',
//           },
//         ],
//       },
//       {
//         name: 'label_2',
//         label: 'Label 2',
//         component: 'html',
//       },
//       {
//         name: 'outro',
//         label: 'Outro',
//         component: 'html',
//       },
//       {
//         name: 'button',
//         label: 'Button',
//         component: 'group',
//         fields: buttonFields,
//       },
//       {
//         name: 'colorSchemeId',
//         label: 'Color Scheme',
//         component: 'select',
//         options: [
//           {
//             value: '1',
//             label: 'Default',
//           },
//         ],
//       },
//     ],
//     itemProps: (item) => {
//       const tempDiv = document.createElement('div')
//       tempDiv.innerHTML = item.title
//       return {
//         label: tempDiv.textContent,
//       }
//     },
//   },
// }

// export const templateName = 'photosetcollage'
