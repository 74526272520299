import StyledHeading from './shared/StyledHeading'
import {InlineTextWithVariables, RichtextWithVariables} from './shared/SectionsWithVariables'
import SectionText from './shared/SectionText'
import SectionImage from './shared/SectionImage'
import Section from './shared/Section'
import styled from 'styled-components'
import SectionButton from './shared/SectionButton'

const StyledSection = styled(Section)`
  cite::before {
    content: "\2014 \0020"
  }

  .quote {
    font-size: 1.5rem;
    padding-left: calc(2rem + 10vw);
    padding-right: 30%;
    line-height: 1.75;
    font-style: italic;
    display: block;
  }

  blockquote {
    margin: 0;
  }
`
const Row = styled.div`
  overflow: hidden;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;

  .image {
    margin: 0 2rem;
    display: block;
    padding-bottom: 2rem;

    ::before {
      opacity: 1;
      background-color: #000;
      position: absolute;
      z-index: 1;
      bottom: 1rem;
      right: 40px;
      content: '';
      display: block;
      height: 130px;
      width: 200px;
    }

    ::after {
      opacity: 0.8;
      background-color: #555;
      position: absolute;
      z-index: 3;
      right: 200px;
      bottom: 0;
      content: '';
      display: block;
      height: 100px;
      width: 120px;
    }
  }

  .phrase {
    opacity: 1;
    color: #e8e8e8;
    font-size: 11rem;
    line-height: 1;
    position: absolute;
    z-index: 5;
    left: auto;
    top: 2rem;
    right: 10%;
  }

  .textblock {
    width: 50%;
    padding-top: 2rem;
    margin: 0 2rem;
    position: relative;
    z-index: 5;
    padding-bottom: 10rem;
  }
`

export default function TextblockImageCollageC(props) {
  const {
    title,
    subtitle,
    intro,
    quote,
    quote_author,
    textblock_title,
    textblock_content,
    image,
    phrase,
    outro,
    button,
    color_scheme,
    className,
    css_overrides,
    color_scheme_id_override,
    lucid_page_section_id,
    cms
  } = props
  return (
    <StyledSection
      cms={cms}
      css_overrides={css_overrides}
      color_scheme_id_override={color_scheme_id_override}
      lucid_page_section_id={lucid_page_section_id}
      className={className}
      name="textblockimagecollagec"
      colorSchemeId={color_scheme}>
      <StyledHeading>
        <h2>
          <InlineTextWithVariables cms={cms} name="title" value={title}/>
        </h2>
        <InlineTextWithVariables cms={cms} name="subtitle" value={subtitle}/>
      </StyledHeading>
      <RichtextWithVariables cms={cms} name="intro" richtext={intro}/>
      <Row>
        <div className="image">
          <SectionImage
            name='image'
            src={image?.src}
            alt={image?.alt}
            width={image?.width}
            height={image?.height}
            cms={cms}
          />
        </div>
        <div className="phrase">
          <SectionText name="phrase" text={phrase} cms={cms}/>
        </div>
        <div className="textblock">
          {textblock_title ? (
            <h3>
              <SectionText
                name="textblock_title"
                text={textblock_title}
                cms={cms}
              />
            </h3>
          ) : null}
          <RichtextWithVariables
            name="textblock_content"
            richtext={textblock_content}
            cms={cms}
          />
        </div>
      </Row>
      {quote || quote_author ? (
        <div className="quote">
          {quote ? (
            <blockquote>
              <RichtextWithVariables cms={cms} name="quote" richtext={quote}/>
            </blockquote>
          ) : null}
          {quote_author ? (
            <cite>
              <SectionText name="quote_author" text={quote_author} cms={cms}/>
            </cite>
          ) : null}
        </div>
      ) : null}

      <RichtextWithVariables cms={cms} name="outro" richtext={outro}/>
      <SectionButton
        name='button'
        href={button?.url}
        label={button?.label}
        cms={cms}
      />
    </StyledSection>
  )
}

// export const blockConfig = {
//   Component(props) {
//     const { index, data } = props

//     return (
//       <BlocksControls
//         index={index}
//         // focusRing={{ offset: -4, borderRadius: 4 }}
//         insetControls
//       >
//         {/* eslint-disable-next-line */}
//         <TextblockImageCollageC {...data} />
//       </BlocksControls>
//     )
//   },
//   template: {
//     label: 'Textblock Image Collage C',
//     defaultItem: {
//       title: 'Textblock Image Collage C Section',
//       subtitle: '',
//       textblock_title: 'Title',
//       textblock_content:
//         '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>',
//       image: {
//         src: 'https://tools--dev.cms.eiidev.com/placeholder/340/425',
//         alt: '',
//       },
//       phrase: 'Lorem',
//       quote: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
//       quote_author: 'Author',
//       button: {
//         label: '',
//         url: '',
//       },
//       colorSchemeId: '1',
//     },
//     fields: [
//       {
//         name: 'title',
//         label: 'Title',
//         component: 'text',
//       },
//       {
//         name: 'subtitle',
//         label: 'Subtitle',
//         component: 'text',
//       },
//       {
//         name: 'intro',
//         label: 'Intro',
//         component: 'html',
//       },
//       {
//         name: 'textblock_title',
//         label: 'Textblock Title',
//         component: 'text',
//       },
//       {
//         name: 'textblock_content',
//         label: 'Textblock Content',
//         component: 'html',
//       },
//       {
//         name: 'image',
//         label: 'Image',
//         component: 'group',
//         fields: [
//           {
//             name: 'src',
//             label: 'Image',
//             component: 'image',
//             description: 'Image description',
//             clearable: true,
//             parse: (media) => {
//               if (!media) {
//                 return
//               }
//               // console.log('image field config - parse:', media)
//               return `https://einstein-clients.imgix.net${media.directory}/${media.filename}`
//             },
//             previewSrc: (src) => {
//               console.log('previewSrc', src)
//               return src
//             },
//           },
//           {
//             name: 'alt',
//             label: 'Image Alt',
//             component: 'text',
//           },
//         ],
//       },
//       {
//         name: 'phrase',
//         label: 'Catchphrase',
//         component: 'text',
//       },
//       {
//         name: 'outro',
//         label: 'Outro',
//         component: 'html',
//       },
//       {
//         name: 'button',
//         label: 'Button',
//         component: 'group',
//         fields: buttonFields,
//       },
//       {
//         name: 'colorSchemeId',
//         label: 'Color Scheme',
//         component: 'select',
//         options: [
//           {
//             value: '1',
//             label: 'Default',
//           },
//         ],
//       },
//     ],
//     itemProps: (item) => {
//       const tempDiv = document.createElement('div')
//       tempDiv.innerHTML = item.title
//       return {
//         label: tempDiv.textContent,
//       }
//     },
//   },
// }

// export const templateName = 'textblockimagecollagec'
