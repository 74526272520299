import {BlocksControls} from '@einsteinindustries/react-tinacms-inline'
import SectionImage from '../shared/SectionImage'

// Base item for all the other item types to pull from
const baseItem = {
  label: 'Item',
  defaultItem: {
    title: 'Title',
    subtitle: 'Subtitle',
  },
  itemProps: (item) => {
    if (!item.title && !item.subtitle) {
      return
    }

    return {label: item.title || item.subtitle}
  },
  // TODO: Create a set of default fields
  fields: [
    {
      name: 'title',
      label: 'Title',
      component: 'text',
    },
    {
      name: 'subtitle',
      label: 'Subtitle',
      component: 'text',
    },
  ],
}

// Templates to use in the parent's block config and the InlineBlocks component
const itemTemplates = {
  affiliations: {
    ...baseItem,
    label: 'Affiliations Item',
    image: {
      src: '',
      alt: '',
    },
    defaultItem: {
      ...baseItem.defaultItem,
      image: {
        src: '',
        alt: '',
      },
    },
    fields: [
      ...baseItem.fields,
      {
        name: 'image',
        label: 'Image',
        component: 'group',
        fields: [
          {
            name: 'src',
            label: 'Image',
            component: 'image',
            description: 'Image description',
            clearable: true,
            parse: (media) => {
              if (!media) {
                return
              }
              // console.log('image field config - parse:', media)
              return `https://${process.env.NEXT_PUBLIC_IMGIX_READ_URL}${media.directory}/${media.filename}`
            },
            previewSrc: (src) => {
              console.log('previewSrc', src)
              return src
            },
          },
          {
            name: 'alt',
            label: 'Image Alt',
            component: 'text',
          },
        ],
      },
    ],
  },
}

// Tina form config for MenuDemo
// export const blockConfig = {
//   Component(props) {
//     const { index, data } = props

//     return (
//       <BlocksControls
//         index={index}
//         focusRing={{ offset: -4, borderRadius: 4 }}
//         insetControls
//       >
//         <Affiliations {...data} />
//       </BlocksControls>
//     )
//   },
//   template: {
//     label: 'Affiliations',
//     defaultItem: {
//       title: 'Affiliations',
//       subtitle: '',
//       intro: '',
//       affiliations_items: [
//         {
//           // id: 1,
//           _template: 'affiliations',
//           image: 'https://tools--dev.cms.eiidev.com/placeholder/300/300',
//           alt: 'Image Alt',
//         },
//       ],
//       outro: '',
//       button: {
//         href: '',
//         label: '',
//       },
//     },
//     fields: [
//       {
//         name: 'title',
//         label: 'Title',
//         component: 'text',
//       },
//       {
//         name: 'subtitle',
//         label: 'Subtitle',
//         component: 'text',
//       },
//       {
//         name: 'intro',
//         label: 'Intro',
//         component: 'html',
//       },
//       {
//         name: 'affiliations_items',
//         label: 'Items',
//         component: 'blocks',
//         templates: itemTemplates,
//       },
//       {
//         name: 'outro',
//         label: 'Outro',
//         component: 'html',
//       },
//       {
//         name: 'button',
//         label: 'Button',
//         component: 'group',
//         fields: [
//           ...buttonFields,
//           // {
//           //   label: 'Label',
//           //   name: 'label',
//           //   component: 'text',
//           // },
//           // {
//           //   label: 'Link URL',
//           //   name: 'url',
//           //   component: 'text',
//           // },
//         ],
//       },
//     ],
//   },
// }

// InlineBlocks `blocks`
export const AFFILIATIONS_ITEM_BLOCKS = {
  affiliations: {
    Component({_name, index, data}) {
      return (
        <ItemWrapper index={index}>
          <SectionImage
            name="image.src"
            src={data.image.src}
            alt={data.image.alt}
            // width={image.width}
            // height={image.height}
            cms={data.cms}
          />
          {/* <pre style={{ whiteSpace: 'pre-wrap' }}>
            {JSON.stringify(data, null, 2)}
          </pre> */}
        </ItemWrapper>
      )
    },
    template: itemTemplates.affiliations,
  },
}

function ItemWrapper(props) {
  return (
    <div
      className="section-item-wrapper"
      style={{
        flex: '1 0 calc(33% - 1rem)',
        // border: '1px dashed #000',
        padding: '1rem',
      }}
    >
      <BlocksControls
        index={props.index}
        focusRing={{offset: 16, borderRadius: 4}}
        insetControls={false}
      >
        {props.children}
      </BlocksControls>
    </div>
  )
}
