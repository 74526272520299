import Section from '../shared/Section'
import {RichtextWithVariables} from '../shared/SectionsWithVariables'
import SectionText from '../shared/SectionText'
import styled from 'styled-components'
import Helpers from '@/src/utils/shared/helpers'
import {useLucidContext} from '../../../state/ServerSideStore'

const StyledDiv = styled.div``

// TODO: return "part of the DocShop Network" after Practice Name in copyright if affiliated with DocShop

function LawDisclaimer(props) {
  return (
    <p>
      *Disclaimer: the information provided by this website is for informational
      purposes only and should not be considered legal advice or a substitute
      for competent legal counsel.
    </p>
  )
}

function MedicalDisclaimer(props) {
  return (
    <p>
      *Disclaimer: results are not guaranteed, may not be permanent, and can
      vary per individual. Some images are of models, not actual patients.
    </p>
  )
}

export default function Footer(props) {
  const [{contents}] = useLucidContext(props.cms)
  const practice_name = contents.practice?.name ?? ''
  const practice_type = contents.practice?.type ?? ''
  const disclaimer = practice_type === 'legal' ? <LawDisclaimer/> : practice_type === 'medical' ?
    <MedicalDisclaimer/> : ''
  const {
    richtext,
    copyright_start_year,
    color_scheme,
    className,
    css_overrides,
    color_scheme_id_override, lucid_page_section_id, meta,
    cms,
  } = props
  return (
    <Section
      cms={cms}
      css_overrides={css_overrides}
      color_scheme_id_override={color_scheme_id_override} meta={meta}
      lucid_page_section_id={lucid_page_section_id}
      className={className}
      name="footer"
      colorSchemeId={color_scheme}>
      <StyledDiv>
        <RichtextWithVariables cms={cms} name="richtext" richtext={richtext}/>
        {disclaimer}
        ©{copyright_start_year} {practice_name} | Forever Website® 3.0 | Designed
        &amp; Developed by Einstein {Helpers.format.capitalize.firstLetter(practice_type)}
        <SectionText
          name="copyright_start_year"
          text={copyright_start_year}
          cms={cms}
        />
      </StyledDiv>
    </Section>
  )
}

// export const blockConfig = {
//   Component(props) {
//     const { index, data } = props

//     useEffect(() => {
//       console.log('Footer data', data)
//     }, [data])

//     return (
//       <BlocksControls
//         index={index}
//         focusRing={{ offset: -4, borderRadius: 4 }}
//         insetControls
//       >
//         <Footer {...data} />
//       </BlocksControls>
//     )
//   },
//   template: {
//     label: 'Footer',
//     defaultItem: {
//       disclaimer:
//         '<p>*Disclaimer: results are not guaranteed, may not be permanent, and can vary per individual. Some images are of models, not actual patients.</p>',
//       copyright_start_year: '2021',
//       colorSchemeId: '1',
//     },
//     fields: [
//       {
//         name: 'richtext',
//         label: 'Content',
//         component: 'html',
//       },
//       {
//         name: 'disclaimer',
//         label: 'Custom Disclaimer',
//         component: 'html',
//       },
//       {
//         name: 'copyright_start_year',
//         label: 'Copyright Start Year',
//         component: 'text',
//       },
//       {
//         name: 'colorSchemeId',
//         label: 'Color Scheme',
//         component: 'select',
//         options: [
//           {
//             value: '1',
//             label: 'Default',
//           },
//         ],
//       },
//     ],
//     itemProps: (item) => {
//       const tempDiv = document.createElement('div')
//       tempDiv.innerHTML = item.title
//       return {
//         label: tempDiv.textContent,
//       }
//     },
//   },
// }

// export const templateName = 'footer'
