import {BlocksControls} from '@einsteinindustries/react-tinacms-inline'
import {RichtextWithVariables} from '../shared/SectionsWithVariables'
import SectionText from '../shared/SectionText'

// Base item for all the other item types to pull from
const baseItem = {
  label: 'Item',
  defaultItem: {
    title: 'Title',
    subtitle: 'Subtitle',
  },
  itemProps: (item) => {
    if (!item.title && !item.subtitle) {
      return
    }

    return {label: item.title || item.subtitle}
  },
  // TODO: Create a set of default fields
  fields: [
    {
      name: 'title',
      label: 'Title',
      component: 'text',
    },
    {
      name: 'subtitle',
      label: 'Subtitle',
      component: 'text',
    },
  ],
}

// Templates to use in the parent's block config and the InlineBlocks component
const itemTemplates = {
  featurescard: {
    ...baseItem,
    label: 'Features Card Item',
    defaultItem: {
      ...baseItem.defaultItem,
      description:
        '<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci expedita doloremque temporibus ipsum illo vero aut voluptas laborum ex dignissimos numquam at odio, aspernatur voluptatum, provident sit nesciunt! Dicta, natus.</p>',
    },
    fields: [
      ...baseItem.fields,
      {
        name: 'description',
        label: 'Description',
        component: 'html',
      },
    ],
  },
}

// Tina form config for MenuDemo
// export const blockConfig = {
//   Component(props) {
//     const { index, data } = props

//     return (
//       <BlocksControls
//         index={index}
//         focusRing={{ offset: -4, borderRadius: 4 }}
//         insetControls
//       >
//         <FeaturesCard {...data} />
//       </BlocksControls>
//     )
//   },
//   template: {
//     ...baseItem,
//     label: 'Features Card',
//     defaultItem: {
//       title: 'Features Card',
//       subtitle: '',
//       intro: '',
//       features_card_items: [
//         {
//           // id: 1,
//           _template: 'featurescard',
//           ...baseItem.defaultItem,
//           description:
//             '<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci expedita doloremque temporibus ipsum illo vero aut voluptas laborum ex dignissimos numquam at odio, aspernatur voluptatum, provident sit nesciunt! Dicta, natus.</p>',
//         },
//         {
//           // id: 2,
//           _template: 'featurescard',
//           ...baseItem.defaultItem,
//           description:
//             '<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci expedita doloremque temporibus ipsum illo vero aut voluptas laborum ex dignissimos numquam at odio, aspernatur voluptatum, provident sit nesciunt! Dicta, natus.</p>',
//         },
//       ],
//       outro: '',
//       button: {
//         href: '',
//         label: '',
//       },
//     },
//     fields: [
//       ...baseItem.fields,
//       {
//         name: 'intro',
//         label: 'Intro',
//         component: 'html',
//       },
//       {
//         name: 'features_card_items',
//         label: 'Items',
//         component: 'blocks',
//         templates: itemTemplates,
//       },
//       {
//         name: 'outro',
//         label: 'Outro',
//         component: 'html',
//       },
//       {
//         name: 'button',
//         label: 'Button',
//         component: 'group',
//         fields: [...buttonFields],
//       },
//     ],
//   },
// }

// InlineBlocks `blocks`
export const FEATURES_CARD_ITEM_BLOCKS = {
  featurescard: {
    Component({_name, index, data}) {
      return (
        <ItemWrapper index={index}>
          <ItemHeader title={data.title} subtitle={data.subtitle} />
          <RichtextWithVariables
            name="description"
            richtext={data.description}
            cms={data.cms}
          />
          {/* <pre style={{ whiteSpace: 'pre-wrap' }}>
            {JSON.stringify(data, null, 2)}
          </pre> */}
        </ItemWrapper>
      )
    },
    template: itemTemplates.featurescard,
  },
}

function ItemWrapper(props) {
  return (
    <div
      className="section-item-wrapper"
      // style={{
      //   flex: '1 0 calc(33% - 1rem)',
      //   // border: '1px dashed #000',
      //   padding: '1rem',
      // }}
    >
      <BlocksControls
        index={props.index}
        focusRing={{offset: 16, borderRadius: 4}}
        insetControls={false}
      >
        {props.children}
      </BlocksControls>
    </div>
  )
}

function ItemHeader(props) {
  if (!props.title && !props.subtitle) {
    return null
  }
  return (
    <h4>
      {props.title ? (
        <span className="title">
          <SectionText name="title" text={props.title} cms={cms} />
        </span>
      ) : null}
      {props.subtitle ? (
        <span className="subtitle">
          <SectionText name="subtitle" text={props.subtitle} cms={cms} />
        </span>
      ) : null}
    </h4>
  )
}
