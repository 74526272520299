import React, {useState} from 'react'
import {AnimatePresence, motion} from 'framer-motion'
import {wrap} from 'popmotion'
import Section from '../shared/Section'
import StyledHeading from '../shared/StyledHeading'
import {InlineTextWithVariables, RichtextWithVariables} from '../shared/SectionsWithVariables'
import styled from 'styled-components'
import SectionButton from '../shared/SectionButton'
import SectionText from '../shared/SectionText'


// TO-DO: Do we need domain and category fields

const variants = {
  enter: (direction) => {
    return {
      x: direction > 0 ? 1000 : -1000,
      opacity: 0,
    }
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    }
  },
}

const StyledDiv = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 520px;

  .next,
  .prev {
    top: calc(50% - 20px);
    position: absolute;
    background: white;
    border-radius: 30px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    cursor: pointer;
    font-weight: bold;
    font-size: 18px;
    z-index: 2;
  }

  .next {
    right: 10px;
  }

  .prev {
    left: 10px;
    transform: scale(-1);
  }

  img {
    position: absolute;
    max-width: 100%;
  }

  .refresh {
    padding: 10px;
    position: absolute;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    width: 20px;
    height: 20px;
    top: 10px;
    right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  /* .slideshow-wrapper {
    display: grid;
    justify-items: center;
    align-items: center;
    grid-auto-flow: column;
    width: 100%;
  } */
`

// const StyledInlineBlocks = styled(InlineBlocks)`
//   > div {
//     display: grid;
//     grid-template-columns: 1fr 1fr;
//     grid-gap: 0.75rem;
//   }

//   /* To display images side by side when in edit mode */
//   div[offset] {
//     display: grid;
//     grid-template-columns: 1fr 1fr;
//     grid-gap: 0.75rem;
//     width: calc(200% + 0.75rem);
//   }
// `

export default function Slideshow(props) {
  const {
    title,
    subtitle,
    intro,
    slideshow_gallery_domain,
    slideshow_gallery_category,
    outro,
    button,
    images,
    color_scheme,
    className,
    css_overrides,
    color_scheme_id_override, lucid_page_section_id, meta,
    cms,
  } = props

  const [[page, direction], setPage] = useState([0, 0])
  const imageIndex = wrap(0, images?.items?.length, page)
  const paginate = (newDirection) => {
    setPage([page + newDirection, newDirection])
  }

  return (
    <Section
      cms={cms}
      css_overrides={css_overrides}
      color_scheme_id_override={color_scheme_id_override} meta={meta}
      lucid_page_section_id={lucid_page_section_id}
      className={className}
      name="slideshow"
      colorSchemeId={color_scheme}>
      <StyledHeading>
        <h2>
          <InlineTextWithVariables cms={cms} name="title" value={title}/>
        </h2>
        <InlineTextWithVariables cms={cms} name="subtitle" value={subtitle}/>
      </StyledHeading>
      <RichtextWithVariables cms={cms} name="intro" richtext={intro}/>
      <StyledDiv>
        <AnimatePresence initial={false} custom={direction}>
          <motion.img
            key={page}
            src={images?.items?.[imageIndex].src}
            custom={direction}
            variants={variants}
            initial='enter'
            animate='center'
            exit='exit'
            // initial={{ opacity: 0 }}
            // animate={{ opacity: 1 }}
            // exit={{ opacity: 0 }}
            transition={{
              x: {type: 'spring', stiffness: 300, damping: 30},
              opacity: {duration: 0.2},
            }}
            drag='x'
            dragConstraints={{left: 0, right: 0}}
            dragElastic={1}
          />
        </AnimatePresence>
        <div className='next' onClick={() => paginate(1)}>
          {'‣'}
        </div>
        <div className='prev' onClick={() => paginate(-1)}>
          {'‣'}
        </div>
      </StyledDiv>
      <RichtextWithVariables cms={cms} name='outro' richtext={outro}/>
      <SectionButton name='button' href={button?.href} label={button?.label} cms={cms} />
      <SectionText
        name='slideshow_gallery_domain'
        text={slideshow_gallery_domain}
        cms={cms}
      />
      <SectionText
        name='slideshow_gallery_category'
        text={slideshow_gallery_category}
        cms={cms}
      />
    </Section>
  )
}
