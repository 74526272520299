import Section from './shared/Section'
import StyledHeading from './shared/StyledHeading'
import {InlineTextWithVariables, RichtextWithVariables} from './shared/SectionsWithVariables'
import SectionText from './shared/SectionText'
import SectionButton from './shared/SectionButton'

export default function Quote(props) {
  const {
    title,
    subtitle,
    intro,
    quote,
    cite,
    outro,
    button,
    color_scheme,
    css_overrides,
    color_scheme_id_override, lucid_page_section_id, meta,
    className,
    cms,
  } = props
  return (
    <Section
      cms={cms}
      css_overrides={css_overrides}
      color_scheme_id_override={color_scheme_id_override} meta={meta}
      lucid_page_section_id={lucid_page_section_id}
      className={className}
      name="quote"
      colorSchemeId={color_scheme}>
      <StyledHeading>
        <h2>
          <InlineTextWithVariables cms={cms} name="title" value={title}/>
        </h2>
        <InlineTextWithVariables cms={cms} name="subtitle" value={subtitle}/>
      </StyledHeading>
      <RichtextWithVariables cms={cms} name="intro" richtext={intro}/>
      <blockquote>
        <q>
          <SectionText name="quote" text={quote} cms={cms}/>
        </q>
        <cite>
          <SectionText name="cite" text={cite} cms={cms}/>
        </cite>
      </blockquote>
      <RichtextWithVariables cms={cms} name="outro" richtext={outro}/>
      <SectionButton
        name='button'
        href={button?.url}
        label={button?.label}
        cms={cms}
      />
    </Section>
  )
}

// export const blockConfig = {
//   Component(props) {
//     const { index, data } = props

//     useEffect(() => {
//       console.log('Quote data', data)
//     }, [data])

//     return (
//       <BlocksControls
//         index={index}
//         focusRing={{ offset: -4, borderRadius: 4 }}
//         insetControls
//       >
//         <Quote {...data} />
//       </BlocksControls>
//     )
//   },
//   template: {
//     label: 'Quote',
//     defaultItem: {
//       title: 'Quote',
//       subtitle: '',
//       quote:
//         'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum convallis quam eu congue suscipit. Sed ac vestibulum ex. Donec vitae purus dictum, posuere dolor.',
//       cite: '- Author',
//       colorSchemeId: '1',
//     },
//     fields: [
//       {
//         name: 'title',
//         label: 'Title',
//         component: 'text',
//       },
//       {
//         name: 'subtitle',
//         label: 'Subtitle',
//         component: 'text',
//       },
//       {
//         name: 'intro',
//         label: 'Intro',
//         component: 'html',
//       },
//       {
//         name: 'quote',
//         label: 'Quote',
//         component: 'text',
//       },
//       {
//         name: 'cite',
//         label: 'Cite',
//         component: 'text',
//       },
//       {
//         name: 'outro',
//         label: 'Outro',
//         component: 'html',
//       },
//       {
//         name: 'button',
//         label: 'Button',
//         component: 'group',
//         fields: buttonFields,
//       },
//       {
//         name: 'colorSchemeId',
//         label: 'Color Scheme',
//         component: 'select',
//         options: [
//           {
//             value: '1',
//             label: 'Default',
//           },
//         ],
//       },
//     ],
//     itemProps: (item) => {
//       const tempDiv = document.createElement('div')
//       tempDiv.innerHTML = item.title
//       return {
//         label: tempDiv.textContent,
//       }
//     },
//   },
// }

// export const templateName = 'quote'
