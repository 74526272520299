import StyledHeading from './shared/StyledHeading'
import {InlineTextWithVariables, RichtextWithVariables} from './shared/SectionsWithVariables'
import Section from './shared/Section'
import styled from 'styled-components'
import SectionButton from './shared/SectionButton'
import {IMAGE_GROUP} from './shared/InputTypesFields'
import React from 'react'
import SectionImage from './shared/SectionImage'
import {InlineGroup} from '@einsteinindustries/react-tinacms-inline'
import {LucidInlineGroups} from './shared/LucidInlineGroups'

const StyledDiv = styled.div`
  display: grid;
  justify-items: center;
  align-items: center;

  @media (min-width: 640px) {
    grid-template-columns: repeat(2, 1fr);
  }
`

export default function PhotoSet(props) {
  const {
    title,
    subtitle,
    intro,
    images,
    outro,
    button,
    color_scheme,
    className,
    css_overrides,
    color_scheme_id_override, lucid_page_section_id, meta,
    cms,
  } = props
  return (
    <Section
      cms={cms}
      css_overrides={css_overrides}
      color_scheme_id_override={color_scheme_id_override} meta={meta}
      lucid_page_section_id={lucid_page_section_id}
      className={className}
      name="photoset"
      colorSchemeId={color_scheme}>
      <StyledHeading>
        <h2>
          <InlineTextWithVariables cms={cms} name="title" value={title}/>
        </h2>
        <InlineTextWithVariables cms={cms} name="subtitle" value={subtitle}/>
      </StyledHeading>
      <RichtextWithVariables cms={cms} name="intro" richtext={intro}/>
      <StyledDiv>
        <LucidInlineGroups
          name='images'
          fields={IMAGE_GROUP}
          insetControls={true}
          focusRing={true}
          cms={cms}
        >
          {images?.items?.map((item, i) => (
            <div className='image-wrapper' key={i}>
              <SectionImage
                src={item.src}
                alt={item.alt}
                width={item.width}
                height={item.height}
                cms={cms}
                name={`items[${i}]`}
              />
              {/*We're going to be using alt as caption. We can update it later if that's not acceptable*/}
              {/*Look into how it was done in PhotoSetCollage if we need to change it*/}
              <span>{item.alt}</span>
            </div>
          ))}
        </LucidInlineGroups>
      </StyledDiv>

      <RichtextWithVariables cms={cms} name="outro" richtext={outro}/>
      <SectionButton
        name='button'
        href={button?.url}
        label={button?.label}
        cms={cms}
      />
    </Section>
  )
}
