import LucidImage from '@/src/utils/LucidImage'
import {CSSProperties, useEffect, useState} from 'react'
import {InlineImage} from '@einsteinindustries/react-tinacms-inline'
import Link from 'next/link'

interface SectionImageProps {
  name: string
  src: string
  alt: string
  cms: boolean
  width: number
  height: number
  link?: string
  className?: string
  style?: CSSProperties
}

interface ImageWrapperProps {
  src?: string
  alt?: string
  link?: string
  imageSize: ImageSize | null,
  className?: string
}

interface ImageSize {
  width: number
  height: number
}

// TODO: Add border
export default function SectionImage({
  name,
  src,
  alt,
  width,
  height,
  link,
  cms,
  className,
  style
}: SectionImageProps) {
  const [imageSize, setImageSize] = useState<ImageSize | null>(null)

  useEffect(() => {
    if (!src || src === '') {
      return
    }

    const img = new Image()
    img.onload = function handleOnload() {
      setImageSize({
        // @ts-ignore
        width: width && width !== '' ? width : this.width,
        // @ts-ignore
        height: height && height !== '' ? height : this.height
      })
    }
    img.src = src
    return () => {
      img.onload = null
    }
  }, [src, height, width])

  return (
    // TODO: Update <div> wrapper with borders and aspect ratio styles
    <div style={style}>
      {cms ? (
        <InlineImage
          name={`${name}.src`}
          // @ts-ignore
          parse={(media) => {
            if (!media) {
              return
            }
            return `${process.env.NEXT_PUBLIC_IMGIX_READ_URL}${media.directory}/${media.filename}`
          }}
          // uploadDir={() => '/public'}
          previewSrc={(src) => src}
          focusRing={true}
          alt={alt}
          className={className}
        >
          {({src: loaded}) => {
            return loaded ? (
              <ImageWrapper
                src={src}
                alt={alt}
                imageSize={imageSize}
                className={className}
              />
            ) : null
          }}
        </InlineImage>
      ) :
        <ImageWrapper
          src={src}
          alt={alt}
          link={link}
          imageSize={imageSize}
          className={className}
        />
      }
    </div>
  )
}

function ImageWrapper({src, alt, imageSize, link, className}: ImageWrapperProps) {
  if (src && imageSize && link && link !== '') {
    return (
      <Link href={link}>
        <LucidImage
          src={src}
          alt={alt}
          width={imageSize?.width}
          height={imageSize?.height}
          className={className}
          // layout="fill"
          // objectFit="cover"
          // layout="responsive"
        />
      </Link>
    )
  }
  if (src && imageSize) {
    return (
      <LucidImage
        src={src}
        alt={alt}
        width={imageSize?.width}
        height={imageSize?.height}
        className={className}
        // layout="fill"
        // objectFit="cover"
        // layout="responsive"
      />
    )
  }

  return null
}
