import StyledHeading from './shared/StyledHeading'
import {InlineTextWithVariables, RichtextWithVariables} from './shared/SectionsWithVariables'
import SectionText from './shared/SectionText'
import SectionImage from './shared/SectionImage'
import Section from './shared/Section'
import styled from 'styled-components'
import SectionButton from './shared/SectionButton'

const Row = styled.div`
  max-width: 1440px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-flow: row wrap;
`

const Column = styled.div`
  position: relative;
  flex: 1 1 0px;
  padding-left: 2rem;
  padding-right: 2rem;

  :first-child {
    display: flex;
    justify-content: flex-end;

    .image {
      position: relative;
      display: block;

      ::before {
        position: absolute;
        z-index: 1;
        top: 45px;
        left: -12rem;
        content: '';
        display: block;
        height: 300px;
        width: 80%;
        opacity: 1;
        background-color: #000;
      }

      ::after {
        position: absolute;
        z-index: 3;
        left: -50px;
        top: 345px;
        content: '';
        display: block;
        height: 150px;
        width: 175px;
        opacity: 0.8;
        background-color: #555555;
      }

      img {
        position: relative;
        z-index: 2;
        display: block;
        padding-bottom: 2.25rem;
      }
    }

    .phrase {
      opacity: 1;
      color: #e8e8e8;
      position: absolute;
      z-index: 5;
      bottom: -1rem;
      right: -25%;
      line-height: 1;
      font-size: 11rem;
    }
  }

  :last-child {
    z-index: 5;
    position: relative;
    padding-top: 2rem;
  }
`

export default function TextblockImageCollageA(props) {
  const {
    title,
    subtitle,
    intro,
    textblock_title,
    textblock_content,
    image,
    phrase,
    outro,
    button,
    color_scheme,
    className,
    css_overrides,
    color_scheme_id_override, lucid_page_section_id, meta,
    cms,
  } = props
  return (
    <Section
      cms={cms}
      css_overrides={css_overrides}
      color_scheme_id_override={color_scheme_id_override} meta={meta}
      lucid_page_section_id={lucid_page_section_id}
      className={className}
      name="textblockimagecollagea"
      colorSchemeId={color_scheme}>
      <StyledHeading>
        <h2>
          <InlineTextWithVariables cms={cms} name="title" value={title}/>
        </h2>
        <InlineTextWithVariables cms={cms} name="subtitle" value={subtitle}/>
      </StyledHeading>
      <RichtextWithVariables cms={cms} name="intro" richtext={intro}/>
      <Row>
        <Column>
          <div className="image">
            <SectionImage
              name='image'
              src={image?.src}
              alt={image?.alt}
              width={image?.width}
              height={image?.height}
              cms={cms}
            />
          </div>
          <div className="phrase">
            <SectionText name="phrase" text={phrase} cms={cms}/>
          </div>
        </Column>
        <Column>
          <h3>
            <SectionText
              name="textblock_title"
              text={textblock_title}
              cms={cms}
            />
          </h3>
          <RichtextWithVariables
            name="textblock_content"
            richtext={textblock_content}
            cms={cms}
          />
        </Column>
      </Row>
      <RichtextWithVariables cms={cms} name="outro" richtext={outro}/>
      <SectionButton
        name='button'
        href={button?.url}
        label={button?.label}
        cms={cms}
      />
    </Section>
  )
}

// export const blockConfig = {
//   Component(props) {
//     const { index, data } = props

//     return (
//       <BlocksControls
//         index={index}
//         // focusRing={{ offset: -4, borderRadius: 4 }}
//         insetControls
//       >
//         {/* eslint-disable-next-line */}
//         <TextblockImageCollageA {...data} />
//       </BlocksControls>
//     )
//   },
//   template: {
//     label: 'Textblock Image Collage A',
//     defaultItem: {
//       title: 'Textblock Image Collage A Section',
//       subtitle: '',
//       textblock_title: 'Title',
//       textblock_content:
//         '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>',
//       image: {
//         src: 'https://tools--dev.cms.eiidev.com/placeholder/500/460',
//         alt: 'placeholder',
//       },
//       phrase: 'Lorem',
//       button: {
//         label: '',
//         url: '',
//       },
//       colorSchemeId: '1',
//     },
//     fields: [
//       {
//         name: 'title',
//         label: 'Title',
//         component: 'text',
//       },
//       {
//         name: 'subtitle',
//         label: 'Subtitle',
//         component: 'text',
//       },
//       {
//         name: 'intro',
//         label: 'Intro',
//         component: 'html',
//       },
//       {
//         name: 'textblock_title',
//         label: 'Textblock Title',
//         component: 'text',
//       },
//       {
//         name: 'textblock_content',
//         label: 'Textblock Content',
//         component: 'html',
//       },
//       {
//         name: 'image',
//         label: 'Image',
//         component: 'group',
//         fields: [
//           {
//             name: 'src',
//             label: 'Image',
//             component: 'image',
//             description: 'Image description',
//             clearable: true,
//             parse: (media) => {
//               if (!media) {
//                 return
//               }
//               // console.log('image field config - parse:', media)
//               return `https://einstein-clients.imgix.net${media.directory}/${media.filename}`
//             },
//             previewSrc: (src) => {
//               console.log('previewSrc', src)
//               return src
//             },
//           },
//           {
//             name: 'alt',
//             label: 'Image Alt',
//             component: 'text',
//           },
//         ],
//       },
//       {
//         name: 'phrase',
//         label: 'Catchphrase',
//         component: 'text',
//       },
//       {
//         name: 'outro',
//         label: 'Outro',
//         component: 'html',
//       },
//       {
//         name: 'button',
//         label: 'Button',
//         component: 'group',
//         fields: buttonFields,
//       },
//       {
//         name: 'colorSchemeId',
//         label: 'Color Scheme',
//         component: 'select',
//         options: [
//           {
//             value: '1',
//             label: 'Default',
//           },
//         ],
//       },
//     ],
//     itemProps: (item) => {
//       const tempDiv = document.createElement('div')
//       tempDiv.innerHTML = item.title
//       return {
//         label: tempDiv.textContent,
//       }
//     },
//   },
// }

// export const templateName = 'textblockimagecollagea'
