import styled, {css} from 'styled-components'

export const Main = styled.main`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: #f6f6f9;
`

export const LoginBox = styled.div`
  display: flex;
  min-width: 450px;
  flex-flow: column nowrap;
  /* justify-content: center; */
  align-items: center;
  background-color: #ffffff;
  box-shadow: 0 0 1rem rgb(0 0 0 / 0.05);
  padding: 2rem 4rem;
  margin-bottom: 4rem;
  border: 1px solid #edecf3;
  border-radius: 25px;
  /* min-height: 33vh; */
`

const link = css`
  margin-inline: 4rem;
  width: 100%;
  text-align: center;
  opacity: ${({disabled}) => (disabled ? 0.2 : 1)};
  pointer-events: ${({disabled}) => (disabled ? 'none' : null)};
`

export const LoginLink = styled.a`
  ${link}
`

const defaultClickable = css`
  ${link};
  display: block;
  color: #fff;
  background: #2296fe;
  border-radius: 4px;
  padding: 1rem 2rem;
  font-size: 1rem;
  font-weight: 700;
  text-decoration: none;
  margin-bottom: 1rem;
  &:hover {
    ${({disabled}) => (!disabled ? 'background: #017dec;' : null)}
    text-decoration: none;
  }
`

export const LoginButton = styled.a`
  ${defaultClickable}
`

export const DefaultButton = styled.a`
  ${defaultClickable};
  cursor: pointer;
`

export const roundClickable = css`
  text-align: center;
  border-radius: var(--tina-radius-big);
  box-shadow: var(--tina-shadow-small);
  border: none;
  font-weight: var(--tina-font-weight-regular);
  cursor: pointer;
  font-size: var(--tina-font-size-1);
  height: 40px;
  padding: 0 var(--tina-padding-big);
  transition: all 85ms ease-out;
  opacity: 1;
`

export const DefaultRoundButton = styled.button`
  ${defaultClickable}
  ${roundClickable}
`

export const SignUpLink = styled(LoginLink)`
  flex: 1 0 auto;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  color: #2296fe;
  text-decoration: none;
  padding-top: 1rem;
  &:hover {
    color: #017dec;
  }
`

export const buttonModifier = css`
  position: relative;
  .button-disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }
  .button-loading::after {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    top: 0;
    right: 8px;
    bottom: 0;
    margin: auto;
    border: 4px solid transparent;
    border-top-color: #ffffff;
    border-radius: 50%;
    animation: button-loading-spinner 1s ease infinite;
  }
  @keyframes button-loading-spinner {
    from {
      transform: rotate(0turn);
    }

    to {
      transform: rotate(1turn);
    }
  }
`