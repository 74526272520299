import {BlocksControls} from '@einsteinindustries/react-tinacms-inline'
import SectionImage from '../shared/SectionImage'
import {RichtextWithVariables} from '../shared/SectionsWithVariables'
import SectionText from '../shared/SectionText'


// TODO: Use actual `cms`

// Base item for all the other item types to pull from
const baseItem = {
  label: 'Item',
  defaultItem: {
    title: 'Title',
    subtitle: 'Subtitle',
  },
  itemProps: (item) => {
    if (!item.title && !item.subtitle) {
      return
    }

    return {label: item.title || item.subtitle}
  },
  // TODO: Create a set of default fields
  fields: [
    {
      name: 'title',
      label: 'Title',
      component: 'text',
    },
    {
      name: 'subtitle',
      label: 'Subtitle',
      component: 'text',
    },
  ],
}

// Templates to use in the parent's block config and the InlineBlocks component
const itemTemplates = {
  featureslist: {
    ...baseItem,
    label: 'Features List Item',
    defaultItem: {
      ...baseItem.defaultItem,
      image: {
        src: 'https://tools--dev.cms.eiidev.com/placeholder/104/104',
        alt: '',
      },
      description:
        '<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci expedita doloremque temporibus ipsum illo vero aut voluptas laborum ex dignissimos numquam at odio, aspernatur voluptatum, provident sit nesciunt! Dicta, natus.</p>',
    },
    fields: [
      ...baseItem.fields,
      {
        name: 'icon',
        label: 'Icon',
        component: 'select',
        options: ['🚀', '🚫', '✅', '⭐️'],
      },
      {
        name: 'image',
        label: 'Image',
        component: 'group',
        fields: [
          {
            name: 'src',
            label: 'Image',
            component: 'image',
            description: 'Image description',
            clearable: true,
            parse: (media) => {
              if (!media) {
                return
              }
              // console.log('image field config - parse:', media)
              return `https://${process.env.NEXT_PUBLIC_IMGIX_READ_URL}${media.directory}/${media.filename}`
            },
            previewSrc: (src) => {
              console.log('previewSrc', src)
              return src
            },
          },
          {
            name: 'alt',
            label: 'Image Alt',
            component: 'text',
          },
        ],
      },
      {
        name: 'description',
        label: 'Description',
        component: 'html',
      },
    ],
  },
}

// InlineBlocks `blocks`
export const FEATURES_LIST_ITEM_BLOCKS = {
  featureslist: {
    Component({_name, index, data}) {
      return (
        <ItemWrapper index={index}>
          <div className="image-wrapper">
            {data.icon ? <div>{data.icon}</div> : null}
            {data.image.src ? (
              <SectionImage
                name="image.src"
                src={data.image.src}
                alt={data.image.alt}
                // width={image.width}
                // height={image.height}
                cms={data.cms}
              />
            ) : null}
          </div>
          <div>
            <ItemHeader title={data.title} subtitle={data.subtitle} />
            <RichtextWithVariables
              name="description"
              richtext={data.description}
              cms={cms}
            />
          </div>
        </ItemWrapper>
      )
    },
    template: itemTemplates.featureslist,
  },
}

function ItemWrapper(props) {
  return (
    <BlocksControls
      index={props.index}
      focusRing={{offset: 16, borderRadius: 4}}
      insetControls={false}
    >
      <div className="section-item-wrapper">{props.children}</div>
    </BlocksControls>
  )
}

function ItemHeader(props) {
  if (!props.title && !props.subtitle) {
    return null
  }
  return (
    <h3>
      {props.title ? (
        <span className="title">
          <SectionText name="title" text={props.title} cms={props.cms} />
        </span>
      ) : null}
      {props.subtitle ? (
        <span className="subtitle">
          <SectionText name="subtitle" text={props.subtitle} cms={props.cms} />
        </span>
      ) : null}
    </h3>
  )
}
