import {InlineGroup} from '@einsteinindustries/react-tinacms-inline'
import {Field} from '@einsteinindustries/tinacms'
import {FocusRingOptions} from '@einsteinindustries/react-tinacms-inline/dist/src/styles'

type LucidInlineGroupsProps = {
  cms: boolean,
  name: string;
  fields?: Field[];
  insetControls?: boolean;
  focusRing?: boolean | FocusRingOptions;
  children?: any;
}

export function LucidInlineGroups({cms, children, ...props} : LucidInlineGroupsProps) {
  return cms ? (
    <InlineGroup {...props}>{children}</InlineGroup>
  ) : (
    <div>{children}</div>
  )
}