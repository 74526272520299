import SectionContactForm from '../shared/SectionContactForm'
// import { useContext, useEffect, useState } from 'react'
// import { BlocksControls } from '@einsteinindustries/react-tinacms-inline'
import Section from '../shared/Section'
import StyledHeading from '../shared/StyledHeading'
import {InlineTextWithVariables, RichtextWithVariables} from '../shared/SectionsWithVariables'
// import styled from 'styled-components'
// import { LucidSiteContext } from '../../../state/site/Store'

export default function ContactForm(props) {
  const {title, subtitle, richtext, color_scheme, className, css_overrides,
color_scheme_id_override, lucid_page_section_id, meta, cms} = props
  return (
    <Section
      cms={cms}
      className={className}
      css_overrides={css_overrides} color_scheme_id_override={color_scheme_id_override} meta={meta} lucid_page_section_id={lucid_page_section_id}
      name="contactform"
      colorSchemeId={color_scheme}
    >
      <StyledHeading>
        <h2>
          <InlineTextWithVariables cms={cms} name="title" value={title} />
        </h2>
        <InlineTextWithVariables cms={cms} name="subtitle" value={subtitle} />
      </StyledHeading>
      <RichtextWithVariables
        name="richtext"
        richtext={richtext}
        cms={cms}
      />
      <SectionContactForm />
    </Section>
  )
}
