import Section from './shared/Section'
import {InlineTextWithVariables, RichtextWithVariables} from './shared/SectionsWithVariables'

export default function AnnouncementB(props) {
  const {title, subtitle, richtext, color_scheme, className, css_overrides, color_scheme_id_override, lucid_page_section_id, cms = true} = props
  return (
    <Section
      title={title}
      subtitle={subtitle}
      richtext={richtext}
      cms={cms}
      css_overrides={css_overrides}
      color_scheme_id_override={color_scheme_id_override}
      lucid_page_section_id={lucid_page_section_id}
      className={className + ' section announcement-b-section stack stylecore'}
      name="announcementb"
      colorSchemeId={color_scheme}
    >
      <div className="section-container">
        <div className="section-content">
          <div className="row">
            <div className="column shrink">
              <div className="section-heading">
                <div className="heading-1">
                  <h1>
                    <span className="title">
                      <InlineTextWithVariables name="title" value={title} cms={cms} />
                    </span>
                  </h1>
                </div>
                <div className="heading-4">
                  <h4>
                    <span className="subtitle">
                      <InlineTextWithVariables name="subtitle" value={subtitle} cms={cms} />
                    </span>
                  </h4>
                </div>
              </div>
            </div>
            <div className="column divider shrink">
              <p className="text">/</p>
            </div>
            <div className="column">
              <RichtextWithVariables name="richtext" richtext={richtext} cms={cms} />
            </div>
          </div>
        </div>
      </div>
      <div className="section-background"></div>
    </Section>
  )
}

AnnouncementB.StyleCore = {
  config: {},
  target: {
    componentName: Object.keys({AnnouncementB})[0].toLowerCase(),
  },
  css: ''
}
