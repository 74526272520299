import {BlocksControls} from '@einsteinindustries/react-tinacms-inline'
import SchemaReview from '../shared/SchemaReview'

// TODO: fix default item not showing

// export const templateName = 'reviews'

// TODO: Use actual `cms`

const schemaValues = {
  nameLocalBusiness: 'Practice Name',
  imageLocalBusiness: 'https://placeimg.com/200/200/tech',
  telephoneLocalBusiness: '(866) 585-2157',
  streetAddressLocalBusiness: '6825 Flanders Dr Ste 110',
  addressLocalityLocalBusiness: 'San Diego',
  addressRegionLocalBusiness: 'CA',
  postalCodeLocalBusiness: '92121',
  addressCountryLocalBusiness: 'USA',
}

// Templates to use in the parent's block config and the InlineBlocks component
const itemTemplates = {
  review: {
    label: 'Review Item',
    defaultItem: {
      name: 'Albert Einstein',
      rating: 5,
      description:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci expedita doloremque temporibus ipsum illo vero aut voluptas laborum ex dignissimos numquam at odio.',
      avatar_url: 'https://ui-avatars.com/api/?size=70&name=Albert+Einstein',
      avatar_alt: 'Albert Einstein',
      source_logo: '🚀',
      source_link_text: 'Google',
      source_link_url: 'https://www.google.com',
    },
    itemProps: (item) => {
      if (!item.name) {
        return
      }

      return {label: `Review: ${item.name}`}
    },
    fields: [
      {
        name: 'name',
        label: 'Name',
        component: 'text',
      },
      // TODO: Upgrade to Tina 0.41 to use new date field
      // {
      //   name: 'date',
      //   label: 'Date',
      //   component: 'date',
      //   dateFormat: 'MMMM DD, YYYY',
      //   timeFormat: false,
      // },
      {
        name: 'rating',
        label: 'Rating',
        component: 'radio-group',
        variant: 'radio',
        direction: 'vertical',
        options: [
          {label: '★☆☆☆☆', value: 1},
          {label: '★★☆☆☆', value: 2},
          {label: '★★★☆☆', value: 3},
          {label: '★★★★☆', value: 4},
          {label: '★★★★★', value: 5},
        ],
      },
      {
        name: 'description',
        label: 'Description',
        component: 'html',
      },
      {
        name: 'avatar_url',
        label: 'Avatar URL',
        component: 'text',
      },
      {
        name: 'avatar_alt',
        label: 'Avatar Alt Text',
        component: 'text',
      },
      {
        name: 'source_logo',
        label: 'Source Logo',
        component: 'select',
        options: ['🚀', '👋', '✅', '❤️', '⭐️'],
      },
      {
        name: 'source_link_text',
        label: 'Source Link Text',
        component: 'text',
      },
      {
        name: 'source_link_url',
        label: 'Source Link URL',
        component: 'text',
      },
    ],
  },
}

// Tina form config for Reviews section
// export const blockConfig = {
//   Component(props) {
//     const { index, data } = props

//     return (
//       <BlocksControls
//         index={index}
//         focusRing={{ offset: -4, borderRadius: 4 }}
//         insetControls
//       >
//         <Reviews {...data} />
//       </BlocksControls>
//     )
//   },
//   template: {
//     label: 'Reviews',
//     defaultItem: {
//       title: 'Reviews Title',
//       subtitle: 'Reviews Subtitle',
//       intro: '',
//       review_items: [
//         {
//           // id: 1,
//           _template: 'review',
//           ...itemTemplates.review.defaultItem,
//         },
//       ],
//       outro: '',
//       button: {
//         href: '',
//         label: '',
//       },
//     },
//     fields: [
//       {
//         name: 'title',
//         label: 'Title',
//         component: 'text',
//       },
//       {
//         name: 'subtitle',
//         label: 'Subtitle',
//         component: 'text',
//       },
//       {
//         name: 'review_items',
//         label: 'Reviews',
//         component: 'blocks',
//         templates: itemTemplates,
//       },
//     ],
//   },
// }

// InlineBlocks `blocks`
export const REVIEWS_BLOCKS = {
  review: {
    Component({_name, index, data}) {
      return (
        <ItemWrapper index={index}>
          <SchemaReview
            // name
            // rating
            // description
            // avatar_url
            // avatar_alt
            // source_logo
            // source_link_text
            // source_link_url
            datePublishedReview={data.date}
            descriptionReview={data.description}
            imageReview={data.avatar_url}
            namePerson={data.name}
            bestRating="5"
            worstRating="1"
            ratingValue={data.rating}
            nameLocalBusiness={schemaValues.nameLocalBusiness}
            imageLocalBusiness={schemaValues.imageLocalBusiness}
            telephoneLocalBusiness={schemaValues.telephoneLocalBusiness}
            streetAddressLocalBusiness={schemaValues.streetAddressLocalBusiness}
            addressLocalityLocalBusiness={
              schemaValues.addressLocalityLocalBusiness
            }
            addressRegionLocalBusiness={schemaValues.addressRegionLocalBusiness}
            postalCodeLocalBusiness={schemaValues.postalCodeLocalBusiness}
            addressCountryLocalBusiness={
              schemaValues.addressCountryLocalBusiness
            }
          />
          <div style={{flex: '1 0 50%', display: 'flex'}}>
            <div style={{marginRight: '1rem'}}>
              {/* eslint-disable-next-line */}
              <img
                src={data.avatar_url}
                alt={data.avatar_alt}
              />
            </div>
            <div
              style={{
                flex: '1 0 auto',
                display: 'flex',
                flexFlow: 'column nowrap',
              }}
            >
              <div>
                <strong>{data.name}</strong>
              </div>
              {/* <div>{data.date}</div> */}
              <div>
                {['★', '★', '★', '★', '★'].map((star, index) => {
                  const emptyStar = '☆'
                  if (data.rating < index + 1) {
                    return emptyStar
                  }
                  return star
                })}
              </div>
            </div>
            <div>{data.source_logo}</div>
          </div>
          <div dangerouslySetInnerHTML={{__html: data.description}} />
          <a href={data.source_link_url}>View on {data.source_link_text}</a>
        </ItemWrapper>
      )
    },
    template: itemTemplates.review,
  },
}

function ItemWrapper(props) {
  return (
    <div
      style={{
        display: 'flex',
        flexFlow: 'row wrap',
        border: '1px dashed #000',
        padding: '1rem',
      }}
    >
      <BlocksControls
        index={props.index}
        focusRing={{offset: 16, borderRadius: 4}}
        insetControls={false}
      >
        {props.children}
      </BlocksControls>
    </div>
  )
}
