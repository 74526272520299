import React from 'react'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import * as yup from 'yup'
import Section from '../shared/Section'
import styled from 'styled-components'

const StyledSection = styled(Section)`
  .field-group {
    position: relative;
    margin-bottom: 0.5rem;
  }
  .field-error {
    color: red;
    font-size: 80%;
  }
  label {
    position: absolute;
    left: 0.5rem;
    top: 0.5rem;
  }
  input,
  textarea {
    padding: 1.75rem 0.5rem 0.5rem;
  }
  form {
    max-width: 460px;
    margin: 0 auto;
    width: 100%;
  }
  input,
  textarea {
    font-family: Arial, Helvetica, sans-serif;
    width: 100%;
  }
  textarea {
    height: 100%;
  }
  button {
    width: 100%;
    padding: 1rem;
  }
`

const schema = yup
  .object({
    contact_name: yup.string().required('Name is required'),
    contact_email: yup
      .string()
      .email('Must be a valid email')
      .required('Email is required'),
    contact_phone: yup.string().required('Phone is required'),
    contact_message: yup.string().required('Message is required'),
  })
  .required()

export default function SectionContactForm() {
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm({
    resolver: yupResolver(schema),
  })

  const onSubmit = (data) => console.log(data)

  return (
    <StyledSection>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="field-group">
          <label htmlFor="name">Name</label>
          <input
            {...register('contact_name')}
            id="name"
          />
          <div className="field-error">
            <p>{errors.contact_name?.message}</p>
          </div>
        </div>
        <div className="field-group">
          <label htmlFor="email">Email</label>
          <input
            {...register('contact_email')}
            id="email"
          />
          <div className="field-error">
            <p>{errors.contact_email?.message}</p>
          </div>
        </div>
        <div className="field-group">
          <label htmlFor="phone">Phone Number</label>
          <input
            {...register('contact_phone')}
            id="phone"
          />
          <div className="field-error">
            <p>{errors.contact_phone?.message}</p>
          </div>
        </div>
        <div className="field-group">
          <label htmlFor="message">Message</label>
          <textarea
            {...register('contact_message')}
            rows="5"
            id="message"
          />
          <div className="field-error">
            <p>{errors.contact_message?.message}</p>
          </div>
        </div>
        <div className="form-group section-button">
          <button type="submit" className="button">Submit</button>
        </div>
      </form>
    </StyledSection>
  )
}

