import {CTA} from '@/components/managers/Toolbar/AssignToUserButton'

interface ButtonBarProps {
  closeDialog: () => void
  handleSave: (isAccept: boolean) => void
  isReject: boolean
  isValid: boolean
  nameAction: string
  errorMessage?: string
}

export const ButtonBar = ({
  closeDialog,
  handleSave,
  isReject,
  isValid,
  nameAction,
  errorMessage,
}: ButtonBarProps) => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'baseline',
      position: 'sticky',
      bottom: 0,
      backgroundColor: 'white',
      paddingBottom: '10px',
    }}
  >
    <CTA onClick={closeDialog}>Cancel</CTA>
    <span style={{color: '#757575'}}>{errorMessage}</span>
    <div>
      {isReject && (
        <CTA
          onClick={() => handleSave(false)}
          style={{
            color: 'red',
            marginRight: '20px',
            cursor: isValid ? 'pointer' : 'not-allowed',
          }}
          disabled={!isValid}
        >
          Reject
        </CTA>
      )}
      <CTA
        onClick={() => handleSave(true)}
        style={{
          backgroundColor: 'var(--tina-color-primary)',
          color: 'var(--tina-color-grey-0)',
          cursor: isValid ? 'pointer' : 'not-allowed',
        }}
        disabled={!isValid}
      >
        {nameAction}
      </CTA>
    </div>
  </div>
)

export default ButtonBar
