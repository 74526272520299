import {BlocksControls} from '@einsteinindustries/react-tinacms-inline'
import {RichtextWithVariables} from '../shared/SectionsWithVariables'

// Templates to use in the parent's block config and the InlineBlocks component
const itemTemplates = {
  item: {
    label: 'Item',
    defaultItem: {
      quote: 'Quote',
    },
    fields: [
      {
        name: 'qoute',
        label: 'Quote',
        component: 'html',
      },
    ],
  },
}

// Tina form config for MenuDemo
// export const blockConfig = {
//   Component(props) {
//     const { index, data } = props

//     return (
//       <BlocksControls
//         index={index}
//         focusRing={{ offset: -4, borderRadius: 4 }}
//         insetControls
//       >
//         <TextblockQuoteCarousel {...data} />
//       </BlocksControls>
//     )
//   },
//   template: {
//     label: 'Textblock Quote Carousel',
//     defaultItem: {
//       title: 'Textblock Quote Carousel Section',
//       column_1_image: {
//         src: 'https://tools--dev.cms.eiidev.com/placeholder/600/400',
//         alt: '',
//       },
//       column_1_richtext:
//         '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam ac iaculis elit. Fusce nec risus sed mi efficitur sagittis. Nulla at est libero. Suspendisse ornare justo vel velit fermentum feugiat vitae vitae augue.</p>',
//       column_2_image: {
//         src: 'https://tools--dev.cms.eiidev.com/placeholder/600/400',
//         alt: '',
//       },
//       column_2_richtext:
//         '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam ac iaculis elit. Fusce nec risus sed mi efficitur sagittis. Nulla at est libero. Suspendisse ornare justo vel velit fermentum feugiat vitae vitae augue.</p>',
//       items: [
//         {
//           // id: 1,
//           _template: 'item',
//           quote: 'Quote',
//         },
//       ],
//       outro: '',
//       button: {
//         href: '',
//         label: '',
//       },
//     },
//     fields: [
//       {
//         name: 'title',
//         label: 'Title',
//         component: 'text',
//       },
//       {
//         name: 'subtitle',
//         label: 'Subtitle',
//         component: 'text',
//       },
//       {
//         name: 'intro',
//         label: 'Intro',
//         component: 'html',
//       },
//       {
//         name: 'column_1_title',
//         label: 'Column 1 Title',
//         component: 'text',
//       },
//       {
//         name: 'column_1_richtext',
//         label: 'Column 1 Richtext',
//         component: 'html',
//       },
//       {
//         name: 'column_1_image',
//         label: 'Column 1 Image',
//         component: 'group',
//         fields: [
//           {
//             name: 'src',
//             label: 'Column 1 Image',
//             component: 'image',
//             description: 'Column 1 Image description',
//             clearable: true,
//             parse: (media) => {
//               if (!media) {
//                 return
//               }
//               // console.log('image field config - parse:', media)
//               return `https://einstein-clients.imgix.net${media.directory}/${media.filename}`
//             },
//             previewSrc: (src) => {
//               console.log('previewSrc', src)
//               return src
//             },
//           },
//           {
//             name: 'alt',
//             label: 'Column 1 Image Alt',
//             component: 'text',
//           },
//         ],
//       },
//       {
//         name: 'column_2_title',
//         label: 'Column 2 Title',
//         component: 'text',
//       },
//       {
//         name: 'column_2_richtext',
//         label: 'Column 2 Richtext',
//         component: 'html',
//       },
//       {
//         name: 'column_2_image',
//         label: 'Column 2 Image',
//         component: 'group',
//         fields: [
//           {
//             name: 'src',
//             label: 'Column 2 Image',
//             component: 'image',
//             description: 'Column 2 Image description',
//             clearable: true,
//             parse: (media) => {
//               if (!media) {
//                 return
//               }
//               // console.log('image field config - parse:', media)
//               return `https://einstein-clients.imgix.net${media.directory}/${media.filename}`
//             },
//             previewSrc: (src) => {
//               console.log('previewSrc', src)
//               return src
//             },
//           },
//           {
//             name: 'alt',
//             label: 'Column 2 Image Alt',
//             component: 'text',
//           },
//         ],
//       },
//       {
//         name: 'items',
//         label: 'Items',
//         component: 'blocks',
//         templates: itemTemplates,
//       },
//       {
//         name: 'outro',
//         label: 'Outro',
//         component: 'html',
//       },
//       {
//         name: 'button',
//         label: 'Button',
//         component: 'group',
//         fields: [
//           ...buttonFields,
//           // {
//           //   label: 'Label',
//           //   name: 'label',
//           //   component: 'text',
//           // },
//           // {
//           //   label: 'Link URL',
//           //   name: 'url',
//           //   component: 'text',
//           // },
//         ],
//       },
//     ],
//   },
// }

// InlineBlocks `blocks`
export const TEXT_QUOTE_ITEM_BLOCKS = {
  item: {
    Component({_name, index, data}) {
      return (
        <ItemWrapper index={index}>
          <RichtextWithVariables cms={cms} name="quote" richtext={data.quote} />
          {/* <pre style={{ whiteSpace: 'pre-wrap' }}>
            {JSON.stringify(data, null, 2)}
          </pre> */}
        </ItemWrapper>
      )
    },
    template: itemTemplates.item,
  },
}

function ItemWrapper(props) {
  return (
    <div
      className="section-item-wrapper"
      style={{
        flex: '1 0 calc(33% - 1rem)',
        // border: '1px dashed #000',
        padding: '1rem',
      }}
    >
      <BlocksControls
        index={props.index}
        focusRing={{offset: 16, borderRadius: 4}}
        insetControls={false}
      >
        {props.children}
      </BlocksControls>
    </div>
  )
}
