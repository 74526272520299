import {usePlugin, useScreenPlugin} from '@einsteinindustries/tinacms'
import {PageManagerPlugin} from './PageManager'
// import { UserManager } from './SiteManager'
import {MenuManager, RouteManager} from '@/components/managers'
import AssignToUser from './Toolbar/AssignToUserButton'
import ManagersMenu from '@/components/managers/Toolbar/ManagersMenu'
import {LinkIcon, MenuNestedIcon} from '@/components/icons'
import PageLiveToDevToggle from './Toolbar/PageLiveToDevToggle'
import ProxyConfigButton from './Toolbar/ProxyConfigButton'
import {TypographyModal} from '../editor/typography/TypographyModal'
import React from 'react'
import {HambergerMenu, LinkSquare, Text} from 'iconsax-react'

export const usePlugins = (pageBuild, site, page) => {
  const AssignUserManagerPlugin = () => <AssignToUser page={pageBuild}/>
  const PageLiveToDevTogglePlugin = () => (
    <PageLiveToDevToggle page={page}/>
  )

  const ProxyConfigPlugin = () => (
    <ProxyConfigButton site={site}/>
  )

  const proxyConfigPlugin = {
    __type: 'toolbar:widget',
    name: 'proxyConfigSync',
    weight: 10,
    component: ProxyConfigPlugin,
  }

  const assignToUserPlugin = {
    __type: 'toolbar:widget',
    name: 'assignToUser',
    weight: 20,
    component: AssignUserManagerPlugin,
  }

  const pageTogglePlugin = {
    __type: 'toolbar:widget',
    name: 'pageLiveToDevToggle',
    weight: 30,
    component: PageLiveToDevTogglePlugin,
  }

  const menuPlugin = {
    __type: 'toolbar:widget',
    name: 'ManagersMenu',
    weight: 40,
    component: ManagersMenu,
  }
  // Screen plugin
  useScreenPlugin(PageManagerPlugin)

  useScreenPlugin({
    name: 'Routes',
    Component: () => <RouteManager siteId={Number(site.id)}/>,
    Icon: () => {
      return <LinkSquare variant="Bulk" />
    },
    layout: 'fullscreen',
  })

  useScreenPlugin({
    name: 'Typography',
    Component: () => <TypographyModal inModal={false} onClose={() => {}} />,
    Icon: () => {
      return <Text variant="Bulk" />
    },
    layout: 'fullscreen',
  })

  useScreenPlugin({
    name: 'Menus',
    Component: MenuManager,
    Icon: () => {
      return <HambergerMenu variant="Bulk" />
    },
    layout: 'fullscreen',
  })

  // TODO: Color Scheme Manager
  // useScreenPlugin({
  //   name: 'Color Schemes',
  //   Component: () => <div>Color Schemes</div>,
  //   Icon: () => null,
  //   layout: 'fullscreen',
  // })

  // usePlugin(UserManager)

  // Toolbar plugin
  usePlugin(proxyConfigPlugin)
  usePlugin(assignToUserPlugin)
  usePlugin(menuPlugin)
  usePlugin(pageTogglePlugin)
}

export * from './MenuManager'
export * from './RouteManager'
export * from './PageManager'
export * from './ColorSchemeManager/ColorScheme'
